import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import DOMPurify from "dompurify";
import QGContext from "../QGContext";
import SignIn from "./SignIn";
import TextField from "@mui/material/TextField";
import { useGetProject } from "../api/rest/useGetProject";
import { usePutProject } from "../api/rest/usePutProject";
import { useTranslation } from "react-i18next";
import { useValidation } from "../hooks/useValidateName";
import { validateName } from "../hooks/useValidateName";
import "./Project.scss";

//const POST_PROJECT_URL = "localhost:3000/qualitygate/v1/project";
interface ProjectProps
{
  name: string;
  street: string;
  houseNumber: string;
  zip: string;
  city: string;
  description: string;
  defaultRoleId: string;
  projectNumber: string;
}

/**
 * The Project component
 *
 * @returns {JSX.Element} - The element
 */
const Project: FunctionComponent = (): JSX.Element => 
{
  const { t } = useTranslation();
  const ctx = useContext(QGContext);

  const [project, setProject] = useState<ProjectProps>({
    name: "",
    street: "",
    houseNumber: "",
    zip: "",
    city: "",
    description: "",
    defaultRoleId: "",
    projectNumber: "",
  });

  const [accData, setAccData] = useState<{ hubId: string; projectId: string }>({
    hubId: "",
    projectId: "",
  });

  const { isLoading, data } = useGetProject(ctx.project.id);
  const projectUpdater = usePutProject(ctx.project.id);

  const nameErrors = useValidation(project.name, validateName);
  const [cantSaveProject, setCantSaveProject] = useState(true);

  useEffect(() => 
  {
    if (!data)
    {
      return;
    }
    setProject({
      name: data.name,
      street: data.address.street,
      houseNumber: data.address.houseNumber,
      zip: data.address.zip,
      city: data.address.city,
      description: data.description,
      defaultRoleId: data.defaultRoleId,
      projectNumber: data.projectNumber,
    });
    setAccData({
      hubId: data.accHubId ?? "",
      projectId: data.accProjectId ?? "",
    });
  }, [data]);

  const handleChangeAccHub = (hubId: string) => 
  {
    setAccData({ hubId, projectId: "" });
    saveAccData(hubId, "");
  };
  const handleChangeAccProjectId = (projectId: string) => 
  {
    setAccData((prev) => ({ ...prev, projectId }));
    saveAccData(accData.hubId, projectId);
  };
  const saveAccData = (accHubId: string, accProjectId: string) => 
  {
    projectUpdater.mutate({
      name: project.name,
      street: project.street,
      houseNumber: project.houseNumber,
      zip: project.zip,
      city: project.city,
      description: project.description,
      defaultRoleId: project.defaultRoleId,
      accHubId,
      accProjectId,
      projectNumber: project.projectNumber,
    });

    ctx.setProject({
      ...ctx.project,
      accHubId,
      accProjectId,
    });
  };

  const handleClick = () => 
  {
    projectUpdater.mutate({
      name: project.name,
      street: project.street,
      houseNumber: project.houseNumber,
      zip: project.zip,
      city: project.city,
      description: project.description,
      defaultRoleId: project.defaultRoleId,
      accHubId: ctx.project.accHubId,
      accProjectId: ctx.project.accProjectId,
      projectNumber: project.projectNumber,
    });
  };

  useEffect(() => 
  {
    const hasNameErrors = nameErrors.length > 0;
    setCantSaveProject(hasNameErrors);
  }, [nameErrors]);

  if (isLoading)
  {
    return (
      <div>
        <Box>
          <CircularProgress className="spinner" />
        </Box>
      </div>
    );
  }

  return (
    <div id="container">
      <div id="project-information">
        <h1 id="title">{t("ui.projectinformation.heading")}</h1>
        <div
          id="description"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              t("ui.projectinformation.description") as string,
            ),
          }}
        />
        <div id="project-properties">
          <div className="property">
            <div className="label">
              <label htmlFor="projectName">
                {t("ui.projectinformation.property.name")}
              </label>
            </div>
            <div className="value" style={{ display: "inline-flex" }}>
              <TextField
                variant="standard"
                placeholder="Name"
                name="projectName"
                id="projectName"
                value={project.name}
                onChange={(ev) =>
                  setProject((prev) => ({
                    ...prev,
                    name: ev.target.value,
                    touched: true,
                  }))
                }
                InputLabelProps={{ shrink: true }}
                sx={{ marginTop: 2 }}
              />
              {nameErrors.length > 0 && (
                <div style={{ display: "inline-flex" }}>
                  {nameErrors.map((error, index) => (
                    <p key={index} style={{ color: "red" }}>
                      {error}
                    </p>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="property">
            <div className="label">
              <label htmlFor="projectStreet">
                {t("ui.projectinformation.property.address.street")}
              </label>
            </div>
            <div className="value">
              <TextField
                variant="standard"
                placeholder="Street"
                name="projectStreet"
                id="projectStreet"
                value={project.street}
                onChange={(ev) =>
                  setProject((prev) => ({
                    ...prev,
                    street: ev.target.value,
                    touched: true,
                  }))
                }
                InputLabelProps={{ shrink: true }}
                sx={{ marginTop: 2 }}
              />
            </div>
          </div>
          <div className="property">
            <div className="label">
              <label htmlFor="projectHousenumber">
                {t("ui.projectinformation.property.address.housenumber")}
              </label>
            </div>
            <div className="value">
              <TextField
                variant="standard"
                placeholder="House Number"
                name="projectHousenumber"
                id="projectHousenumber"
                value={project.houseNumber}
                onChange={(ev) =>
                  setProject((prev) => ({
                    ...prev,
                    houseNumber: ev.target.value,
                    touched: true,
                  }))
                }
                InputLabelProps={{ shrink: true }}
                sx={{ marginTop: 2 }}
              />
            </div>
          </div>
          <div className="property">
            <div className="label">
              <label htmlFor="projectZip">
                {t("ui.projectinformation.property.address.zip")}
              </label>
            </div>
            <div className="value">
              <TextField
                variant="standard"
                placeholder="ZIP"
                name="projectZip"
                id="projectZip"
                value={project.zip}
                onChange={(ev) =>
                  setProject((prev) => ({
                    ...prev,
                    zip: ev.target.value,
                    touched: true,
                  }))
                }
                InputLabelProps={{ shrink: true }}
                sx={{ marginTop: 2 }}
              />
            </div>
          </div>
          <div className="property">
            <div className="label">
              <label htmlFor="projectCity">
                {t("ui.projectinformation.property.address.city")}
              </label>
            </div>
            <div className="value">
              <TextField
                variant="standard"
                placeholder="City"
                name="projectCity"
                id="projectCity"
                value={project.city}
                onChange={(ev) =>
                  setProject((prev) => ({
                    ...prev,
                    city: ev.target.value,
                    touched: true,
                  }))
                }
                InputLabelProps={{ shrink: true }}
                sx={{ marginTop: 2 }}
              />
            </div>
          </div>
          <div className="property">
            <div className="label">
              <label htmlFor="projectNumber">
                {/*TODO translate*/}
                {t("Project Number")}
              </label>
            </div>
            <div className="value">
              <TextField
                variant="standard"
                placeholder="Project Number"
                name="projectNumber"
                id="projectNumber"
                value={project.projectNumber}
                onChange={(ev) => 
                {
                  setProject((prev) => ({
                    ...prev,
                    projectNumber: ev.target.value,
                    touched: true,
                  }));
                }}
                InputLabelProps={{ shrink: true }}
                sx={{ marginTop: 2 }}
              />
            </div>
          </div>
          <div className="long-property">
            <label htmlFor="projectDescription">
              {t("ui.projectinformation.property.description")}
            </label>
            <TextField
              variant="standard"
              placeholder="Description"
              name="projectDescription"
              id="projectDescription"
              multiline
              rows={4}
              value={project.description}
              onChange={(ev) =>
                setProject((prev) => ({
                  ...prev,
                  description: ev.target.value,
                  touched: true,
                }))
              }
              InputLabelProps={{ shrink: true }}
              sx={{ marginTop: 2, width: "100%" }}
            />
          </div>
        </div>
        <button
          className={!cantSaveProject ? "button_save-form" : "button_dissabled"}
          disabled={cantSaveProject}
          onClick={handleClick}
        >
          Save Project
        </button>
      </div>
      <div>
        <SignIn
          onChangeHub={handleChangeAccHub}
          onChangeProject={handleChangeAccProjectId}
        />
      </div>
    </div>
  );
};

export default Project;