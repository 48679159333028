/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent } from "react";
import { LOGIN_URL } from "../environment";

/**
 * The session expired component.
 *
 * @returns {JSX.Element} - The element
 */
const SessionExpired: FunctionComponent = (): JSX.Element => 
{
  // TODO translate
  // const { t } = useTranslation();
  return (
    <span>
      Your session timed out.&nbsp;
      <a
        href="#"
        onClick={(e) => 
        {
          e.preventDefault();
          window.location.href = `${LOGIN_URL}/?redirectUrl=${encodeURI(
            window.location.href,
          )}`;
        }}
      >
        Click
      </a>
      &nbsp;to create a new session.
    </span>
  );
};

export default SessionExpired;