import React, { useEffect, useRef, useState } from "react";
import { useValidation, validateName } from "../hooks/useValidateName";
import { usePostProject } from "../api/rest/usePostProject";
import { useTranslation } from "react-i18next";

interface CreateProjectDialogProps
{
  /**
   * A value that tells if the dialog is visible or not
   */
  isVisible: boolean;
  /**
   *Close event handler.
   */
  onClose: () => void;
}

/**
 * The modal dialog component.
 *
 * @param {CreateProjectDialogProps} onClose a prop.
 * @returns {JSX.Element} The modal component.
 */
const CreateProjectDialog = ({ onClose }: CreateProjectDialogProps) => 
{
  const projectCreator = usePostProject();
  const { t } = useTranslation();

  const [projectData, setProjectData] = useState({
    name: "",
    projectNumber: "",
    description: "",
    street: "",
    number: "",
    zipcode: "",
    city: "",
  });

  const nameErrors = useValidation(projectData.name, validateName);
  const [cantCreateProject, setCantCreateProject] = useState(false);
  const nameRef = useRef(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => 
  {
    const { name, value } = e.target;
    setProjectData({
      ...projectData,
      [name]: value,
    });
  };

  const handleBlur = () => 
  {
    nameRef.current = true;
  };

  useEffect(() => 
  {
    const hasNameErrors = nameErrors.length > 0;
    setCantCreateProject(hasNameErrors);
  }, [nameErrors]);

  const handleSumbit = async (e: React.FormEvent<HTMLFormElement>) => 
  {
    e.preventDefault();
    projectCreator.mutate(projectData);
    onClose();
  };
  return (
    <form className="muiDialog" onSubmit={handleSumbit}>
      <div className="createProjectModal">
        <h1 className="modal-title">
          {/* TODO Translate */}
          {t("ui.projectlist.create")}
          <span onClick={onClose} className="close-modal">
            x
          </span>
        </h1>
        <div className="dialog-field">
          <label htmlFor="name">
            <span className="label_name">Name</span>
            <input
              className="input_name"
              type="text"
              name="name"
              id="name"
              value={projectData.name}
              onChange={handleChange}
              onBlur={handleBlur}
              required
              placeholder="Enter name"
            />
            {nameErrors.length > 0 && (
              <div>
                {nameRef.current
                  && nameErrors.map((error, index) => (
                    <p key={index} style={{ color: "red" }}>
                      {error}
                    </p>
                  ))}
              </div>
            )}
          </label>
          <label htmlFor="projectNumber">
            <span className="label_project-number">Project number</span>
            <input
              className="input_projectNumber"
              type="text"
              name="projectNumber"
              id="projectNumber"
              value={projectData.projectNumber}
              onChange={handleChange}
              placeholder="Enter project number"
            />
          </label>
          <label htmlFor="description">
            <span className="label_description">Description</span>
            <input
              className="input_description"
              type="text"
              name="description"
              id="description"
              value={projectData.description}
              onChange={handleChange}
              placeholder="Enter description"
            />
          </label>
          <label htmlFor="street">
            <span className="label_street">Street</span>
            <input
              className="input_street"
              type="text"
              name="street"
              id="street"
              value={projectData.street}
              onChange={handleChange}
              placeholder="Enter street"
            />
          </label>
          <div className="number-and-zipcode">
            <label htmlFor="number">
              <span className="label_number">Number</span>
              <input
                className="input_number"
                type="text"
                name="number"
                id="number"
                value={projectData.number}
                onChange={handleChange}
                placeholder="#"
              />
            </label>
            <label htmlFor="zipcode">
              <span className="label_zipcode">Zip Code</span>
              <input
                className="input_zipcode"
                type="text"
                name="zipcode"
                id="zipcode"
                value={projectData.zipcode}
                onChange={handleChange}
                placeholder="Enter zip code"
              />
            </label>
          </div>
          <label htmlFor="city">
            <span className="label_city">City</span>
            <input
              className="input_city"
              type="text"
              name="city"
              id="city"
              value={projectData.city}
              onChange={handleChange}
              placeholder="Enter city"
            />
          </label>
        </div>
        <div className="actions">
          <button
            className="button_save"
            type="submit"
            disabled={cantCreateProject}
          >
            Save
          </button>
          <button className="button_connect-cloud">Connect Cloud</button>
        </div>
      </div>
    </form>
  );
};

export default CreateProjectDialog;