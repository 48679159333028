import * as React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import ReactDOM from "react-dom";
import { v4 as uuid } from "uuid";
import "./Dialog.scss";

/**
 * Interface for the dialog state.
 *
 * @interface
 */
export interface DialogState
{
  /**
   * A value indicating whether the dialog is visible or not.
   *
   * @property {boolean} visible A value indicating whether the dialog is visible or not.
   */
  visible: boolean;
}

/**
 * Interface for the dialog arguments.
 *
 * @interface
 */
export interface DialogProps extends WithTranslation
{
  /**
   * The event handler if the dialog will be hidden.
   *
   * @callback hideHandler
   * @returns {void}
   * @property {hideHandler} onHide The event handler if the dialog will be hidden.
   */
  onHide: () => void;

  /**
   * The event handler if the dialog will be shown.
   *
   * @callback showHandler
   * @returns {void | null}
   * @property {showHandler} onShow The event handler if the dialog will be shown.
   */
  onShow?: () => void | null;

  /**
   * The event handler if the dialog has been confirmed.
   *
   * @callback confirmHandler
   * @returns {void | null}
   * @property {confirmHandler} onConfirm The event handler if the dialog has been confirmed.
   */
  onConfirm?: () => void | null;

  /**
   * The event handler if the dialog has been canceled.
   *
   * @callback cancelHandler
   * @returns {void | null}
   * @property {cancelHandler} onCancel The event handler if the dialog has been canceled.
   */
  onCancel?: () => void | null;

  /**
   * The event handler if the dialog has been closed.
   *
   * @callback closeHandler
   * @returns {void | null}
   * @property {closeHandler} onClose The event handler if the dialog has been closed.
   */
  onClose?: () => void | null;

  /**
   * The event handler if the user has clicked out of bounds of the dialog.
   *
   * @callback outOfBoundsHandler
   * @returns {void | null}
   * @property {outOfBoundsHandler} onOutOfBounds The event handler if the user has clicked out of bounds of the dialog.
   */
  onOutOfBounds?: () => void | null;

  /**
   * A value indicating whether the dialog is visible or not.
   *
   * @property {boolean} visible A value indicating whether the dialog is visible or not.
   */
  visible: boolean;

  /**
   * The header.
   *
   * @property {string} header The header.
   */
  header: string;

  /**
   * The children.
   *
   * @property {any} children The children.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;

  /**
   * The ID.
   *
   * @property {string} id The ID.
   */
  id?: string;
}

/**
 * The Dialog.
 *
 * @class
 * @augments {React.Component<DialogProps, DialogState>}
 */
export class Dialog extends React.Component<DialogProps, DialogState>
{
  /**
   * The ID.
   *
   * @property {string} id The ID.
   */
  id: string;

  /**
   * Create an instance of Dialog.
   *
   * @param {DialogProps} props The properties.
   */
  constructor(props: DialogProps)
  {
    super(props);

    console.debug("[Dialog] Generate dialog", props);

    this.state = {
      visible: props.visible,
    };

    if (!this.props.id)
    {
      this.id = `mum-qg-dialog-${uuid()}`;
    }
    else 
    {
      this.id = this.props.id;
    }

    console.debug(`[Dialog] <${this.id}> Generated dialog`, props);

    this.hideDialog = this.hideDialog.bind(this);
    this.confirm = this.confirm.bind(this);
    this.close = this.close.bind(this);
    this.outOfBounds = this.outOfBounds.bind(this);
    this.cancel = this.cancel.bind(this);
    this.show = this.show.bind(this);
  }

  /**
   * Hides the dialog.
   *
   * @returns {void}
   */
  hideDialog()
  {
    console.debug(`[Dialog] <${this.id}> Hide dialog`, {
      props: this.props,
      state: this.state,
    });

    this.setState({
      visible: false,
    });

    this.props.onHide();
  }

  /**
   * Closes the dialog.
   *
   * @returns {void}
   */
  close()
  {
    console.debug(`[Dialog] <${this.id}> closed dialog`, {
      props: this.props,
      state: this.state,
    });

    const dialogWindow = document.getElementById(this.id);

    if (dialogWindow !== null)
    {
      dialogWindow.setAttribute("aria-hidden", "true");
    }

    if (this.props.onClose)
    {
      this.props.onClose();
    }

    if (this.props.onCancel)
    {
      this.props.onCancel();
    }

    this.hideDialog();
  }

  /**
   * The out of bounds click event.
   *
   * @param {MouseEvent} event The click event arguments.
   * @returns {any} Can return any result.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  outOfBounds(event: MouseEvent): any
  {
    console.debug(`[Dialog] <${this.id}> clicked out of dialog bounds`, {
      event: event,
      props: this.props,
      state: this.state,
    });

    if ((event.target as HTMLElement).id !== "mum-qg-dialog-mask")
    {
      console.debug(`[Dialog] <${this.id}> click was in bounds`);

      return;
    }

    const dialogWindow = document.getElementById(this.id);

    if (dialogWindow !== null)
    {
      dialogWindow.setAttribute("aria-hidden", "true");
    }

    if (this.props.onOutOfBounds)
    {
      this.props.onOutOfBounds();
    }

    if (this.props.onCancel)
    {
      this.props.onCancel();
    }

    this.hideDialog();
  }

  /**
   * Cancels the dialog.
   *
   * @returns {void}
   */
  cancel()
  {
    console.debug(`[Dialog] <${this.id}> canceled dialog`, {
      props: this.props,
      state: this.state,
    });

    const dialogWindow = document.getElementById(this.id);

    if (dialogWindow !== null)
    {
      dialogWindow.setAttribute("aria-hidden", "true");
    }

    if (this.props.onCancel)
    {
      this.props.onCancel();
    }

    this.hideDialog();
  }

  /**
   * Confirms the dialog.
   *
   * @returns {void}
   */
  confirm()
  {
    console.debug(`[Dialog] <${this.id}> confirmed dialog`, {
      props: this.props,
      state: this.state,
    });

    const dialogWindow = document.getElementById(this.id);

    if (dialogWindow !== null)
    {
      dialogWindow.setAttribute("aria-hidden", "true");
    }

    if (this.props.onConfirm)
    {
      this.props.onConfirm();
    }

    this.hideDialog();
  }

  /**
   * Shows the dialog.
   *
   * @returns {void}
   */
  show()
  {
    console.debug(`[Dialog] <${this.id}> show dialog`, {
      props: this.props,
      state: this.state,
    });

    if (this.props.onShow)
    {
      this.props.onShow();
    }
  }

  /**
   * Event handler if the component has updated.
   *
   * @param {DialogProps} previousProperties The previous properties.
   * @param {DialogState} previousState The previous state.
   * @returns {void}
   */
  componentDidUpdate(
    previousProperties: DialogProps,
    previousState: DialogState,
  )
  {
    console.debug(`[Dialog] <${this.id}> Dialog component has been updated`, {
      properties: this.props,
      previousProperties: previousProperties,
      state: this.state,
      previousState: previousState,
    });

    if (this.props.visible !== previousProperties.visible)
    {
      if (this.props.visible)
      {
        this.setState({
          visible: this.props.visible,
        });

        this.show();
      }
    }
  }

  /**
   * Event handler if the component will unmount.
   *
   * @returns {void}
   */
  componentWillUnmount(): void
  {
    const dialogClose = document.getElementById(`${this.id}-close`);

    if (dialogClose !== null)
    {
      dialogClose.removeEventListener("click", this.close);
    }

    const dialogCancel = document.getElementById(`${this.id}-cancel`);

    if (dialogCancel !== null)
    {
      dialogCancel.removeEventListener("click", this.cancel);
    }

    const dialogOk = document.getElementById(`${this.id}-ok`);

    if (dialogOk !== null)
    {
      dialogOk.removeEventListener("click", this.confirm);
    }

    const dialogMask = document.getElementById("mum-qg-dialog-mask");

    if (dialogMask !== null)
    {
      dialogMask.removeEventListener("click", this.outOfBounds);
    }

    const dialog = document.getElementById(this.id);

    if (dialog !== null)
    {
      dialogMask?.removeChild(dialog);
    }
  }

  /**
   * Render the component.
   *
   * @returns {JSX.Element} The component instance.
   */
  render(): JSX.Element
  {
    console.debug(`[Dialog] <${this.id}> render`, {
      props: this.props,
      state: this.state,
    });

    const { t } = this.props;

    const Children = () => 
    {
      return this.props.children;
    };

    let dialogMask = document.getElementById("mum-qg-dialog-mask");

    if (dialogMask === null)
    {
      const bodyTag = document.getElementsByTagName("body")[0];

      dialogMask = document.createElement("div");
      dialogMask.classList.add("dialog-mask");
      dialogMask.id = "mum-qg-dialog-mask";
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      dialogMask.addEventListener("click", this.outOfBounds);

      bodyTag.appendChild(dialogMask);
    }

    let dialog = document.getElementById(`${this.id}-content`);

    if (dialog === null)
    {
      console.debug(
        `[Dialog] <${this.id}> render - no dialog elements available`,
        {
          props: this.props,
          state: this.state,
        },
      );

      const dialogWindow = document.createElement("div");
      dialogWindow.classList.add("dialog-window");
      dialogWindow.classList.add("alternate");
      dialogWindow.setAttribute(
        "aria-hidden",
        !this.state.visible ? "true" : "false",
      );
      dialogWindow.id = this.id;

      const dialogHeader = document.createElement("div");
      dialogHeader.classList.add("dialog-header");
      dialogHeader.id = `${this.id}-header`;

      const dialogHeading = document.createElement("h3");
      dialogHeading.classList.add("dialog-heading");
      dialogHeading.id = `${this.id}-heading`;

      dialogHeader.appendChild(dialogHeading);

      const dialogClose = document.createElement("button");
      dialogClose.classList.add("dialog-close");
      dialogClose.id = `${this.id}-close`;
      dialogClose.innerHTML
        = "<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 320 512\"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d=\"M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z\"/></svg>";
      dialogClose.addEventListener("click", this.close);

      dialogHeader.appendChild(dialogClose);

      dialogWindow.appendChild(dialogHeader);

      const dialogContent = document.createElement("div");
      dialogContent.classList.add("dialog-content");
      dialogContent.id = `${this.id}-content`;

      dialogWindow.appendChild(dialogContent);

      const dialogFooter = document.createElement("div");
      dialogFooter.classList.add("dialog-footer");
      dialogFooter.id = `${this.id}-footer`;

      const dialogOk = document.createElement("button");
      dialogOk.id = `${this.id}-ok`;
      dialogOk.classList.add("action-button");
      dialogOk.classList.add("dialog-ok");
      dialogOk.addEventListener("click", this.confirm);

      dialogFooter.appendChild(dialogOk);

      const dialogCancel = document.createElement("button");
      dialogCancel.id = `${this.id}-cancel`;
      dialogCancel.classList.add("action-button");
      dialogCancel.classList.add("dialog-cancel");
      dialogCancel.addEventListener("click", this.cancel);

      dialogFooter.appendChild(dialogCancel);

      dialogWindow.appendChild(dialogFooter);

      dialogMask.appendChild(dialogWindow);

      dialog = dialogContent;
    }

    const dialogWindow = document.getElementById(this.id);

    if (dialogWindow !== null)
    {
      dialogWindow.setAttribute(
        "aria-hidden",
        !this.state.visible ? "true" : "false",
      );
    }

    const dialogHeading = document.getElementById(`${this.id}-heading`);

    if (dialogHeading !== null)
    {
      dialogHeading.textContent = this.props.header;
    }

    const dialogClose = document.getElementById(`${this.id}-close`);

    if (dialogClose !== null)
    {
      dialogClose.removeEventListener("click", this.close);
      dialogClose.addEventListener("click", this.close);
    }

    const dialogCancel = document.getElementById(`${this.id}-cancel`);

    if (dialogCancel !== null)
    {
      dialogCancel.textContent = t("ui.cancel") ?? "Cancel";

      dialogCancel.removeEventListener("click", this.cancel);
      dialogCancel.addEventListener("click", this.cancel);
    }

    const dialogOk = document.getElementById(`${this.id}-ok`);

    if (dialogOk !== null)
    {
      dialogOk.textContent = t("ui.okay") ?? "OK";

      dialogOk.removeEventListener("click", this.confirm);
      dialogOk.addEventListener("click", this.confirm);
    }

    if (dialogMask !== null)
    {
      dialogMask.removeEventListener("click", this.outOfBounds);
      dialogMask.addEventListener("click", this.outOfBounds);
    }

    // eslint-disable-next-line
    const dialogContent = document.getElementById(`${this.id}-content`)!;

    return (
      <React.Fragment>
        {ReactDOM.createPortal(<Children />, dialogContent)}
      </React.Fragment>
    );
  }
}

export default withTranslation()(Dialog);