import { useLocation, useNavigate, useParams } from "react-router-dom";
import React from "react";

/**
 * @callback routedComponent
 * @param {any} props The properties.
 * @returns {JSX.Element} A component which has access to a various router properties.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Return a element which supports the router.
 *
 * @param {React.ElementType} Component The component.
 * @returns {routedComponent} The function for a component with router properties.
 */
export function withRouter(
  Component: React.ElementType,
): (props: any) => JSX.Element
{
  /**
   * Get a component with router properties.
   *
   * @param {any} props The router properties.
   * @returns {JSX.Element} The component which uses router properties.
   */
  function ComponentWithRouterProp(props: any): JSX.Element
  {
    /* eslint-enable @typescript-eslint/no-explicit-any */
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}