import { AppContext } from "../../QGContext";
import { gql } from "@apollo/client";

/**
 * Get the S3 URL of a file.
 *
 * @param {string} itemId The ID of the file.
 * @param {AppContext} context The context.
 * @param {string} projectId The ID of the project.
 */
export async function getFileS3Url(
  itemId: string,
  context: AppContext,
  projectId: string,
): Promise<string>
{
  try 
  {
    const query = gql`
    {
      getFileById(
        id: "${itemId}"
        providerSettings: {
          items: [
            { key: "projectId", value: "${projectId}" }
            { key: "getS3Url", value: "true" }
          ]
        }
      ) {
        code
        internalDetails
        resultDescription
        response {
          s3Url
        }
      }
    }
    `;

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const fileResult = await context.accClient!.query({
      query: query,
      context: {
        headers: {
          accessToken: context.tokens.accessToken,
        },
      },
    });

    return fileResult.data.getFileById.response.s3Url;
  }
  catch (error)
  {
    throw new Error("S3Url not found.");
  }
}

/**
 * Get an acc item by its ID.
 *
 * @param {string} itemId The ID.
 * @param {AppContext} context The context.
 */
export async function getFileById(
  itemId: string,
  context: AppContext,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any>
{
  try 
  {
    const query = gql`
    {
      getFileById(
        id: "${itemId}"
        providerSettings: {
          items: [
            { key: "projectId", value: "${context.project.accProjectId}" }
          ]
        }
      ) {
        code
        internalDetails
        resultDescription
        response {
          id
          fileName
          path
          parentId
          owner
          created
          lastModified
          lastModifiedUserName
          versionNumber
          versionId
          s3Url
          size
        }
      }
    }
    `;

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const fileResult = await context.accClient!.query({
      query: query,
      context: {
        headers: {
          accessToken: context.tokens.accessToken,
        },
      },
    });

    return fileResult.data.getFileById.response;
  }
  catch (error)
  {
    throw new Error("File not found.");
  }
}