/* eslint-disable @typescript-eslint/no-explicit-any */
import * as axios from "axios";
import QGContext from "../../QGContext";
import { useContext } from "react";
import { useQuery } from "@tanstack/react-query";

/**
 * Gets the project members from the Quality Gate API
 *
 * @param {string | null | undefined} projectId The project ID
 * @returns {Promise<any>} the project members from the Quality Gate API
 */
export const useGetProjectMembers = (projectId: string | null | undefined) => 
{
  const ctx = useContext(QGContext);

  // TODO typing
  return useQuery<any, any>({
    queryKey: ["getProjectMembers", projectId],
    queryFn: async () => 
    {
      const response = await axios.default.get(
        `/qualitygate/v1/project/${projectId}/members`,
        {
          headers: {
            userToken: ctx.tokens.idToken,
            accessToken: ctx.tokens.accessToken,
          },
        },
      );
      return response.data;
    },
  });
};