import React, { FunctionComponent, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField/TextField";
import { User } from "../../core/User";
import { useTranslation } from "react-i18next";
import "./ProjectMembersDialog.scss";

/**
 * Interface for the projectmember dialog props.
 *
 * @interface
 */
interface ProjectMemberDialogProps
{
  /**
   * A value indicating whether the dialog is visible or not.
   */
  isVisible: boolean;

  /**
   * The user that is edited, or undefined if a new user is created.
   */
  user: User | undefined;

  /**
   * The create event handler.
   */
  onCreatePojectMember: (email: string, expirationDate: string) => void;

  /**
   * The update event handler.
   */
  onUpdateProjectMember: (userId: string, expirationDate: string) => void;

  /**
   * The cancel event handler.
   */
  onCancel: () => void;
}

/**
 * The projectmember dialog component.
 *
 * @param {ProjectMemberDialogProps} props The props.
 * @returns {JSX.Element} The component instance.
 */
const ProjectMemberDialog: FunctionComponent<ProjectMemberDialogProps> = (
  props: ProjectMemberDialogProps,
): JSX.Element => 
{
  const { t } = useTranslation();

  const useCase = props.user !== undefined ? "update" : "create";

  const [email, setEmail] = useState<string>(props.user?.emailAddress ?? "");
  const [expirationDate, setExpirationDate] = useState<Date>(new Date());
  const [neverExpire, setNeverExpire] = useState(false);

  const handleConfirm = (event: React.FormEvent<HTMLFormElement>) => 
  {
    const date = neverExpire
      ? new Date(86400000000000).toISOString()
      : expirationDate.toISOString();

    if (useCase === "update")
    {
      props.onUpdateProjectMember(props.user?.id ?? "", date);
    }
    else 
    {
      props.onCreatePojectMember(email, date);
    }
  };

  return (
    <Dialog
      open={props.isVisible}
      onClose={props.onCancel}
      PaperProps={{
        component: "form",
        onSubmit: handleConfirm,
      }}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>{t("ui.projectmembers.dialog.header")}</DialogTitle>
      <DialogContent>
        <div id="user-create-form">
          <div className="form-group">
            <label htmlFor="user-create-email" style={{ marginTop: "15px" }}>
              {t("ui.projectmembers.create.email")}
            </label>
            <TextField
              autoFocus
              type="email"
              variant="standard"
              label="E-Mail"
              placeholder="E-Mail"
              name="user-create-email"
              id="user-create-email"
              value={email}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setEmail(e.target.value)
              }
              sx={{ minWidth: "320px" }}
              disabled={useCase === "update"}
            />
          </div>
          <div className="form-group">
            <label htmlFor="user-create-expirationdate">
              {t("ui.projectmembers.create.expirationdate")}
            </label>
            <input
              style={{ maxWidth: "320px" }}
              type="date"
              name="user-create-expirationdate"
              id="user-create-expirationdate"
              disabled={neverExpire}
              value={
                neverExpire ? "" : expirationDate?.toISOString().split("T")[0]
              }
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => 
              {
                const dateValue = event.target.valueAsDate ?? new Date();
                setExpirationDate(dateValue);
              }}
            />
          </div>
          <div className="form-group">
            <input
              type="checkbox"
              name="user-create-expirationdate-never-expire"
              id="user-create-expirationdate-never-expire"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => 
              {
                setNeverExpire(!neverExpire);
              }}
            />
            <label
              style={{ color: "black" }}
              htmlFor="user-create-expirationdate-never-expire"
            >
              {t("ui.projectmembers.create.neverexpire")}
            </label>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onCancel}>{t("ui.cancel")}</Button>
        <Button type="submit">{t("ui.okay")}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProjectMemberDialog;