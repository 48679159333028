import * as React from "react";
import { AbstractReactFactory } from "@projectstorm/react-canvas-core";
import { CustomNodeModel } from "./CustomNodeModel";
import { CustomNodeWidget } from "./CustomNodeWidget";
import { DiagramEngine } from "@projectstorm/react-diagrams-core";

/**
 * The custom node factory.
 *
 * @class
 * @augments {AbstractReactFactory<CustomNodeModel, DiagramEngine>}
 */
export class CustomNodeFactory extends AbstractReactFactory<
  CustomNodeModel,
  DiagramEngine
>
{
  /**
   * Create an instance of CustomNodeFactory.
   */
  constructor()
  {
    super("custom-node");
  }

  /**
   * Generate the model.
   *
   * @param {any} initialConfig The inital config.
   * @returns {void}
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  generateModel(initialConfig: any)
  {
    return new CustomNodeModel();
  }

  /**
   * Generate a react widget.
   *
   * @param {any} event The event.
   * @returns {JSX.Element} The react widget.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  generateReactWidget(event: any): JSX.Element
  {
    return (
      <CustomNodeWidget
        engine={this.engine as DiagramEngine}
        node={event.model}
      />
    );
  }
}