/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FunctionComponent } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { useTranslation } from "react-i18next";

/**
 * The validation arrangement functional component props
 *
 * @interface
 */
interface ValidationArrangementDialogProps
{
  /**
   * A value indicating whether the dialog is visible or not.
   */
  isVisible: boolean;
  /**
   * The validation sets.
   */
  validationSetFlatList: Array<any>;
  /**
   * The event handler.
   */
  onSelectValidationSetFromDialog: (
    validationSetId: string,
    validationSetName: string,
  ) => void;
  /**
   * The cancel event handler.
   */
  onCancel: () => void;
}

/**
 * The validation arrangement functional component
 *
 * @param {ValidationArrangementDialogProps} props - The props
 * @returns {JSX.Element} - The element
 */
const ValidationArrangementDialog: FunctionComponent<
  ValidationArrangementDialogProps
> = (props: ValidationArrangementDialogProps) => 
{
  const { t } = useTranslation();
  const [selectedValidationSetId, setSelectedValidationSetId]
    = React.useState<string>("");

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => 
  {
    event.preventDefault();

    if (!selectedValidationSetId)
    {
      props.onCancel();
      return;
    }

    props.onSelectValidationSetFromDialog(
      selectedValidationSetId!,
      props.validationSetFlatList.find(
        (set: any) => set.id === selectedValidationSetId,
      )!.name,
    );
  };

  return (
    <Dialog
      open={props.isVisible}
      onClose={props.onCancel}
      PaperProps={{
        component: "form",
        onSubmit: handleSubmit,
      }}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>{t("ui.validationarrangement.dialog.header")}</DialogTitle>
      <DialogContent>
        {props.validationSetFlatList.length > 0 && (
          <FormControl>
            <RadioGroup
              name="validationset-radio-buttons-group"
              value={selectedValidationSetId}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => 
              {
                setSelectedValidationSetId(
                  (event.target as HTMLInputElement).value,
                );
              }}
            >
              {/*eslint-disable-next-line @typescript-eslint/no-explicit-any*/}
              {props.validationSetFlatList.map((set: any) => (
                <FormControlLabel
                  key={set.id}
                  value={set.id}
                  control={<Radio />}
                  label={set.name}
                  style={{ marginLeft: `${set.level * 2}em` }}
                />
              ))}
            </RadioGroup>
          </FormControl>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onCancel}>{t("ui.cancel")}</Button>
        <Button type="submit">{t("ui.okay")}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ValidationArrangementDialog;