/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  Alert,
  Box,
  Checkbox,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import QGContext, { AppContext } from "../QGContext";
import { WithTranslation, withTranslation } from "react-i18next";
import { AccBaseEntity } from "../core/AccBaseEntity";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FolderIcon from "@mui/icons-material/Folder";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import React from "react";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { gql } from "@apollo/client/core";
import "./GenericMenu.scss";

/**
 * Interface for the Acc Folder List arguments.
 *
 * @interface
 */
interface AccProjectExplorerViewArguments extends WithTranslation
{
  /**
   * The on file selected event.
   *
   * @param {AccBaseEntity} entity The entity.
   */
  onFileSelected: (entity: AccBaseEntity) => void;

  /**
   * The is authenticated flag.
   *
   * @property {boolean | undefined} isAccAuthenticated The is authenticated flag.
   */
  isAccAuthenticated: boolean | undefined;
}

/**
 * Interface for the generic menu state.
 *
 * @interface
 */
interface AccProjectExplorerViewState
{
  /**
   * Loading spinner show.
   *
   * @property {boolean} loading Loading spinner show.
   */
  loading: boolean;

  /**
   * The Entity list.
   *
   * @property {AccBaseEntity[] | null} entities The entity list.
   */
  entities: AccBaseEntity[] | null;

  /**
   * The folder id path.
   *
   * @property {string[]} folderIdPath The folder id path.
   */
  folderIdPath: string[];

  /**
   * The folder id.
   *
   * @property {string} folderId The folder id.
   */
  folderId: string;

  /**
   * The filter.
   *
   * @property {string} filter The filter.
   */
  filter: string;

  /**
   * Include parent names.
   *
   * @property {boolean} includeParentNames Include parent names.
   */
  includeParentNames: boolean;
}

/**
 * The Acc Folder Explorer View Component.
 *
 * @class
 * @augments {React.Component<AccProjectExplorerViewArguments, AccProjectExplorerViewState>}
 */
class AccProjectExplorerView extends React.Component<
  AccProjectExplorerViewArguments,
  AccProjectExplorerViewState
>
{
  static contextType = QGContext;

  /**
   * The timeout id.
   *
   * @property {ReturnType<typeof setTimeout> | null} timeoutId The timeout id.
   */
  timeoutId: ReturnType<typeof setTimeout> | null;

  /**
   * Create an instance of AccFolderList.
   *
   * @param {AccProjectExplorerViewArguments} props The properties.
   */
  constructor(props: AccProjectExplorerViewArguments)
  {
    super(props);

    this.state = {
      loading: false,
      entities: null,
      folderId: "",
      folderIdPath: [],
      filter: "",
      includeParentNames: true,
    };
    this.timeoutId = null;

    this.loadEntities = this.loadEntities.bind(this);
    this.startLoading = this.startLoading.bind(this);
    this.endLoading = this.endLoading.bind(this);
    this.getColumns = this.getColumns.bind(this);
    this.renderCellIcon = this.renderCellIcon.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.addPathElement = this.addPathElement.bind(this);
    this.removePathElement = this.removePathElement.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
    this.loadFilteredEntities = this.loadFilteredEntities.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.startSearchLogic = this.startSearchLogic.bind(this);
    this.filterEntities = this.filterEntities.bind(this);
    this.addParentFolderToName = this.addParentFolderToName.bind(this);
  }

  componentDidMount()
  {
    if (this.props.isAccAuthenticated)
    {
      const { accHubId, accProjectId } = (this.context as AppContext).project;
      if (accHubId && accProjectId)
      {
        const { filter } = this.state;
        if (filter !== "")
        {
          this.startSearchLogic(
            filter,
            accHubId,
            accProjectId,
            this.state.folderId,
          );
        }
        else 
        {
          this.loadEntities(accHubId, accProjectId, this.state.folderId);
        }
      }
    }
  }

  /**
   * Filters the entities based on specific criteria.
   *
   * @param {AccBaseEntity[] | null} entities The entities to filter.
   * @returns {AccBaseEntity[]} The filtered entities.
   */
  filterEntities(entities: AccBaseEntity[] | null): AccBaseEntity[]
  {
    if (entities === null)
    {
      return [];
    }

    const filteredEntities = entities.filter((entity) => 
    {
      return entity.type === "FOLDER" || entity.displayName.includes(".ifc");
    });

    return filteredEntities;
  }

  startSearchLogic(
    filter: string,
    hubId: string,
    projectId: string,
    folderId: string,
  )
  {
    if (this.timeoutId)
    {
      clearTimeout(this.timeoutId);
    }

    this.timeoutId = setTimeout(() => 
    {
      if (filter !== "")
      {
        this.loadFilteredEntities(filter, hubId, projectId, folderId);
      }
    }, 500);
  }

  handleSearchChange(event: React.ChangeEvent<HTMLInputElement>)
  {
    const value = event.target.value;
    this.setState({ filter: value }, () => 
    {
      const { accHubId, accProjectId } = (this.context as AppContext).project;
      if (accHubId && accProjectId)
      {
        this.startSearchLogic(
          value,
          accHubId,
          accProjectId,
          this.state.folderId,
        );
      }
    });
  }

  /**
   * Load the filtered projects.
   *
   * @param {string} filter The filter.
   * @param {string} hubId The hub ID.
   * @param {string} projectId The project ID.
   * @param {string} folderId The folder ID.
   */
  async loadFilteredEntities(
    filter: string,
    hubId: string,
    projectId: string,
    folderId: string,
  )
  {
    const ctx = this.context as AppContext;

    this.startLoading();

    const query = gql`
    {
      getFilteredContents(
        filterName: "${filter}"
        hubId: "${hubId}"
        projectId: "${projectId}"
        folderId: "${folderId}"
      ) {
        code
        internalDetails
        resultDescription
        response {
          displayName
          id
          type
          lastModifiedDate
          parentId
        }
      }
    }
    `;

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const entitiesResult = await ctx.accClient!.query({
      query: query,
      context: {
        headers: {
          accessToken: ctx.tokens.accessToken,
        },
      },
    });

    if (!entitiesResult.data.getFilteredContents.response)
    {
      this.endLoading();
      return;
    }

    const entities
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      = (entitiesResult.data.getFilteredContents.response as Array<any>).map(
        (entity) => 
        {
          console.debug("[AccFolderList] get filtered contents entity", entity);
          return {
            id: entity.id,
            displayName: entity.displayName,
            type: entity.type,
            lastModifiedDate: entity.lastModifiedDate,
            parentId: entity.parentId,
          };
        },
      );

    let filteredEntities = this.filterEntities(entities);

    if (this.state.includeParentNames)
    {
      filteredEntities = await this.addParentFolderToName(filteredEntities);
    }

    if (JSON.stringify(this.state.entities) === JSON.stringify(entities))
    {
      return;
    }

    this.setState(
      {
        entities: filteredEntities,
      },
      () => 
      {
        this.endLoading();
      },
    );
  }

  /**
   * Adds the parent folder to the name of the entities.
   *
   * @param {AccBaseEntity[]} entities The entities.
   * @returns {AccBaseEntity[]} The entities with the parent folder name.
   */
  async addParentFolderToName(
    entities: AccBaseEntity[],
  ): Promise<AccBaseEntity[]>
  {
    this.startLoading();

    for (let i = 0; i < entities.length - 1; i++)
    {
      const entity = entities[i];
      if (entity.type === "ITEM")
      {
        if (entity.parentId)
        {
          try 
          {
            const parentFolderResult = await (
              this.context as AppContext
            ).accClient!.query({
              query: gql`
              {
                getFolderById(
                  projectId: "${window.sessionStorage.getItem("accProjectId")}"
                  id: "${entity.parentId}"
                ) {
                  code
                  internalDetails
                  resultDescription
                  response {
                    displayName
                  }
                }
              }
              `,
              context: {
                headers: {
                  accessToken: (this.context as AppContext).tokens.accessToken,
                },
              },
            });

            const parentDisplayName
              = parentFolderResult.data.getFolderById.response.displayName;

            if (parentDisplayName !== "")
            {
              entity.displayName
                = parentFolderResult + " / " + entity.displayName;
            }
          }
          catch (_)
          {
            continue;
          }
        }
      }
    }

    this.endLoading();
    return entities;
  }

  /**
   * Load the project.
   *
   * @param {string} hubId The hub ID.
   * @param {string} projectId The project ID.
   * @param {string} folderId The folder ID.
   * @returns {void}
   */
  async loadEntities(hubId: string, projectId: string, folderId: string)
  {
    this.startLoading();

    let query;
    if (folderId === "")
    {
      query = gql`
            {
                getContents(
                  hubId: "${hubId}"
                  projectId: "${projectId}"
                ) {
                  code
                  internalDetails
                  resultDescription
                  response {
                    displayName
                    id
                    type
                    lastModifiedDate
                    parentId
                  }
                }
              }
            `;
    }
    else 
    {
      query = gql`
            {
                getContents(
                  hubId: "${hubId}"
                  projectId: "${projectId}"
                  folderId: "${folderId}"
                ) {
                  code
                  internalDetails
                  resultDescription
                  response {
                    displayName
                    id
                    type
                    lastModifiedDate
                    parentId
                  }
                }
              }
            `;
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    await (this.context as AppContext)
      .accClient!.query({
        query: query,
        context: {
          headers: {
            accessToken: (this.context as AppContext).tokens.accessToken,
          },
        },
      })
      .then((result) => 
      {
        if (
          result.data.getContents.response === null
          || result.data.getContents.response === undefined
        )
        {
          this.endLoading();
          return;
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const entities = (result.data.getContents.response as Array<any>).map(
          (entity) => 
          {
            return {
              id: entity.id,
              displayName: entity.displayName,
              type: entity.type,
              lastModifiedDate: entity.lastModifiedDate,
              parentId: entity.parentId,
            };
          },
        );

        if (JSON.stringify(this.state.entities) === JSON.stringify(entities))
        {
          return;
        }

        const filteredEntities = this.filterEntities(entities);
        this.setState(
          {
            entities: filteredEntities,
          },
          () => 
          {
            this.endLoading();
          },
        );
      })
      .catch((error) => 
      {
        this.endLoading();
        console.error("[AccFolderList] get Contents error", error);
      });
  }

  renderCellIcon(props: GridRenderCellParams): JSX.Element
  {
    return (
      <div>
        {props.row.type === "FOLDER" ? (
          <IconButton>
            <FolderIcon />
          </IconButton>
        ) : (
          <IconButton>
            <InsertDriveFileIcon />
          </IconButton>
        )}
      </div>
    );
  }

  getColumns(): GridColDef[]
  {
    const { t } = this.props;

    const displayNameHeader = t("ui.accfolderlist.name");
    const lastModifiedHeader = t("ui.accfolderlist.lastmodified");

    const columns: GridColDef[] = [
      {
        field: "id",
        headerName: "",
        width: 110,
        disableColumnMenu: true,
        renderCell: this.renderCellIcon,
        renderHeader: () => 
        {
          if (this.state.folderIdPath.length > 0)
          {
            return (
              <IconButton onClick={this.handleBackClick}>
                <ArrowBackIcon />
              </IconButton>
            );
          }
        },
      },
      {
        field: "displayName",
        headerName: displayNameHeader,
        width: 350,
      },
      {
        field: "lastModifiedDate",
        headerName: lastModifiedHeader,
        width: 200,
        valueGetter: (value: GridValueGetterParams) => 
        {
          return value?.value
            ? new Date(value.value as string).toLocaleString("de-DE")
            : "";
        },
      },
    ];
    return columns;
  }

  /**
   * Start loading spinner.
   */
  private startLoading()
  {
    this.setState({
      loading: true,
    });
  }

  /**
   * End loading spinner
   */
  private endLoading()
  {
    this.setState({
      loading: false,
    });
  }

  addPathElement(folderId: string)
  {
    this.setState({
      folderId: folderId,
    });
    this.state.folderIdPath.push(folderId);

    console.log("Add", folderId, this.state.folderIdPath);
  }

  removePathElement(folderId: string)
  {
    const index = this.state.folderIdPath.indexOf(folderId);
    if (index > -1)
    {
      this.state.folderIdPath.splice(index, 1);
    }
  }

  handleBackClick = () => 
  {
    console.log("Back", this.state.folderIdPath);
    if (this.state.folderIdPath.length > 0)
    {
      this.state.folderIdPath.pop();

      const { accHubId, accProjectId } = (this.context as AppContext).project;

      if (accHubId && accProjectId)
      {
        if (this.state.folderIdPath.length - 1 > 0)
        {
          this.setState({
            folderId:
              this.state.folderIdPath[this.state.folderIdPath.length - 1],
          });
          this.loadEntities(
            accHubId,
            accProjectId,
            this.state.folderIdPath[this.state.folderIdPath.length - 1],
          );
        }
        else 
        {
          this.setState({
            folderId: "",
          });
          this.loadEntities(accHubId, accProjectId, "");
        }
      }
    }
  };

  handleClick = (params: GridRowParams) => 
  {
    if (params.row.type === "FOLDER")
    {
      const newFolderId = params.id as string;
      const { accHubId, accProjectId } = (this.context as AppContext).project;

      this.addPathElement(newFolderId);

      if (accHubId && accProjectId)
      {
        this.loadEntities(accHubId, accProjectId, newFolderId);
      }
    }
    if (params.row.type === "ITEM")
    {
      const entity = params.row as AccBaseEntity;
      this.props.onFileSelected(entity);
    }
  };

  /**
   * Render the component.
   *
   * @returns {JSX.Element} The component instance.
   */
  render()
  {
    if (this.props.isAccAuthenticated === undefined)
    {
      return (
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CircularProgress className="spinner" />
        </Box>
      );
    }

    const { t } = this.props;

    if (this.props.isAccAuthenticated)
    {
      const { accHubId, accProjectId } = (this.context as AppContext).project;
      if (!accHubId || !accProjectId)
      {
        return (
          <Box
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <Typography
              style={{
                minWidth: 800,
                minHeight: 400,
                background: "white",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
              }}
              variant="h6"
            >
              {/** TODO translate */}
              <Alert severity="warning">
                For this project, no ACC project has been connected yet.
              </Alert>
            </Typography>
          </Box>
        );
      }

      const columnDefinition = this.getColumns();

      return (
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <div style={{ display: "flex" }}>
            <TextField
              style={{ marginBottom: 10, minWidth: 800, background: "white" }}
              label="Suche"
              type="text"
              variant="outlined"
              value={this.state.filter}
              onChange={this.handleSearchChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => 
                      {
                        this.setState(
                          {
                            folderId: "",
                            folderIdPath: [],
                            filter: "",
                          },
                          () => 
                          {
                            this.componentDidMount();
                          },
                        );
                      }}
                      edge="end"
                      disabled={this.state.filter === ""}
                    >
                      <RestartAltIcon />
                    </IconButton>
                    <Tooltip title={t("ui.accfolderlist.includeparentnames")}>
                      <Checkbox
                        defaultChecked
                        value={this.state.includeParentNames}
                        onChange={(event) => 
                        {
                          this.setState({
                            includeParentNames: event.target.checked,
                          });
                        }}
                      />
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <DataGrid
            style={{
              height: 400,
              minWidth: 800,
              minHeight: 400,
              background: "white",
            }}
            columns={columnDefinition}
            filterMode="client"
            rows={this.state.entities ?? []}
            getRowId={(row) => row.id}
            loading={this.state.loading}
            pageSizeOptions={[5, 10, 25, 100]}
            onRowClick={this.handleClick}
            localeText={{
              noRowsLabel: t("ui.accfolderlist.nocontentorpermission") ?? "",
            }}
            disableColumnMenu
          />
        </Box>
      );
    }
    else 
    {
      return (
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Typography
            style={{
              minWidth: 800,
              minHeight: 400,
              background: "white",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
            variant="h6"
          >
            <Alert severity="info">
              {t("ui.accfolderlist.notauthenticated")}
            </Alert>
          </Typography>
        </Box>
      );
    }
  }
}

export default withTranslation()(AccProjectExplorerView);