import { WithTranslation, withTranslation } from "react-i18next";
import QGContext from "../QGContext";
import React from "react";
import { WithRouter } from "../interfaces/WithRouter";
import axios from "axios";
import { withRouter } from "../support/withRouter";

/**
 * Interface for the error boundary arguments.
 *
 * @interface
 * @augments {WithTranslation}
 * @augments {WithRouter}
 */
interface ErrorBoundaryArguments extends WithTranslation, WithRouter
{
  /**
   * The children.
   *
   * @property {any} children The children.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
}

/**
 * Interface for the error state.
 *
 * @interface
 */
interface ErrorBoundaryState
{
  /**
   * The error.
   *
   * @property {Error | null} error The error.
   */
  error: Error | null;

  /**
   * The error info.
   *
   * @property {React.ErrorInfo | null} errorInfo The error info.
   */
  errorInfo: React.ErrorInfo | null;
}

/**
 * The error boundary.
 *
 * @class
 * @augments {React.Component<ErrorBoundaryArguments, ErrorBoundaryState>}
 */
class ErrorBoundary extends React.Component<
  ErrorBoundaryArguments,
  ErrorBoundaryState
>
{
  static contextType = QGContext;
  /**
   * Create an instance of ErrorBoundary.
   *
   * @param {ErrorBoundaryArguments} props The properties.
   */
  constructor(props: ErrorBoundaryArguments)
  {
    super(props);

    this.state = {
      error: null,
      errorInfo: null,
    };
  }

  /**
   * Event handler if the component catched anything.
   *
   * @param {Error} error The error.
   * @param {React.ErrorInfo} errorInfo The error information.
   * @returns {void}
   */
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo)
  {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);

    this.setState({
      error: error,
      errorInfo: errorInfo,
    });

    const logMessage = `${error.name}: ${error.message}\r\n${error.stack}\r\n${errorInfo.componentStack}`;

    axios
      .post("/qualitygate/v1/log?logLevel=error", JSON.stringify(logMessage), {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => 
      {
        console.debug("[ErrorBoundary] error logged", {
          response: response,
        });
      });
  }

  /**
   * Render the component.
   *
   * @returns {JSX.Element} The component instance.
   */
  render()
  {
    if (this.state.errorInfo)
    {
      const { t } = this.props;

      // You can render any custom fallback UI
      return (
        <div>
          <h1>{t("ui.error.heading")}</h1>
          <details style={{ whiteSpace: "pre-wrap" }}>
            {this.state.error && this.state.error.name}:
            {this.state.error && this.state.error.message}
            <br />
            {this.state.error && this.state.error.stack}
            <br />
            {this.state.errorInfo.componentStack}
          </details>
        </div>
      );
    }

    return this.props.children;
  }
}

export default withTranslation()(withRouter(ErrorBoundary));