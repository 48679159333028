import QGContext, { AppContext } from "../QGContext";
import { WithTranslation, withTranslation } from "react-i18next";
import axios, { AxiosRequestConfig } from "axios";
import { ACCOUNT_URL } from "../environment";
import React from "react";

/**
 * Interface for the user info arguments.
 *
 * @interface
 * @augments {WithTranslation}
 */
interface UserInfoArguments extends WithTranslation
{
  /**
   * The user image URL.
   *
   * @property {string} userImage The user image URL.
   */
  userImage: string | null;
}

/**
 * Interface for the user info state.
 *
 * @interface
 */
interface UserInfoState
{
  /**
   * A value indicating whether the dark mode should be used or not.
   *
   * @property {boolean} darkMode A value indicating whether the dark mode should be used or not.
   */
  darkMode: boolean;

  /**
   * A value indicating whether the dialog to change the user profile picture should be displayed or not.
   *
   * @property {boolean} showChangePictureDialog A value indicating whether the dialog to change the user profile picture should be displayed or not.
   */
  showChangePictureDialog: boolean;

  /**
   * A value indicating whether the component is reloading data.
   *
   * @property {boolean} isLoading A value indicating whether the component is reloading data.
   */
  isLoading: boolean;

  /**
   * A value indicating whether the component uploads data.
   *
   * @property {boolean} isUploading A value indicating whether the component uploads data.
   */
  isUploading: boolean;

  /**
   * The user image content.
   *
   * @property {string} imageContent The user image content.
   */
  imageContent: string;
}

/**
 * The User Info component.
 *
 * @class
 * @augments {React.Component<UserInfoArguments, UserInfoState>}
 */
class UserInfo extends React.Component<UserInfoArguments, UserInfoState>
{
  static readonly contextType = QGContext;

  /**
   * The user image URL.
   *
   * @property {string | null} userImage The user image URL.
   */
  userImage: string | null;

  /**
   * A value indicating whether the dark mode should be used or not.
   *
   * @property {boolean} darkMode A value indicating whether the dark mode should be used or not.
   */
  darkMode: boolean;

  /**
   * Create an instance of UserInfo.
   *
   * @param {UserInfoArguments} props The properties.
   */
  constructor(props: UserInfoArguments)
  {
    super(props);

    /*
    const darkMode
      = window.matchMedia
      && window.matchMedia("(prefers-color-scheme: dark)").matches;
    */

    this.userImage = props.userImage;
    this.darkMode = false;

    // this.toggleDarkMode = this.toggleDarkMode.bind(this);
    this.changeProfilePicture = this.changeProfilePicture.bind(this);
    this.uploadUserPicture = this.uploadUserPicture.bind(this);

    this.state = {
      darkMode: false,
      showChangePictureDialog: false,
      isLoading: false,
      isUploading: false,
      imageContent: "",
    };
  }

  /**
   * The event handler after the component did mount.
   *
   * @returns {void}
   */
  componentDidMount(): void
  {
    axios
      .get(
        `${ACCOUNT_URL}/user/v1/image?userId=${
          (this.context as AppContext).user.id
        }`,
      )
      .then((imageResponse) => 
      {
        this.setState({
          imageContent: imageResponse.data,
        });
      });
  }

  /**
   * Return a boolean value whether the component should be updated.
   *
   * @param {UserInfoArguments} nextProps The next properties.
   * @param {UserInfoState} nextState The next state.
   * @returns {boolean} A value indicating whether the component should be updated.
   */
  shouldComponentUpdate(
    nextProps: UserInfoArguments,
    nextState: UserInfoState,
  )
  {
    return (
      this.state.darkMode !== nextState.darkMode
      || this.state.showChangePictureDialog
        !== nextState.showChangePictureDialog
      || this.state.imageContent !== nextState.imageContent
    );
  }

  /**
   * Change the profile picture.
   *
   * @returns {void}
   */
  changeProfilePicture()
  {
    console.debug("[UserInfo] change profile picture", {
      state: this.state,
    });

    this.setState({
      showChangePictureDialog: true,
    });
  }

  /**
   * Hide the dialog to change the profile picture.
   *
   * @returns {void}
   */
  onHideChangePictureDialog()
  {
    this.setState({
      showChangePictureDialog: false,
    });
  }

  /**
   * Upload a user picture.
   *
   * @param {File} file The file.
   * @returns {void}
   */
  uploadUserPicture(file: File)
  {
    console.debug("[UserInfo] upload user picture", {
      file: file,
    });

    try 
    {
      this.setState({
        isUploading: true,
        isLoading: true,
      });

      const context = this.context as AppContext;

      axios
        .get(
          `${ACCOUNT_URL}/user/v1/image/upload?fileFormat=${file.name
            .split(".")
            .pop()}`,
          {
            headers: {
              userToken: context.tokens.idToken,
              accessToken: context.tokens.accessToken,
              userId: context.user.id,
            },
          },
        )
        .then((presignedUrl) => 
        {
          const config: AxiosRequestConfig<File> = {
            headers: {
              "Content-Type": "application/octet-stream",
            },
            onUploadProgress: (progressEvent) =>
              console.debug(
                `[UserInfo] upload user image ${progressEvent.loaded}/${
                  file.size
                } (${(progressEvent.loaded / file.size) * 100}%)`,
              ),
          };

          axios
            .put(presignedUrl.data.response, file, config)
            .then((response) => 
            {
              console.debug("[UserInfo] user image upload successful.");

              // TODO update user image
            })
            .catch((e) => 
            {
              console.debug("[UserInfo] file upload ERROR:", e);
              console.debug("[UserInfo]  e.response", e.response);

              throw e;
            });

          this.setState({
            isUploading: false,
            isLoading: false,
          });
        });
    }
    catch (exception: unknown)
    {
      console.error(exception);

      axios
        .post("/qualitygate/v1/log?logLevel=error", JSON.stringify(exception), {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => 
        {
          console.debug("[UserInfo] error logged", {
            response: response,
          });
        });

      this.setState({
        isUploading: false,
        isLoading: false,
      });
    }
  }

  /**
   * Event handler if a file hovers over the upload component.
   *
   * @returns {void}
   */
  fileHovering()
  {
    console.warn("[UserInfo] fileHovering - Not implemented");
  }

  /**
   * Render the component.
   *
   * @returns {JSX.Element} The component instance.
   */
  render()
  {
    console.debug("[UserInfo] render", { state: this.state });

    /*
    const fontAwesomeIcon: IconProp = !this.state.darkMode
      ? "moon"
      : ["far", "lightbulb"];
    */

    // const allowedFileTypes = ["jpg", "jpeg", "png"];

    return (
      <React.Fragment>
        {/* Profile Content */}
        <div className="profileContainer flex justify-start gap-x-4 items-center">
          {/* Profile Content */}
          <img
            className="rounded-full w-[32px] h-[32px]"
            src={`data:image/webp;base64, ${this.state.imageContent}`}
            alt={(this.context as AppContext).user.email}
          />
          <ul>
            <div className="profileContents text-left">
              <p className="name text-white text-sm font-medium">
                {(this.context as AppContext).user.firstName}
              </p>
              <p className="email text-gray-400 text-xs font-medium">
                {(this.context as AppContext).user.email}
              </p>
            </div>
          </ul>
        </div>
      </React.Fragment>
    );
  }
}

export default withTranslation()(UserInfo);