import React, { FunctionComponent } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "react-i18next";

interface DialogProps
{
  open: boolean;
  onClose: () => void;
  title: string;
  message: string;
  onConfirm: () => void;
}

/**
 * The custom dialog functional component
 *
 * @param {DialogProps} props - The props
 * @returns {JSX.Element} - The element
 */
const AgreeOrDenyDialog: FunctionComponent<DialogProps> = ({
  open,
  onClose,
  onConfirm,
  title,
  message,
}) => 
{
  const { t } = useTranslation();
  const handleClose = () => 
  {
    onClose();
  };

  const handleConfirm = () => 
  {
    onClose();
    onConfirm();
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            {t("ui.agreeordenydialog.deny")}
          </Button>
          <Button onClick={handleConfirm} color="primary">
            {t("ui.agreeordenydialog.confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AgreeOrDenyDialog;