import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

/**
 * Helper function to conditionally add Tailwind CSS classes.
 * See https://ui.shadcn.com/docs/installation/manual
 *
 * @param {Array<ClassValue>} inputs - Classes to merge.
 * @returns {string } merged class names.
 */
export function cn(...inputs: Array<ClassValue>)
{
  return twMerge(clsx(inputs));
}