import * as React from "react";
import { DiagramEngine, PortWidget } from "@projectstorm/react-diagrams-core";
import { ClassPortModel } from "./ClassPortModel";
import styled from "@emotion/styled";

/**
 * Interface for the class port widget arguments.
 *
 * @interface
 */
export interface ClassPortLabelProps
{
  /**
   * The port.
   *
   * @property {ClassPortModel} port The port.
   */
  port: ClassPortModel;

  /**
   * The engine.
   *
   * @property {DiagramEngine} engine The engine.
   */
  engine: DiagramEngine;
}

/**
 * The styling namespace for a port label widget.
 *
 * @namespace
 */
namespace S 
{
  /**
   * The port label.
   */
  export const PortLabel = styled.div`
    display: flex;
    margin-top: 1px;
    align-items: center;
    justify-content: center;
  `;

  /**
   * The label.
   */
  export const Label = styled.div`
    padding: 0 5px;
    flex-grow: 1;
  `;

  /**
   * The port.
   */
  export const Port = styled.div`
    width: 100%;
    height: 0.75rem;
    background: rgba(255, 255, 255, 0.1);
    &:hover {
      background: rgb(192, 255, 0);
    }
  `;
}

/**
 * The custom port label.
 *
 * @class
 * @augments {React.Component<ClassPortLabelProps>}
 */
export class ClassPortLabelWidget extends React.Component<ClassPortLabelProps>
{
  /**
   * Render the component.
   *
   * @returns {JSX.Element} The component instance.
   */
  render()
  {
    const portStyle = {
      width: "100%",
    };

    const port = (
      <PortWidget
        engine={this.props.engine}
        port={this.props.port}
        style={portStyle}
      >
        <S.Port />
      </PortWidget>
    );

    return <S.PortLabel>{port}</S.PortLabel>;
  }
}