/* eslint-disable @typescript-eslint/no-explicit-any */
import * as axios from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import QGContext from "../../QGContext";
import { useContext } from "react";

/**
 * Gets the models from the Quality Gate API
 *
 * @param {string} projectId The project ID
 * @returns {Promise<any>} The project from the Quality Gate API
 */
export const usePutProject = (projectId: string) => 
{
  const ctx = useContext(QGContext);
  const queryClient = useQueryClient();

  return useMutation({
    // TODO typing
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    mutationFn: async (args: any) => 
    {
      console.log("usePutProject", args);
      const response = await axios.default.put(
        `/qualitygate/v1/project/${projectId}`,
        {
          id: projectId,
          name: args.name,
          address: {
            street: args.street,
            houseNumber: args.houseNumber,
            zip: args.zip,
            city: args.city,
          },
          description: args.description,
          defaultRoleId: args.defaultRoleId,
          accHubId: args.accHubId,
          accProjectId: args.accProjectId,
          roleUserMapping: {},
          image: "",
          ownerId: "",
          projectNumber: args.projectNumber,
        },
        {
          headers: {
            userToken: ctx.tokens.idToken,
            accessToken: ctx.tokens.accessToken,
          },
        },
      );

      return !!response.data;
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ["getProject", projectId],
      }),
  });
};