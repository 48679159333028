/* eslint-disable @typescript-eslint/no-explicit-any */
import * as axios from "axios";
import QGContext from "../../QGContext";
import { useContext } from "react";
import { useQuery } from "@tanstack/react-query";

/**
 * Gets the models from the Quality Gate API
 *
 * @param {string} projectId The project ID
 * @returns {Promise<any>} The models from the Quality Gate API
 */
export const useGetModels = (projectId: string) => 
{
  const ctx = useContext(QGContext);

  // TODO typing
  return useQuery<any, any>({
    queryKey: ["getModels", projectId],
    queryFn: async () => 
    {
      const response = await axios.default.get(
        `/qualitygate/v1/models/${ctx.project.id}`,
        {
          headers: {
            userToken: ctx.tokens.idToken,
            accessToken: ctx.tokens.accessToken,
          },
        },
      );
      return response.data;
    },
    enabled: !!projectId,
  });
};