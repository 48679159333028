import {
  NotificationType,
  NotificationTypes,
  StakeholderListItem,
} from "../../components/Stakeholder";
import React, { FunctionComponent, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Select from "react-select";
import TextField from "@mui/material/TextField/TextField";
import { useTranslation } from "react-i18next";
import "./StakeholderDialog.scss";

/**
 * Interface for the stakeholder dialog props.
 *
 * @interface
 */
interface StakeholderDialogProps
{
  /**
   * A value indicating whether the dialog is visible or not.
   */
  isVisible: boolean;

  /**
   * The stakeholder that is edited, or undefined if a new stakeholer is created.
   */
  stakeholder: StakeholderListItem | undefined;

  /**
   * The list of already assigned email addresses.
   */
  alreadyAssignedEmailAddresses: Array<string>;

  /**
   * The create event handler.
   */
  onCreateStakeholder: (
    email: string,
    notifications: Array<NotificationType>,
  ) => void;

  /**
   * The update event handler.
   */
  onUpdateNotifications: (
    id: number,
    email: string,
    notifications: Array<NotificationType>,
  ) => void;

  /**
   * The cancel event handler.
   */
  onCancel: () => void;
}

type OptionType = { label: string; value: NotificationType };

/**
 * The stakeholder dialog component.
 *
 * @param {StakeholderDialogProps} props The props.
 * @returns {JSX.Element} The component instance.
 */
const StakeholderDialog: FunctionComponent<StakeholderDialogProps> = (
  props: StakeholderDialogProps,
): JSX.Element => 
{
  const { t } = useTranslation();

  const useCase = props.stakeholder !== undefined ? "update" : "create";

  const [email, setEmail] = useState<string>(props.stakeholder?.email ?? "");
  const [selectedNotifications, setSelectedNotifications] = useState<
    Array<NotificationType>
  >(props.stakeholder?.notifications ?? []);

  const handleConfirm = (event: React.FormEvent<HTMLFormElement>) => 
  {
    event.preventDefault();

    if (!email.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/))
    {
      alert("Please enter a valid email address.");
      return;
    }
    if (
      props.alreadyAssignedEmailAddresses
        .map((address) => address.toLowerCase())
        .includes(email.toLowerCase())
    )
    {
      alert("This email address is already assigned.");
      return;
    }
    if (selectedNotifications.length === 0)
    {
      alert("Please select at least one notification.");
      return;
    }

    if (useCase === "update")
    {
      props.onUpdateNotifications(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        props.stakeholder!.id,
        email,
        selectedNotifications,
      );
    }
    else 
    {
      props.onCreateStakeholder(email, selectedNotifications);
    }
  };

  const notificationOptions = NotificationTypes.map<OptionType>((option) => ({
    value: option,
    label: t(`ui.stakeholder.notificationTypes.${option}`),
  }));

  return (
    <Dialog
      open={props.isVisible}
      onClose={props.onCancel}
      PaperProps={{
        component: "form",
        onSubmit: handleConfirm,
      }}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>{t("ui.stakeholder.dialog.header")}</DialogTitle>
      <DialogContent>
        <div className="form-group">
          <label
            style={{ marginTop: "15px" }}
            htmlFor="stakeholder-create-name"
          >
            {t("ui.stakeholder.create.name")}
          </label>
          <TextField
            autoFocus
            type="email"
            variant="standard"
            label="Name"
            placeholder="Name"
            name="stakeholder-create-name"
            id="stakeholder-create-name"
            value={email}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setEmail(e.target.value)
            }
            sx={{ minWidth: "320px" }}
            disabled={useCase === "update"}
          />
        </div>
        <div className="form-group">
          <label htmlFor="stakeholder-create-notifications">
            {t("ui.stakeholder.create.notifications")}
          </label>
          <Select
            isMulti
            options={notificationOptions}
            value={selectedNotifications.map((notification) =>
              notificationOptions.find(
                (option) => option.value === notification,
              ),
            )}
            isSearchable={false}
            isClearable={false}
            placeholder={
              t("ui.stakeholder.create.selectnotification") as string
            }
            onChange={(selectedNotifications) => 
            {
              // note that inlining the function is necessary to avoid terrible typescript issues with a separate handler function
              setSelectedNotifications(
                selectedNotifications
                  .filter((option) => option !== undefined)
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  .map((option) => option!.value),
              );
            }}
            // styles and menuPortalTarget attributes see https://react-select.com/advanced#portaling
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            styles={{ menuPortal: (base: any) => ({ ...base, zIndex: 9999 }) }}
            menuPortalTarget={document.body}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onCancel}>{t("ui.cancel")}</Button>
        <Button type="submit">{t("ui.okay")}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default StakeholderDialog;