/**
 * The region.
 *
 * @property {string} REGION The region.
 */
export const REGION: string = process.env.REACT_APP_REGION as string;

/**
 * The user pool ID.
 *
 * @property {string} USER_POOL_ID The user pool ID.
 */
export const USER_POOL_ID: string = process.env
  .REACT_APP_USER_POOL_ID as string;

/**
 * The user pool client ID.
 *
 * @property {string} USER_POOL_CLIENT_ID The user pool client ID.
 */
export const USER_POOL_CLIENT_ID: string = process.env
  .REACT_APP_USER_POOL_CLIENT_ID as string;

/**
 * The login URL.
 *
 * @property {string} LOGIN_URL The login URL.
 */
export const LOGIN_URL: string = process.env.REACT_APP_LOGIN_URL as string;

/**
 * The account URL.
 *
 * @property {string} ACCOUNT_URL The account URL.
 */
export const ACCOUNT_URL: string = process.env.REACT_APP_ACCOUNT_URL as string;

/**
 * The environment label
 *
 * @property {string} ENVIRONMENT_LABEL The environment label.
 */
export const ENVIRONMENT_LABEL: string = process.env
  .REACT_APP_ENVIRONMENT_LABEL as string;

/**
 * The MuMiver.se Connect GraphQL endpoint.
 *
 * @property {string} CONNECT_GRAPHQL_ENDPOINT The MuMiver.se Connect GraphQL endpoint.
 */
export const CONNECT_GRAPHQL_ENDPOINT: string = process.env
  .REACT_APP_CONNECT_GRAPHQL_ENDPOINT as string;

/**
 * The MuMiver.se ACC Connect GraphQL endpoint.
 *
 * @property {string} ACC_CONNECT_GRAPHQL_ENDPOINT The MuMiver.se ACC Connect GraphQL endpoint.
 */
export const ACC_CONNECT_GRAPHQL_ENDPOINT: string = process.env
  .REACT_APP_ACC_CONNECT_GRAPHQL_ENDPOINT as string;