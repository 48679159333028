import * as _ from "lodash";
import {
  BasePositionModelOptions,
  DeserializeEvent,
} from "@projectstorm/react-canvas-core";
import { CheckType, mapCheckType } from "../../../core/CheckType";
import {
  NodeModel,
  NodeModelGenerics,
  PortModel,
} from "@projectstorm/react-diagrams-core";
import { ClassPortModel } from "./port/ClassPortModel";
import { CustomPortModel } from "./port/CustomPortModel";
import { FilterPortModel } from "./port/FilterPortModel";
import { PropertyCheck } from "../../../core/PropertyCheck";
import { v4 as uuid } from "uuid";

/**
 * The selection event handler.
 *
 * @class
 */
export type SelectionEventHandler = (nodeModel: CustomNodeModel | null) => void;

/**
 * Interface for the custom node model options.
 *
 * @interface
 * @augments {BasePositionModelOptions}
 */
export interface CustomNodeModelOptions extends BasePositionModelOptions
{
  /**
   * The color.
   *
   * @property {string} color The color.
   */
  color: string;

  /**
   * The name.
   *
   * @property {string} name The name.
   */
  name?: string;

  /**
   * The properties.
   *
   * @property {PropertyCheck[]} propertyChecks The properties.
   */
  propertyChecks?: PropertyCheck[];

  /**
   * The filters.
   *
   * @property {PropertyCheck[]} filters The filters.
   */
  filters?: PropertyCheck[];

  /**
   * The selection event handler.
   *
   * @property {SelectionEventHandler} selectionEventHandler The selection event handler.
   */
  selectionEventHandler: SelectionEventHandler;
}

/**
 * Interface for the custom node model generics.
 *
 * @interface
 * @augments {NodeModelGenerics}
 */
export interface CustomNodeModelGenerics extends NodeModelGenerics
{
  /**
   * The options.
   *
   * @property {CustomNodeModelOptions} OPTIONS The options.
   */
  OPTIONS: CustomNodeModelOptions;
}

/**
 * The custom node model.
 *
 * @class
 * @augments {NodeModel<CustomNodeModelGenerics>}
 */
export class CustomNodeModel extends NodeModel<CustomNodeModelGenerics>
{
  /**
   * The validation entity.
   *
   * @property {string} validationEntity The validation entity.
   */
  validationEntity: string;

  /**
   * The properties.
   *
   * @property {PropertyCheck[]} propertyChecks The properties.
   */
  propertyChecks?: PropertyCheck[];

  /**
   * The filters.
   *
   * @property {PropertyCheck[]} filters The filters.
   */
  filters?: PropertyCheck[];

  /**
   * The selection event handler.
   *
   * @property {SelectionEventHandler} selectionEventHandler The selection event handler.
   */
  selectionEventHandler: SelectionEventHandler;

  /**
   * Create an instance of CustomNodeModel.
   *
   * @param {string} name The name.
   * @param {string} color The color.
   * @param {PropertyCheck[]} propertyChecks The properties.
   * @param {PropertyCheck[]} filters The filters.
   * @param {SelectionEventHandler} selectionEventHandler The selection event handler.
   */
  constructor(
    name: string,
    color: string,
    propertyChecks: PropertyCheck[],
    filters: PropertyCheck[],
    selectionEventHandler?: SelectionEventHandler,
  );

  /**
   * Create an instance of CustomNodeModel.
   *
   * @param {CustomNodeModelOptions} options The options.
   */
  constructor(options?: CustomNodeModelOptions);

  /* eslint-disable @typescript-eslint/no-explicit-any */
  /**
   * Create an instance of CustomNodeModel.
   *
   * @param {any} options The options.
   * @param {string} color The color.
   * @param {PropertyCheck[]} propertyChecks The properties.
   * @param {PropertyCheck[]} filters The filters.
   * @param {SelectionEventHandler} selectionEventHandler The selection event handler.
   */
  constructor(
    options: any = {},
    color?: string,
    propertyChecks?: PropertyCheck[],
    filters?: PropertyCheck[],
    selectionEventHandler?: SelectionEventHandler,
  )
  {
    /* eslint-enable @typescript-eslint/no-explicit-any */
    if (typeof options === "string")
    {
      options = {
        name: options,
        color: color ?? "red",
        propertyChecks: propertyChecks ?? [],
        filters: filters ?? [],
        selectionEventHandler: selectionEventHandler,
      };
    }

    const validationEntity = options.name ?? "unnamed";

    super({
      type: "custom-node",
      name: validationEntity,
      color: options.color,
      ...options,
    });

    this.validationEntity = validationEntity;
    this.propertyChecks = options.propertyChecks;
    this.filters = options.filters;
    this.selectionEventHandler = options.selectionEventHandler;

    this.setPropertyChecks(null);
    this.setFilters(null);

    this.addClassPort(
      new ClassPortModel({
        in: true,
        id: uuid(),
        name: validationEntity + "_inherits",
        label: validationEntity + "_inherits",
      }),
    );

    this.addClassPort(
      new ClassPortModel({
        in: false,
        id: uuid(),
        name: validationEntity + "_passesOn",
        label: validationEntity + "_passesOn",
      }),
    );
  }

  /**
   * Add a port.
   *
   * @template {PortModel} T The port type.
   * @param {T} port The port.
   * @returns {T} A port instance.
   */
  addPort<T extends PortModel>(port: T): T
  {
    super.addPort(port);

    return port;
  }

  /**
   * Add a property port.
   *
   * @template {CustomPortModel} T The port type.
   * @param {T} port The property port.
   * @returns {T} A property port instance.
   */
  addPropertyPort<T extends CustomPortModel>(port: T): T
  {
    return this.addPort(port);
  }

  /**
   * Add a class port.
   *
   * @template {ClassPortModel} T The port type.
   * @param {T} port The class port.
   * @returns {T} A class port model instance.
   */
  addClassPort<T extends ClassPortModel>(port: T): T
  {
    return this.addPort(port);
  }

  /**
   * Add a filter port.
   *
   * @template {FilterPortModel} T The port type.
   * @param {T} port The filter port.
   * @returns {T} A filter port model instance.
   */
  addFilterPort<T extends FilterPortModel>(port: T): T
  {
    return this.addPort(port);
  }

  /**
   * Get the property ports.
   *
   * @returns {CustomPortModel[]} All property ports of the current node.
   */
  getPropertyPorts(): CustomPortModel[]
  {
    const ports = this.getPorts();

    const propertyPorts = _.map(ports, (port) => 
    {
      if (port instanceof CustomPortModel)
      {
        return port;
      }
    });

    return propertyPorts.filter(
      (element) => element !== undefined,
    ) as CustomPortModel[];
  }

  /**
   * Get the class ports.
   *
   * @returns {ClassPortModel[]} All class ports of the current node.
   */
  getClassPorts(): ClassPortModel[]
  {
    const ports = this.getPorts();

    const propertyPorts = _.map(ports, (port) => 
    {
      if (port instanceof ClassPortModel)
      {
        return port;
      }
    });

    return propertyPorts.filter(
      (element) => element !== undefined,
    ) as ClassPortModel[];
  }

  /**
   * Get the class ports.
   *
   * @returns {ClassPortModel[]} All class ports of the current node that are inheriting from another node.
   */
  getInClassPorts(): ClassPortModel[]
  {
    const ports = this.getPorts();

    const propertyPorts = _.map(ports, (port) => 
    {
      if (port instanceof ClassPortModel)
      {
        return port;
      }
    });

    return propertyPorts.filter(
      (element) => element?.options.in,
    ) as ClassPortModel[];
  }

  /**
   * Get the class ports.
   *
   * @returns {ClassPortModel[]} All class ports of the current node are deriving to another node.
   */
  getOutClassPorts(): ClassPortModel[]
  {
    const ports = this.getPorts();

    const propertyPorts = _.map(ports, (port) => 
    {
      if (port instanceof ClassPortModel)
      {
        return port;
      }
    });

    return propertyPorts.filter(
      (element) => element !== undefined && !element.options.in,
    ) as ClassPortModel[];
  }

  /**
   * Get the filter ports.
   *
   * @returns {FilterPortModel[]}  All filter ports of the current node.
   */
  getFilterPorts(): FilterPortModel[]
  {
    const ports = this.getPorts();

    const propertyPorts = _.map(ports, (port) => 
    {
      if (port instanceof FilterPortModel)
      {
        return port;
      }
    });

    return propertyPorts.filter(
      (element) => element !== undefined,
    ) as FilterPortModel[];
  }

  /**
   * Set the property checks.
   *
   * @param {PropertyCheck[] | null} propertyChecks The property checks.
   * @returns {void}
   */
  setPropertyChecks(propertyChecks: PropertyCheck[] | null)
  {
    console.debug("[CustomNodeModel] set property checks", {
      parameter: propertyChecks,
      currentState: this.options.propertyChecks,
    });

    // if parameter has a value set the property to the parameter value.
    if (propertyChecks !== null)
    {
      this.options.propertyChecks = propertyChecks;
    }

    // remove all property ports since it should be regenerated later
    _.forEach(this.getPropertyPorts(), (port) => 
    {
      this.removePropertyPort(port);
    });

    // todo: reconnect links

    // regenerate property ports
    if (
      this.options.propertyChecks !== null
      && this.options.propertyChecks !== undefined
    )
    {
      for (const element of this.options.propertyChecks)
      {
        let comparisonValue = element.comparisonValue;
        const checkType = element.checkType;

        if (
          comparisonValue === ""
          && checkType !== CheckType.Empty
          && checkType !== CheckType.NotEmpty
        )
        {
          comparisonValue = "''";
        }

        this.addPropertyPort(
          new CustomPortModel({
            id: element.id,
            name: element.name,
            label:
              element.name + mapCheckType(checkType, true) + comparisonValue,
          }),
        );
      }
    }
  }

  /**
   * Set the filters.
   *
   * @param {PropertyCheck[] | null} filters The filters.
   * @returns {void}
   */
  setFilters(filters: PropertyCheck[] | null)
  {
    console.debug("[CustomNodeModel] set filters", {
      parameter: filters,
      currentState: this.options.propertyChecks,
    });

    // if parameter has a value set the property to the parameter value.
    if (filters !== null)
    {
      this.options.filters = filters;
    }

    // remove all filter ports since it should be regenerated later
    _.forEach(this.getFilterPorts(), (port) => 
    {
      this.removeFilterPort(port);
    });

    // todo: reconnect links

    // regenerate filter ports
    if (this.options.filters !== null && this.options.filters !== undefined)
    {
      for (const element of this.options.filters)
      {
        let comparisonValue = element.comparisonValue;

        if (comparisonValue === "")
        {
          comparisonValue = "''";
        }

        this.addFilterPort(
          new FilterPortModel({
            id: element.id,
            name: element.name,
            label:
              element.name
              + mapCheckType(element.checkType, true)
              + comparisonValue,
          }),
        );
      }
    }
  }

  /**
   * Remove a port.
   *
   * @param {PortModel} port The port.
   * @returns {void}
   */
  removePort(port: PortModel): void
  {
    super.removePort(port);
  }

  /**
   * Remove a property port.
   *
   * @param {CustomPortModel} port The property port.
   * @returns {void}
   */
  removePropertyPort(port: CustomPortModel): void
  {
    super.removePort(port);
  }

  /**
   * Remove a class port.
   *
   * @param {ClassPortModel} port The class port.
   * @returns {void}
   */
  removeClassPort(port: ClassPortModel): void
  {
    super.removePort(port);
  }

  /**
   * Remove a filter port.
   *
   * @param {FilterPortModel} port The filter port.
   * @returns {void}
   */
  removeFilterPort(port: FilterPortModel): void
  {
    super.removePort(port);
  }

  /**
   * Serialize the model.
   *
   * @returns {any} The serialized entity.
   */
  serialize()
  {
    return {
      ...super.serialize(),
      validationEntity: this.validationEntity,
      propertyChecks: this.propertyChecks,
    };
  }

  /**
   * Deserialize the model.
   *
   * @param {DeserializeEvent<this>} event The deserialization event arguments.
   * @returns {void}
   */
  deserialize(event: DeserializeEvent<this>): void
  {
    super.deserialize(event);
    this.validationEntity = event.data.validationEntity;
    this.propertyChecks = event.data.propertyChecks;
  }
}