import { CirclePlus, EditIcon, Trash } from "lucide-react";
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import Box from "@mui/material/Box";
import { Button } from "./ui/button";
import CircularProgress from "@mui/material/CircularProgress";
import QGContext from "../QGContext";
import StakeholderDialog from "../ui/dialog/StakeholderDialog";
import axios from "axios";
import { useGetStakeholders } from "../api/rest/useGetStakeholders";
import { useTranslation } from "react-i18next";
import "./Stakeholder.scss";

/**
 * List of available notification types.
 */
export const NotificationTypes = [
  "validationResult",
  "newUser",
  "newStakeholder",
  "deletedUser",
  "deletedStakeholder",
] as const;

/**
 * The string literal union type corresponding to the list of available notification types.
 */
export type NotificationType = (typeof NotificationTypes)[number];

/**
 * Represents a stakeholder list item.
 *
 * @interface
 */
export interface StakeholderListItem
{
  /**
   * The ID of the stakeholder.
   */
  id: number;
  /**
   * The email adress of the stakeholder.
   */
  email: string;
  /**
   * The selected notification types.
   */
  notifications: Array<NotificationType>;
}

/**
 * The stakeholder container component.
 *
 * @returns {JSX.Element} The component instance.
 */
const Stakeholder: FunctionComponent = (): JSX.Element => 
{
  const { t } = useTranslation();
  const context = useContext(QGContext);

  // TODO remove this as soon as stakeholder mutations are in place
  const [stakeholders, setStakeholders] = useState<Array<StakeholderListItem>>(
    [],
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { data, isLoading: isGetStakeholderHookLoading } = useGetStakeholders(
    context.project.id,
  );

  const [currentlyEditedStakeholderId, setCurrentlyEditedStakeholderId]
    = useState<number | null | undefined>(null); // null => edit/create not active, undefined => create new stakeholder

  useEffect(() => 
  {
    if (!data)
    {
      return;
    }

    setStakeholders(
      Object.keys(data).map<StakeholderListItem>((key, index) => ({
        id: index, // TBD: get ID from backend
        email: key,
        notifications: data[key].split(","),
      })),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleCreateStakeholder = async (
    email: string,
    notifications: Array<NotificationType>,
  ) => 
  {
    setIsLoading(true);
    await axios.post(
      `/qualitygate/v1/project/${context.project.id}/stakeholders`,
      `email=${email}&notifications=${notifications.join(",")}`,
      {
        headers: {
          userToken: context.tokens.idToken,
          accessToken: context.tokens.accessToken,
          "Content-Type": "application/x-www-form-urlencoded", // TODO: use application/json
        },
      },
    );

    setStakeholders((prev) => [
      ...prev,
      {
        id: prev.length,
        email,
        notifications,
      },
    ]);
    setCurrentlyEditedStakeholderId(null);
    setIsLoading(false);
  };

  const handleUpdateNotifications = async (
    id: number,
    email: string,
    notifications: Array<NotificationType>,
  ) => 
  {
    setIsLoading(true);
    await axios.put(
      `/qualitygate/v1/project/${context.project.id}/stakeholders/${id}`, // note that the id is never used in BE!
      `email=${email}&notifications=${notifications.join(",")}`,
      {
        headers: {
          userToken: context.tokens.idToken,
          accessToken: context.tokens.accessToken,
          "Content-Type": "application/x-www-form-urlencoded", // TODO: use application/json
        },
      },
    );

    setStakeholders((prev) => 
    {
      const newStakeholders = [...prev];
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const updatedStakeholder = newStakeholders.find((s) => s.id === id)!;
      updatedStakeholder.email = email;
      updatedStakeholder.notifications = notifications;
      return newStakeholders;
    });
    setCurrentlyEditedStakeholderId(null);
    setIsLoading(false);
  };

  const handleDeleteStakeholder = async (email: string) => 
  {
    if (window.confirm(t("ui.areyousure") as string) === false)
    {
      return;
    }
    setIsLoading(true);
    await axios.delete(
      `/qualitygate/v1/project/${
        context.project.id
      }/stakeholders/${encodeURIComponent(email)}`,
      {
        headers: {
          userToken: context.tokens.idToken,
          accessToken: context.tokens.accessToken,
        },
      },
    );

    setStakeholders((prev) => prev.filter((s) => s.email !== email));
    setIsLoading(false);
  };

  if (isLoading || isGetStakeholderHookLoading)
  {
    return (
      <div>
        <Box>
          <CircularProgress className="spinner" />
        </Box>
      </div>
    );
  }

  return (
    <>
      <div className="content-container">
        <div>
          <h1 id="title">{t("ui.stakeholder.heading")}</h1>
          <div id="description">{t("ui.stakeholder.description")}</div>
          <div className="content">
            <div className="action-bar">
              <Button
                onClick={() => setCurrentlyEditedStakeholderId(undefined)}
              >
                <CirclePlus className="mr-2 h-4 w-4" />
                {t("ui.stakeholder.addstakeholder")}
              </Button>
            </div>
            <table>
              <thead>
                <tr>
                  <th>{t("ui.stakeholder.name")}</th>
                  <th>{t("ui.stakeholder.notifications")}</th>
                  <th>{t("ui.stakeholder.actions")}</th>
                </tr>
              </thead>
              <tbody>
                {stakeholders.map((stakeholder) => (
                  <tr key={stakeholder.email}>
                    <td>{stakeholder.email}</td>
                    <td>
                      {stakeholder.notifications.map((notification) => (
                        <span key={notification} className="notification-key">
                          {t(
                            `ui.stakeholder.notificationTypes.${notification}`,
                          )}
                        </span>
                      ))}
                    </td>
                    <td className="user-actions">
                      <Button
                        className="rounded-full bg-transparent hover:bg-transparent"
                        onClick={() =>
                          handleDeleteStakeholder(stakeholder.email)
                        }
                      >
                        <Trash className="text-white" />
                      </Button>
                      <Button
                        className="rounded-full bg-transparent hover:bg-transparent"
                        onClick={() =>
                          setCurrentlyEditedStakeholderId(stakeholder.id)
                        }
                      >
                        <EditIcon className="text-white" />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {currentlyEditedStakeholderId !== null && (
        <StakeholderDialog
          isVisible={currentlyEditedStakeholderId !== null}
          stakeholder={stakeholders.find(
            (sh) => sh.id === currentlyEditedStakeholderId,
          )}
          onCreateStakeholder={handleCreateStakeholder}
          onUpdateNotifications={handleUpdateNotifications}
          onCancel={() => setCurrentlyEditedStakeholderId(null)}
          alreadyAssignedEmailAddresses={stakeholders
            .filter((sh) => sh.id !== currentlyEditedStakeholderId)
            .map((sh) => sh.email)}
        />
      )}
    </>
  );
};

export default Stakeholder;