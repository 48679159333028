import * as dialog from "../ui/dialog/Dialog";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import QGContext, { AppContext } from "../QGContext";
import { WithTranslation, withTranslation } from "react-i18next";
import { AccessType } from "../interfaces/AccessType";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IPermission } from "../interfaces/IPermission";
import { IRole } from "../interfaces/IRole";
import { Permission } from "../core/Permission";
import React from "react";
import { Role } from "../core/Role";
import { UserWithLevel } from "../core/UserWithLevel";
import { WithRouter } from "../interfaces/WithRouter";
import axios from "axios";
import createDOMPurify from "dompurify";
import folder from "./Images/folder_setting.svg";
import { formatDate } from "../support/DateTime";
import { v4 as uuid } from "uuid";
import { withRouter } from "../support/withRouter";
import "./ProjectRoles.scss";

/**
 * Interface for the project roles arguments.
 *
 * @interface
 */
interface ProjectRolesArguments extends WithTranslation, WithRouter
{}

/**
 * Interface for the project roles state.
 *
 * @interface
 */
interface ProjectRolesState
{
  /**
   * A value indicating whether the component is loading or not.
   *
   * @property {boolean} loading A value indicating whether the component is loading or not.
   */
  loading: boolean;

  /**
   * The role list.
   *
   * @property {IRole[] | null | undefined} roleList The role list.
   */
  roleList: IRole[] | null | undefined;

  /**
   * A value indicating whether the creation dialog is visible or not.
   *
   * @property {boolean} showCreateDialog A value indicating whether the creation dialog is visible or not.
   */
  showCreateDialog: boolean;

  /**
   * A value indicating whether the permission dialog is visible or not.
   *
   * @property {boolean} showPermissionDialog A value indicating whether the permission dialog is visible or not.
   */
  showPermissionDialog: boolean;

  /**
   * The permission list.
   *
   * @property {IPermission[] | null | undefined} permissionList The permission list.
   */
  permissionList: IPermission[] | null | undefined;

  /**
   * The selected role ID.
   *
   * @property {string | null} selectedRoleId The selected role ID.
   */
  selectedRoleId?: string | null;

  /**
   * The current user maximum role level.
   *
   * @property {number} userMaxLevel The current user maximum role level.
   */
  userMaxLevel: number;

  /**
   * The role level that was selected from the dropdown.
   *
   * @property {string} selectedRoleLevel The role level that was selected from the dropdown.
   */
  selectedRoleLevel: string | undefined;
}

/**
 * The Project Roles component.
 *
 * @class
 * @augments {React.Component<ProjectRolesArguments, ProjectRolesState>}
 */
class ProjectRoles extends React.Component<
  ProjectRolesArguments,
  ProjectRolesState
>
{
  static contextType = QGContext;

  /**
   * Create an instance of ProjectRoles.
   *
   * @param {ProjectRolesArguments} props The properties.
   */
  constructor(props: ProjectRolesArguments)
  {
    super(props);

    this.state = {
      loading: false,
      roleList: [],
      showCreateDialog: false,
      showPermissionDialog: false,
      permissionList: null,
      selectedRoleId: null,
      userMaxLevel: 100,
      selectedRoleLevel: undefined,
    };

    this.loadRoleTable = this.loadRoleTable.bind(this);
    this.showCreateDialog = this.showCreateDialog.bind(this);
    this.hideDialog = this.hideDialog.bind(this);
    this.confirmDialog = this.confirmDialog.bind(this);
    this.showPermissions = this.showPermissions.bind(this);
  }

  /**
   * Sets up the component after it has been mounted.
   *
   * @returns {void}
   */
  /**
   * Get the user settings.
   *
   * @returns {void}
   */
  getUserSettings()
  {
    this.setState({
      loading: true,
    });

    const ctx = this.context as AppContext;

    axios
      .get(`/qualitygate/v1/project/${ctx.project.id}/members/current`, {
        headers: {
          userToken: ctx.tokens.idToken,
          accessToken: ctx.tokens.accessToken,
        },
      })
      .then((response) => 
      {
        this.setState({
          userMaxLevel: (response.data as UserWithLevel).level,
        });
      });
  }

  /**
   * Load the role table.
   *
   * @returns {void}
   */
  loadRoleTable()
  {
    this.setState({
      loading: true,
    });

    const ctx = this.context as AppContext;

    axios
      .get(`/qualitygate/v1/project/${ctx.project.id}/roles`, {
        headers: {
          userToken: ctx.tokens.idToken,
          accessToken: ctx.tokens.accessToken,
        },
      })
      .then((response) => 
      {
        this.setState({
          roleList: response.data as IRole[],
          loading: false,
        });
      });
  }

  /**
   * Show the create dialog.
   *
   * @param {React.MouseEvent<HTMLButtonElement>} event The click event.
   * @returns {void}
   */
  showCreateDialog(event: React.MouseEvent<HTMLButtonElement>)
  {
    console.debug("[ProjectRoles] show create dialog", {
      event: event,
    });

    this.setState({
      showCreateDialog: true,
    });
  }

  /**
   * Hide the create dialog.
   *
   * @returns {void}
   */
  hideDialog()
  {
    console.debug("[ProjectRoles] hide dialog");

    this.setState({
      showCreateDialog: false,
      showPermissionDialog: false,
      selectedRoleLevel: undefined,
    });
  }

  /**
   * Confirm the create dialog.
   *
   * @returns {void}
   */
  confirmDialog()
  {
    const ctx = this.context as AppContext;

    if (this.state.showCreateDialog)
    {
      const name = (
        document.getElementById("role-create-name") as HTMLInputElement
      ).value;

      if (!name || this.state.selectedRoleLevel === undefined)
      {
        alert("Please enter name and level.");
        return;
      }

      axios
        .post(
          `/qualitygate/v1/project/${ctx.project.id}/roles`,
          new Role({
            id: uuid(),
            name,
            expiration: new Date(
              new Date().getFullYear() + 20,
              new Date().getMonth(),
              new Date().getDate(),
            ),
            created: new Date(),
            permissions: [],
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            level: parseInt(this.state.selectedRoleLevel!),
          }),
          {
            headers: {
              userToken: ctx.tokens.idToken,
              accessToken: ctx.tokens.accessToken,
            },
          },
        )
        .then((response) => 
        {
          console.debug("[ProjectRoles] create response", {
            response: response,
          });

          this.setState({
            selectedRoleLevel: undefined,
          });

          this.loadRoleTable();
        });
    }
    else if (this.state.showPermissionDialog)
    {
      if (this.state.selectedRoleId)
      {
        if (this.state.selectedRoleId)
        {
          const permissions: Permission[] = [];

          if (
            (
              document.getElementById(
                "role-permissions-project-view",
              ) as HTMLInputElement
            ).checked
          )
          {
            permissions.push(
              new Permission({
                accessType: AccessType.View,
                resourceName: "project",
              }),
            );
          }

          if (
            (
              document.getElementById(
                "role-permissions-project-edit",
              ) as HTMLInputElement
            ).checked
          )
          {
            permissions.push(
              new Permission({
                accessType: AccessType.Edit,
                resourceName: "project",
              }),
            );
          }

          if (
            (
              document.getElementById(
                "role-permissions-project-create",
              ) as HTMLInputElement
            ).checked
          )
          {
            permissions.push(
              new Permission({
                accessType: AccessType.Create,
                resourceName: "project",
              }),
            );
          }

          if (
            (
              document.getElementById(
                "role-permissions-project-delete",
              ) as HTMLInputElement
            ).checked
          )
          {
            permissions.push(
              new Permission({
                accessType: AccessType.Delete,
                resourceName: "project",
              }),
            );
          }

          if (
            (
              document.getElementById(
                "role-permissions-role-view",
              ) as HTMLInputElement
            ).checked
          )
          {
            permissions.push(
              new Permission({
                accessType: AccessType.View,
                resourceName: "role",
              }),
            );
          }

          if (
            (
              document.getElementById(
                "role-permissions-role-edit",
              ) as HTMLInputElement
            ).checked
          )
          {
            permissions.push(
              new Permission({
                accessType: AccessType.Edit,
                resourceName: "role",
              }),
            );
          }

          if (
            (
              document.getElementById(
                "role-permissions-role-create",
              ) as HTMLInputElement
            ).checked
          )
          {
            permissions.push(
              new Permission({
                accessType: AccessType.Create,
                resourceName: "role",
              }),
            );
          }

          if (
            (
              document.getElementById(
                "role-permissions-role-delete",
              ) as HTMLInputElement
            ).checked
          )
          {
            permissions.push(
              new Permission({
                accessType: AccessType.Delete,
                resourceName: "role",
              }),
            );
          }

          axios
            .put(
              `/qualitygate/v1/project/${ctx.project.id}/roles/${this.state.selectedRoleId}/permissions`,
              permissions,
              {
                headers: {
                  userToken: ctx.tokens.idToken,
                  accessToken: ctx.tokens.accessToken,
                },
              },
            )
            .then((response) => 
            {
              console.debug("[ProjectRoles] create response", {
                response: response,
              });

              this.loadRoleTable();
            });
        }
      }

      this.setState({
        showPermissionDialog: false,
      });
    }
  }

  /**
   * Show the permission dialog.
   *
   * @param {React.MouseEvent<HTMLButtonElement>} event The click event.
   * @returns {void}
   */
  showPermissions(event: React.MouseEvent<HTMLButtonElement>)
  {
    console.debug("[ProjectRoles] show permission dialog", {
      event: event,
    });

    this.setState({
      showPermissionDialog: true,
    });

    let eventTarget: HTMLElement = event.target as HTMLElement;

    while (eventTarget.tagName.toLowerCase() !== "tr")
    {
      if (eventTarget.tagName.toLowerCase() === "body")
      {
        return;
      }

      eventTarget = eventTarget.parentElement as HTMLElement;
    }

    const ctx = this.context as AppContext;

    axios
      .get(
        `/qualitygate/v1/project/${
          ctx.project.id
        }/roles/${eventTarget.getAttribute("data-id")}/permissions`,
        {
          headers: {
            userToken: ctx.tokens.idToken,
            accessToken: ctx.tokens.accessToken,
          },
        },
      )
      .then((response) => 
      {
        console.debug("[ProjectRoles] set permission list", {
          response: response,
        });

        this.setState({
          selectedRoleId: eventTarget.getAttribute("data-id"),
          permissionList: response.data ? response.data : [],
        });
      });
  }

  /*
   * Generate the permission entry fragments
   *
   * @param {IPermission[]} permissions The permissions.
   * @returns {JSX.Element[]}
   */
  generatePermissionEntryFragments(permissions: IPermission[]): JSX.Element[]
  {
    const result: JSX.Element[] = [];

    permissions.forEach((permission) => 
    {
      result.push(
        <span className="permission-entry">
          {permission.accessType}:{permission.resourceName}
        </span>,
      );
    });

    return result;
  }

  /**
   * The event handler for permission check box changes.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} event The change event.
   * @param {string} elementId The element ID.
   */
  changePermissionCheckBox(
    event: React.ChangeEvent<HTMLInputElement>,
    elementId: string,
  )
  {
    console.debug("[ProjectRoles] change permission check box", {
      event: event,
      elementId: elementId,
    });

    const accessType = elementId.split("-")[3].toLowerCase();
    const resourceName = elementId.split("-")[2].toLowerCase();

    const permissionList: IPermission[] = this.state.permissionList ?? [];

    const permissionIndex = this.state.permissionList?.findIndex(
      (permission: IPermission) => 
      {
        return (
          permission.resourceName.toLowerCase() === resourceName
          && permission.accessType.toLowerCase() === accessType
        );
      },
    );

    console.debug("[ProjectRoles] change permission check box", {
      accessType: accessType,
      resourceName: resourceName,
      permissionIndex: permissionIndex,
      targetChecked: event.target.checked,
    });

    if (permissionIndex && permissionIndex !== -1 && !event.target.checked)
    {
      permissionList.splice(permissionIndex, 1);
    }
    else if (
      (!permissionIndex || permissionIndex === -1)
      && event.target.checked
    )
    {
      const accessTypeEnumValue
        = accessType.charAt(0).toUpperCase() + accessType.slice(1);

      permissionList.push({
        accessType: accessTypeEnumValue as AccessType,
        resourceName: resourceName,
      });
    }

    this.setState({
      permissionList: permissionList,
    });
  }

  /**
   * Render the component.
   *
   * @returns {JSX.Element} The component instance.
   */
  render()
  {
    console.debug("[ProjectRoles] render", {
      state: this.state,
      props: this.props,
    });

    const { t } = this.props;

    const DOMPurify = createDOMPurify(window);

    const description = t("ui.projectroles.description");

    const roleElements: JSX.Element[] = [];

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.state.roleList!.forEach((role) => 
    {
      return roleElements.push(
        <tr key={role.id} data-id={role.id}>
          <td>{role.name}</td>
          <td>{formatDate(role.created)}</td>
          <td>{this.generatePermissionEntryFragments(role.permissions)}</td>
          <td>
            <Button variant="outlined" onClick={this.showPermissions}>
              <FontAwesomeIcon icon="user-shield" />
            </Button>
          </td>
        </tr>,
      );
    });

    if (this.state.loading)
    {
      return (
        <div>
          <Box>
            <CircularProgress className="spinner" />
          </Box>
        </div>
      );
    }

    return (
      <React.Fragment>
        <div className="post">
          <h1 id="title">{t("ui.projectroles.heading")}</h1>
          <div
            id="description"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(description),
            }}
          />
          <div className="content">
            <table>
              <thead>
                <tr>
                  <th>{t("ui.projectroles.name")}</th>
                  <th>{t("ui.projectroles.created")}</th>
                  <th>{t("ui.projectroles.permissions")}</th>
                  <th>{t("ui.projectroles.actions")}</th>
                </tr>
              </thead>
              <tbody>{roleElements}</tbody>
            </table>
            <div className="outer-box">
              <div className="inner-box">
                <img src={folder} alt="icon" className="img-icon " />
                <p className="text">No Roles yet</p>
                <p className="description">
                  Vorbereitet für spätere Version
                  <br />
                  Momentan noch nicht implemertiert
                </p>
              </div>
            </div>
          </div>
        </div>

        <dialog.default
          header={
            this.state.showCreateDialog
              ? t("ui.projectroles.dialog.create.header")
              : t("ui.projectroles.dialog.permissions.header")
          }
          visible={
            this.state.showCreateDialog || this.state.showPermissionDialog
          }
          onHide={() => this.hideDialog()}
          onConfirm={() => this.confirmDialog()}
        >
          {this.state.showCreateDialog && (
            <div id="role-create-form">
              <div className="form-group">
                <label htmlFor="role-create-name" style={{ marginTop: "15px" }}>
                  {t("ui.projectroles.dialog.create.name")}
                </label>
                <TextField
                  variant="standard"
                  label="Name"
                  placeholder="Name"
                  name="role-create-name"
                  id="role-create-name"
                  sx={{ minWidth: "320px" }}
                />
              </div>
              <div className="form-group">
                <label htmlFor="role-create-name" style={{ marginTop: "15px" }}>
                  {t("ui.projectroles.dialog.create.level")}
                </label>
                <FormControl fullWidth>
                  <InputLabel id="role-create-level-label">
                    {t("ui.projectroles.dialog.create.level")}
                  </InputLabel>
                  <Select
                    sx={{ maxWidth: "320px" }}
                    labelId="role-create-level-label"
                    id="role-create-level"
                    label={t("ui.projectroles.dialog.create.level")}
                    value={this.state.selectedRoleLevel}
                    onChange={(event: SelectChangeEvent) => 
                    {
                      this.setState({
                        selectedRoleLevel: event.target.value,
                      });
                    }}
                  >
                    {this.state.userMaxLevel < 2 && (
                      <MenuItem value={2}>{t("ui.roles.member")}</MenuItem>
                    )}
                    {this.state.userMaxLevel < 1 && (
                      <MenuItem value={1}>
                        {t("ui.roles.administrator")}
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </div>
            </div>
          )}
          {this.state.showPermissionDialog && (
            <div id="role-permission-form">
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th>{t("ui.permission.view")}</th>
                    <th>{t("ui.permission.edit")}</th>
                    <th>{t("ui.permission.create")}</th>
                    <th>{t("ui.permission.delete")}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{t("ui.permission.project")}</td>
                    <td>
                      <input
                        type="checkbox"
                        name="role-permissions-project-view"
                        id="role-permissions-project-view"
                        onChange={(e) =>
                          this.changePermissionCheckBox(
                            e,
                            "role-permissions-project-view",
                          )
                        }
                        checked={
                          this.state.permissionList?.find((permission) => 
                          {
                            return (
                              permission.resourceName === "project"
                              && permission.accessType === AccessType.View
                            );
                          })
                            ? true
                            : false
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        name="role-permissions-project-edit"
                        id="role-permissions-project-edit"
                        onChange={(e) =>
                          this.changePermissionCheckBox(
                            e,
                            "role-permissions-project-edit",
                          )
                        }
                        checked={
                          this.state.permissionList?.find((permission) => 
                          {
                            return (
                              permission.resourceName === "project"
                              && permission.accessType === AccessType.Edit
                            );
                          })
                            ? true
                            : false
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        name="role-permissions-project-create"
                        id="role-permissions-project-create"
                        onChange={(e) =>
                          this.changePermissionCheckBox(
                            e,
                            "role-permissions-project-create",
                          )
                        }
                        checked={
                          this.state.permissionList?.find((permission) => 
                          {
                            return (
                              permission.resourceName === "project"
                              && permission.accessType === AccessType.Create
                            );
                          })
                            ? true
                            : false
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        name="role-permissions-project-delete"
                        id="role-permissions-project-delete"
                        onChange={(e) =>
                          this.changePermissionCheckBox(
                            e,
                            "role-permissions-project-delete",
                          )
                        }
                        checked={
                          this.state.permissionList?.find((permission) => 
                          {
                            return (
                              permission.resourceName === "project"
                              && permission.accessType === AccessType.Delete
                            );
                          })
                            ? true
                            : false
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>{t("ui.permission.role")}</td>
                    <td>
                      <input
                        type="checkbox"
                        name="role-permissions-role-view"
                        id="role-permissions-role-view"
                        onChange={(e) =>
                          this.changePermissionCheckBox(
                            e,
                            "role-permissions-role-view",
                          )
                        }
                        checked={
                          this.state.permissionList?.find((permission) => 
                          {
                            return (
                              permission.resourceName === "role"
                              && permission.accessType === AccessType.View
                            );
                          })
                            ? true
                            : false
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        name="role-permissions-role-edit"
                        id="role-permissions-role-edit"
                        onChange={(e) =>
                          this.changePermissionCheckBox(
                            e,
                            "role-permissions-role-edit",
                          )
                        }
                        checked={
                          this.state.permissionList?.find((permission) => 
                          {
                            return (
                              permission.resourceName === "role"
                              && permission.accessType === AccessType.Edit
                            );
                          })
                            ? true
                            : false
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        name="role-permissions-role-create"
                        id="role-permissions-role-create"
                        onChange={(e) =>
                          this.changePermissionCheckBox(
                            e,
                            "role-permissions-role-create",
                          )
                        }
                        checked={
                          this.state.permissionList?.find((permission) => 
                          {
                            return (
                              permission.resourceName === "role"
                              && permission.accessType === AccessType.Create
                            );
                          })
                            ? true
                            : false
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        name="role-permissions-role-delete"
                        id="role-permissions-role-delete"
                        onChange={(e) =>
                          this.changePermissionCheckBox(
                            e,
                            "role-permissions-role-delete",
                          )
                        }
                        checked={
                          this.state.permissionList?.find((permission) => 
                          {
                            return (
                              permission.resourceName === "role"
                              && permission.accessType === AccessType.Delete
                            );
                          })
                            ? true
                            : false
                        }
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </dialog.default>
      </React.Fragment>
    );
  }
}

export default withTranslation()(withRouter(ProjectRoles));