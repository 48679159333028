import React, { FunctionComponent, useEffect, useState } from "react";
import createEngine, {
  DiagramEngine,
  DiagramModel,
} from "@projectstorm/react-diagrams";
import { CanvasWidget } from "@projectstorm/react-canvas-core";
import { CheckType } from "../core/CheckType";
import { CustomNodeFactory } from "../ui/diagram/element/CustomNodeFactory";
import { CustomNodeModel } from "../ui/diagram/element/CustomNodeModel";
import "./ValidationSetDiagram.scss";

interface ValidationSetDiagramProps
{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  definition: any;
}

// if we find a way to calculate it too please replace
const nodeWidth = 380;

const mapToCheckType = (checkTypeValue: string): CheckType => 
{
  switch (checkTypeValue.toLowerCase())
  {
  case "in":
    return CheckType.In;
  case "notin":
    return CheckType.NotIn;
  case "empty":
    return CheckType.Empty;
  case "notempty":
    return CheckType.NotEmpty;
  case "lt":
    return CheckType.Lt;
  case "lte":
    return CheckType.Lte;
  case "gt":
    return CheckType.Gt;
  case "gte":
    return CheckType.Gte;
  case "neq":
    return CheckType.NEq;
  case "eq":
  default:
    return CheckType.Eq;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapItem = (item: any) => ({
  id: item.id,
  name: item.name,
  checkType: mapToCheckType(item.checkType),
  comparisonValue:
    item.value.class === "Simple"
      ? item.value.value
      : item.value.collection.join(","),
});

/**
 * The validation set diagram.
 *
 * @param {ValidationSetDiagramProps} props The properties.
 * @returns {JSX.Element} The validation set diagram.
 */
const ValidationSetDiagram: FunctionComponent<ValidationSetDiagramProps> = (
  props: ValidationSetDiagramProps,
): JSX.Element => 
{
  const [engine, setEngine] = useState<DiagramEngine | undefined>();

  useEffect(() => 
  {
    // we do all of this in a useEffect hook to avoid "canvas is null" error when navigating to other validation sets in the sidebar.
    // see https://github.com/projectstorm/react-diagrams/issues/671#issuecomment-735589917
    const model = new DiagramModel();
    model.setLocked(true);

    props.definition.validations.forEach(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (validationItem: any, index: number) => 
      {
        const node = new CustomNodeModel({
          name: validationItem.name,
          color: `rgb(${Math.floor(Math.random() * 255)}, ${Math.floor(
            Math.random() * 255,
          )}, ${Math.floor(Math.random() * 255)})`, // [WS] really?
          propertyChecks: [],
          filters: [],
          selectionEventHandler: () => 
          {
            console.log("[ValidationSet] node selected");
          },
        });

        const filters = validationItem.filters.map(mapItem);
        node.setFilters(filters);

        let propertyChecks = [];
        if (validationItem.checks.class === "Checks")
        {
          propertyChecks = validationItem.checks.checks.map(mapItem); // [WS] backend data structure "checks.checks" => code smell...
          node.setPropertyChecks(propertyChecks);
        }

        const remCalcSize = parseFloat(
          getComputedStyle(document.documentElement).fontSize,
        );

        node.width = nodeWidth;

        node.height
          = 2 * (0.75 * remCalcSize) // in and out class port
          + 1.7 * remCalcSize
          + 2 * 5 // add node label height (line-height of 1.7rem and padding of 5)
          + 1.7 * remCalcSize * filters.length // add filter check height
          + 1.7 * remCalcSize * propertyChecks.length; // add property check height;;

        model.addNode(node);
      },
    );

    let currentRowIndex = -1;
    let y = 0;
    const nodes = model.getNodes();
    nodes.forEach((node, nodeIndex) => 
    {
      const columnIndex = nodeIndex % 3;

      // for each first node in a row, increase y by the maximum height of the previous row
      if (columnIndex === 0)
      {
        currentRowIndex++;
        const heightsInPreviousRow = nodes
          .filter((_, i) => Math.floor(i / 3) === currentRowIndex - 1) // get all nodes in the current row
          .map((n) => n.height);
        const maxHeightInPreviousRow = Math.max(...heightsInPreviousRow, 0);
        y += maxHeightInPreviousRow + 40;
      }

      const x = 20 + columnIndex * nodeWidth;
      node.setPosition(x, y);
    });

    const eng = createEngine({
      registerDefaultDeleteItemsAction: false,
      registerDefaultZoomCanvasAction: true,
      registerDefaultPanAndZoomCanvasAction: true,
    });
    eng.getNodeFactories().registerFactory(new CustomNodeFactory());
    eng.setModel(model);

    setEngine(eng);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="diagram-container">
      {engine !== undefined && <CanvasWidget engine={engine} />}
    </div>
  );
};

export default ValidationSetDiagram;