import backend from "i18next-http-backend";
import { defaultNS } from "./i18n-namespace";
import detector from "i18next-browser-languagedetector";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
  .use(detector)
  .use(backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    backend: {
      loadPath: "/qualitygate/v1/resources?languageKey={{lng}}",
      addPath: "/qualitygate/v1/reportMissing?languageKey={{lng}}",
    },
    cleanCode: true,
    debug: true,
    defaultNS: defaultNS,
    fallbackLng: "en-GB", // use en-GB if detected lng is not available
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    load: "currentOnly",
    nonExplicitSupportedLngs: true,
    react: {
      useSuspense: true,
    },
    supportedLngs: ["en-GB", "en", "de-DE", "de"],
  });

export default i18n;