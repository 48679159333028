import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { SnackbarProvider, enqueueSnackbar } from "notistack";
import {
  faArrowDown,
  faArrowDownUpAcrossLine,
  faArrowLeftLong,
  faArrowRightFromBracket,
  faArrowUp,
  faBan,
  faBars,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronUp,
  faClipboardCheck,
  faDoorOpen,
  faFilter,
  faFloppyDisk,
  faGear,
  faHouse,
  faImage,
  faMagnifyingGlass,
  faMinusCircle,
  faMoon,
  faPalette,
  faPen,
  faPenRuler,
  faPlus,
  faPlusCircle,
  faRightFromBracket,
  faSliders,
  faTrash,
  faUserShield,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import {
  faCircleQuestion,
  faFileExcel,
  faFileLines,
  faLightbulb,
  faMoon as faMoonRegular,
  faSun,
} from "@fortawesome/free-regular-svg-icons";
import App from "./App";
import React from "react";
import ReactDOM from "react-dom/client";
import SessionExpired from "./components/SessionExpired";
import i18next from "i18next";
import { library } from "@fortawesome/fontawesome-svg-core";
import { processAuthentication } from "./support/authentication";
import "./index.scss";
import "./styles.css";

/* add icons to the library */
library.add(
  faArrowDown,
  faArrowDownUpAcrossLine,
  faArrowLeftLong,
  faArrowRightFromBracket,
  faArrowUp,
  faBan,
  faBars,
  faChevronDown,
  faCircleQuestion,
  faCheck,
  faChevronLeft,
  faChevronUp,
  faClipboardCheck,
  faDoorOpen,
  faFileExcel,
  faFileLines,
  faFilter,
  faGear,
  faHouse,
  faImage,
  faFloppyDisk,
  faLightbulb,
  faMagnifyingGlass,
  faMinusCircle,
  faMoon,
  faMoonRegular,
  faPalette,
  faPen,
  faPenRuler,
  faPlus,
  faPlusCircle,
  faRightFromBracket,
  faSliders,
  faSun,
  faTrash,
  faUserShield,
  faXmark,
);

processAuthentication().then((authData) => 
{
  if (authData)
  {
    const queryClient = new QueryClient({
      defaultOptions: {
        queries: {
          retry: false,
        },
      },
      queryCache: new QueryCache({
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onError: (error: any, query) => 
        {
          if (query.meta?.ignore404 && error.response.status === 404)
          {
            return;
          }

          if (error.response.status === 401)
          {
            enqueueSnackbar(<SessionExpired />, {
              variant: "warning",
              persist: true,
              preventDuplicate: true,
              key: "session-expired",
            });
            return;
          }

          enqueueSnackbar(error.message ?? i18next.t("ui.error"), {
            variant: "error",
          });
        },
      }),
      mutationCache: new MutationCache({
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onError: (error: any) => 
        {
          if (error.response.status === 401)
          {
            enqueueSnackbar(<SessionExpired />, {
              variant: "warning",
              persist: true,
              preventDuplicate: true,
              key: "session-expired",
            });
            return;
          }

          enqueueSnackbar(
            error?.response?.data?.resultDescription ?? i18next.t("ui.error"),
            {
              variant: "error",
            },
          );
        },
      }),
    });

    ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
      <React.StrictMode>
        <Router>
          <Routes>
            <Route
              path="/*"
              element={
                <QueryClientProvider client={queryClient}>
                  <SnackbarProvider
                    autoHideDuration={5000}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                  >
                    <App tokens={authData.tokens} claims={authData.claims} />
                  </SnackbarProvider>
                </QueryClientProvider>
              }
            />
          </Routes>
        </Router>
      </React.StrictMode>,
    );
  }
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();