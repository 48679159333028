import * as React from "react";

/**
 * The on drop callback type.
 *
 * @param {string} key The key.
 * @returns {void}
 */
type OnDropCallback = (key: string) => void;

/**
 * A method to disable native drag over events.
 *
 * @param {React.DragEvent<HTMLElement>} event The drag event.
 * @returns {void}
 */
const disableNativeDragOverEvent: React.DragEventHandler<HTMLElement> = (
  event,
): void => 
{
  event.preventDefault();
};

/**
 * A method to enable droppable components.
 *
 * @param {OnDropCallback} onDropCallback The drop callback function.
 * @returns {Partial<React.HTMLAttributes<HTMLElement>>} The HTML element attributes that need to be set to enable a droppable component.
 */
export const createDropAbility = (
  onDropCallback: OnDropCallback,
): Partial<React.HTMLAttributes<HTMLElement>> => 
{
  /**
   * The drop event handler.
   *
   * @param {{ dataTransfer: DataTransfer }} The data transfer.
   * @returns {void}
   */
  const onDrop: React.DragEventHandler<HTMLElement> = ({
    dataTransfer,
  }): void => 
  {
    const dragAndDropData = dataTransfer?.getData("dragAndDropData");

    if (dragAndDropData)
    {
      onDropCallback(dragAndDropData);
    }
  };

  return { onDragOver: disableNativeDragOverEvent, onDrop };
};

/**
 * A method to enable draggable components.
 *
 * @param {string} dragAndDropData The drag and drop data.
 * @returns {Partial<React.HTMLAttributes<HTMLElement>>} The HTML element attributes that need to be set to enable a draggable component.
 */
export const createDraggableAbility = (
  dragAndDropData: string,
): Partial<React.HTMLAttributes<HTMLElement>> => ({
  /**
   * A value indicating whether the element is draggable or not.
   */
  draggable: true,
  /**
   * The drag start event handler.
   *
   * @param {{ dataTransfer: DataTransfer }} The data transfer.
   */
  onDragStart: ({ dataTransfer }) => 
  {
    dataTransfer?.setData("dragAndDropData", dragAndDropData);
  },
});