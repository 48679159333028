/* eslint-disable @typescript-eslint/no-explicit-any */
import * as axios from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import QGContext from "../../QGContext";
import { useContext } from "react";

/**
 * Posts a new project to the Quality Gate API
 *
 * @returns {Promise<any>} The project from the Quality Gate API
 */
export const usePostProject = () => 
{
  const ctx = useContext(QGContext);
  const queryClient = useQueryClient();

  return useMutation({
    // TODO typing
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    mutationFn: async (args: any) => 
    {
      const response = await axios.default.post(
        "/qualitygate/v1/project",
        {
          name: args.name,
          address: {
            street: args.street,
            houseNumber: args.houseNumber,
            zip: args.zip,
            city: args.city,
          },
          description: args.description,
          projectNumber: "",
        },
        {
          headers: {
            userToken: ctx.tokens.idToken,
            accessToken: ctx.tokens.accessToken,
            "Content-Type": "application/json",
          },
        },
      );

      return response.data;
    },
    // note that returning the promise keeps the loading state activated)
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ["getProjects"],
      }),
  });
};