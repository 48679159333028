/* eslint-disable @typescript-eslint/no-explicit-any */
import * as axios from "axios";
import QGContext from "../../QGContext";
import { useContext } from "react";
import { useQuery } from "@tanstack/react-query";

/**
 * Gets the models from the Quality Gate API
 *
 * @param {string | null | undefined} email The email
 * @returns {Promise<any>} The project from the Quality Gate API
 */
export const useGetUserByEmailAddress = (email: string) => 
{
  const ctx = useContext(QGContext);

  // TODO typing
  const response = useQuery({
    queryKey: ["getUserByEmailAddress", email],
    queryFn: async () => 
    {
      const response = await axios.default.get(
        `/qualitygate/v1/user?emailAddress=${email}`,
        {
          headers: {
            userToken: ctx.tokens.idToken,
            accessToken: ctx.tokens.accessToken,
          },
        },
      );

      return response.data;
    },
    enabled: !!email,
    meta: {
      ignore404: true,
    },
  });

  return response;
};