import { AccUser, Hub, Project } from "../../components/SignIn.types";
import {
  ApolloClient,
  ApolloQueryResult,
  NormalizedCacheObject,
  gql,
} from "@apollo/client";
import { AppContext } from "../../QGContext";

/**
 * The sign in function
 *
 * @param {string} accessToken The access token
 * @param {ApolloClient<NormalizedCacheObject>} accClient The Apollo client
 * @returns {Promise<boolean>} The sign in
 */
export async function isAuthenticated(
  accessToken: string,
  accClient: ApolloClient<NormalizedCacheObject>,
): Promise<boolean>
{
  const result = await accClient.query({
    query: gql`
      {
        isAuthenticated
      }
    `,
    context: {
      headers: {
        accessToken,
      },
    },
  });

  return result.data.isAuthenticated as boolean;
}

/**
 * Gets the AccHubs from the MuMiverse Acc Connect API
 *
 * @param {AppContext} context The context of the application
 * @returns {Promise<Hub[] | undefined>} The hubs from the MuMiverse Acc Connect API
 */
export async function getHubs(context: AppContext): Promise<Hub[]>
{
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const result = await context.accClient!.query({
    query: gql`
      {
        getHubs {
          response {
            hubId
            name
            region
          }
        }
      }
    `,
    context: {
      headers: {
        accessToken: context.tokens.accessToken,
      },
    },
  });

  if (result?.data?.getHubs?.response)
  {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const queriedHubs = (result.data.getHubs.response as Array<any>).map(
      (hub) => 
      {
        return { hubId: hub.hubId, name: hub.name, region: hub.region };
      },
    );

    return queriedHubs as Hub[];
  }
  throw new Error("Hubs not found");
}

/**
 * Gets the projects from the MuMiverse Acc Connect API
 *
 * @param {string} hubId The hub ID
 * @param {AppContext} context The context of the application
 * @returns {Promise<Project[]>} The projects from the MuMiverse Acc Connect API
 */
export async function getProjects(
  hubId: string,
  context: AppContext,
): Promise<Project[]>
{
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const result = await context.accClient!.query({
    query: gql`
        {
          getProjects(hubId: "${hubId}") {
            response {
              projectId
              name
              rootFolderId
            }
          }
        }
      `,
    context: {
      headers: {
        accessToken: context.tokens.accessToken,
      },
    },
  });

  if (result?.data?.getProjects?.response)
  {
    const queriedProjects
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      = (result.data.getProjects.response as Array<any>).map((project) => 
      {
        return {
          projectId: project.projectId,
          name: project.name,
          rootFolderId: project.rootFolderId,
        };
      });

    return queriedProjects as Project[];
  }
  throw new Error("Projects not found");
}

/**
 * Gets the user from the MuMiverse Acc Connect API
 *
 * @param {string} accessToken The access token
 * @param {ApolloClient<NormalizedCacheObject>} accClient The Apollo client
 * @returns {Promise<AccUser | undefined>} The user from the MuMiverse Acc Connect API
 */
export async function getUser(
  accessToken: string,
  accClient: ApolloClient<NormalizedCacheObject>,
): Promise<AccUser>
{
  const result = await accClient.query({
    query: gql`
      {
        getUser {
          userId
          userName
        }
      }
    `,
    context: {
      headers: {
        accessToken,
      },
    },
  });

  if (result?.data?.getUser)
  {
    return {
      userId: result.data.getUser.userId,
      userName: result.data.getUser.userName,
    };
  }
  throw new Error("User not found");
}

/**
 * Gets the hub by ID from the MuMiverse Acc Connect API
 *
 * @param {string} hubId The hub ID
 * @param {AppContext} context The context of the application
 * @returns {Promise<Hub | undefined>} The hub by ID from the MuMiverse Acc Connect API
 */
export async function getHubById(
  hubId: string,
  context: AppContext,
): Promise<Hub | undefined>
{
  const query = gql`
    {
      getHub(hubId: "${hubId}") {
        response {
          hubId
          name
          region
        }
      }
    }
    `;

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const result = await context.accClient!.query({
    query: query,
    context: {
      headers: {
        accessToken: context.tokens.accessToken,
      },
    },
  });

  if (result?.data?.getHub?.response)
  {
    return {
      hubId: result.data.getHub.response.hubId,
      name: result.data.getHub.response.name,
      region: result.data.getHub.response.region,
    };
  }
}

/**
 * Gets the project by ID from the MuMiverse Acc Connect API
 *
 * @param {string} projectId The project ID
 * @param {string} hubId The hub ID
 * @param {AppContext} context The context of the application
 * @returns {Promise<Project | undefined>} The project by ID from the MuMiverse Acc Connect API
 */
export async function getProjectById(
  projectId: string,
  hubId: string,
  context: AppContext,
): Promise<Project | undefined>
{
  const query = gql`
    {
      getProject(hubId: "${hubId}", projectId: "${projectId}") {
        response {
          projectId
          name
          rootFolderId
        }
      }
    }
    `;

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const result = await context.accClient!.query({
    query: query,
    context: {
      headers: {
        accessToken: context.tokens.accessToken,
      },
    },
  });

  if (!result?.data?.getProject?.response)
  {
    return;
  }

  return {
    projectId: result.data.getProject.response.projectId,
    name: result.data.getProject.response.name,
    rootFolderId: result.data.getProject.response.rootFolderId,
  };
}

/**
 * Generates the tokens from the MuMiverse Acc Connect API
 *
 * @param {string} code The code
 * @param {ApolloClient<NormalizedCacheObject>} accClient The Apollo client
 * @param {string} accessToken The access token
 * @returns {Promise<void>} The tokens from the MuMiverse Acc Connect API
 */
export async function generateTokens(
  code: string,
  accClient: ApolloClient<NormalizedCacheObject>,
  accessToken: string,
): Promise<boolean>
{
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const result = await accClient.query({
    query: gql`
      query storeAuth($code: String) {
        storeAuthenticationInfo(
          providerSettings: { items: [{ key: "code", value: $code }] }
        )
      }
    `,
    variables: {
      code: code,
    },
    context: {
      headers: {
        accessToken,
      },
    },
  });
  return result.data.storeAuthenticationInfo as boolean;
}

/**
 * Handles the sign out from the MuMiverse Acc Connect API
 *
 * @param {AppContext} context The context of the application
 * @returns {Promise<ApolloQueryResult<any>>} The sign out from the MuMiverse Acc Connect API
 */
export async function handleLogOut(
  context: AppContext,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<ApolloQueryResult<any>>
{
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const result = context.accClient!.query({
    query: gql`
      {
        signOut {
          additionalInformation
          nextStep
          success
        }
      }
    `,
    context: {
      headers: {
        accessToken: context.tokens.accessToken,
      },
    },
  });
  return await result;
}