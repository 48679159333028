/* eslint-disable @typescript-eslint/no-unused-vars */
import { Avatar, AvatarFallback, AvatarImage } from "./ui/avatar";
import React, { useContext, useState } from "react";
import { logout } from "../support/authentication";
// eslint-disable-next-line sort-imports
import { Card, CardContent } from "./ui/card";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "./ui/dropdown-menu";
import { Tabs, TabsList, TabsTrigger } from "./ui/tabs";
import { Badge } from "./ui/badge";
import { Box } from "@mui/material";
import { Button } from "./ui/button";
import CardActionArea from "@mui/material/CardActionArea";
import CircularProgress from "@mui/material/CircularProgress";
import CreateProjectDialog from "./CreateProjectDialog";
import English from "./Images/united-kingdom_555417.png";
import Logo from "./Images/Icon.svg";
import QGContext from "../QGContext";
import { Separator } from "./ui/separator";
import UserInfo from "./UserInfo";
import { useDeleteProject } from "../api/rest/useDeleteProject";
// eslint-disable-next-line sort-imports
import { ChevronDown, Heart, LogOut, Plus, Trash } from "lucide-react";
import { useGetProjects } from "../api/rest/useGetProjects";
import { usePostProject } from "../api/rest/usePostProject";
import { useTranslation } from "react-i18next";
import "./ProjectList.scss";

/**
 * The project cards component.
 *
 * @returns {JSX.Element} - The element
 */

// eslint-disable-next-line jsdoc/require-jsdoc
const ProjectList = () => 
{
  const { t } = useTranslation();
  const ctx = useContext(QGContext);

  const [showModal, setShowModal] = useState(false);
  const { data: projects, isLoading } = useGetProjects();

  const handleClose = () => setShowModal(!showModal);

  const projectCreator = usePostProject();
  const projectRemover = useDeleteProject();
  const removeProject = (projectId: string) => 
  {
    const isConfirmed = window.confirm(t("ui.prompt.areyousure") as string);

    if (!isConfirmed)
    {
      return;
    }
    projectRemover.mutate(projectId);
  };

  if (isLoading || projectCreator.isPending || projectRemover.isPending)
  {
    return (
      <div>
        <Box>
          <CircularProgress className="spinner" />
        </Box>
      </div>
    );
  }

  return (
    <>
      <div
        className={`parent-container ${showModal ? "blur-parent" : ""} flex`}
      >
        {/* Sidebar-Container */}
        <div className="flex flex-col bg-black bg-opacity-20 min-h-screen w-[272px] min-w-[272px] p-4 ">
          <div className="grid items-center justify-center h-[80px] w-[232px] ">
            <img src={Logo} alt="logo" className="logo w-[195px] " />
          </div>
          <Separator />

          <div className=" grow p-2"></div>
          <Separator />
          <div className=" h-[80px] w-[232px] flex items-center justify-center ">
            <DropdownMenu>
              <DropdownMenuTrigger>
                <Button className=" flex w-[232px] h-[63px] relative justify-start items-center bg-transparent hover:bg-white/5 ">
                  {/* UserInfo */}
                  <div className="flex justify-start">
                    <UserInfo userImage="/user-image.jpg" />
                  </div>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="bg-slate-800 w-[250px] h-[180px] text-white border-0 p-4">
                <div className="flex justify-between pb-4">
                  <p className="font-sans text-xs ">Language</p>
                  <ChevronDown className="w-[16px] h-[16px] " />
                </div>
                <DropdownMenuItem className=" flex items-center p-4 h-[48px] text-base gap-x-4 bg-white/10 hover:bg-white/20">
                  <img src={English} alt="English" className="logo w-[20px] " />
                  Language
                </DropdownMenuItem>
                <DropdownMenuSeparator className="opacity-20 " />
                <DropdownMenuItem
                  onClick={logout}
                  className="p-4 gap-x-4 h-[48px] text-base hover:bg-white/10"
                >
                  <LogOut className="w-[20px] h-[20px] rotate-180" />
                  {t("ui.user.logout")}
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>

        <div className="flex flex-col items-start  w-full ">
          <div className="project_header-container flex-col h-[162px] w-full  pl-[32px] pt-[20px] pr-[32px] pb-[20px]">
            <div className="title-container flex text-2xl font-sans text-white text-left w-full">
              {t("ui.projectlist.projects")}
            </div>

            <div className="desc-container flex justify-between grow text-sm font-sans text-slate-400 pt-[8px]">
              {t(
                "Manage your projects or add a new one by clicking the button on the right.",
              )}
              <Button
                className="button_new flex-none justify-center items-center bg-white text-slate-900 font-sans font-medium"
                onClick={() => setShowModal(true)}
              >
                <Plus className="w-[20px] h-[20px] pr-[8px] " />
                {t("Add New Project")}
              </Button>
            </div>

            <Tabs defaultValue="All projects" className=" pt-[8px]">
              <TabsList>
                <TabsTrigger value="All projects">All projects</TabsTrigger>
              </TabsList>
            </Tabs>
            <Separator className="w-full border-[0.2px] border-white/10" />
          </div>

          <div className="card-container flex h-full w-[1450px] space-x-6 pl-[32px] pt-[20px] pr-[32px] pb-[20px]">
            {/* eslint-disable @typescript-eslint/no-explicit-any */}
            {projects.map((project: any) => (
              <React.Fragment key={project.id}>
                <Card className="w-[264px] h-[248px] font-sans bg-white bg-opacity-10 p-6 border-transparent overflow-visible ">
                  <CardActionArea className="p-0 bg-yellow-200">
                    <CardContent
                      className="card-content p-0 w-[212px] h-[168px] "
                      onClick={() =>
                        ctx.setProject({
                          id: project.id,
                          accHubId: project.accHubId
                            ? project.accHubId
                            : undefined,
                          accProjectId: project.accProjectId
                            ? project.accProjectId
                            : undefined,
                        })
                      }
                    >
                      <h3 className="card-header grid text-white text-lg font-medium  ">
                        {project.name}
                      </h3>
                      <p className="card-body text-sm  font-medium text-slate-400">
                        {project.description.length > 100
                          ? project.description.substring(0, 100) + "..."
                          : project.description}
                      </p>{" "}
                    </CardContent>
                    <div className=" inline-flex justify-between  items-center w-full ">
                      <Badge className="w-[84px] h-[22px] text-sx text-white">
                        No. {project.projectNumber}
                      </Badge>
                      {/* <Heart className="grid justify-end w-[16px] h-[16px] " /> */}

                      <Button
                        className=" flex relative rounded-full h-[30px] w-[30px] bg-transparent hover:bg-orange-600/10 "
                        onClick={() => removeProject(project.id)}
                      >
                        <Trash className="flex absolute w-[16px] h-[16px] stroke-orange-600  " />
                      </Button>
                    </div>
                  </CardActionArea>
                </Card>
              </React.Fragment>
            ))}
          </div>

          {showModal && (
            <CreateProjectDialog isVisible={showModal} onClose={handleClose} />
          )}
        </div>
      </div>
    </>
  );
};

export default ProjectList;