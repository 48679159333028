import { AccessType } from "../interfaces/AccessType";
import { IPermission } from "../interfaces/IPermission";

/**
 * The Permission.
 *
 * @class
 * @augments {IPermission}
 */
export class Permission implements IPermission
{
  /**
   * Create an instance of Permission.
   *
   * @param {IPermission} permission The permission.
   */
  constructor(permission?: IPermission | null)
  {
    if (permission === null || permission === undefined)
    {
      this.accessType = AccessType.View;
      this.resourceName = "";
      this.key = "";
    }
    else 
    {
      this.accessType = permission.accessType;
      this.resourceName = permission.resourceName;
      this.key = `${permission.resourceName.toLowerCase()}:${permission.accessType.toLowerCase()}`;
    }
  }
  /**
   * The access type.
   *
   * @property {AccessType} accessType The access type.
   */
  accessType: AccessType;

  /**
   * The resource name.
   *
   * @property {string} resourceName The resource name.
   */
  resourceName: string;

  /**
   * The key
   *
   * @property {string} key The key.
   */
  key: string;
}