import React, { FormEvent, FunctionComponent } from "react";
import Button from "@mui/material/Button";
import DOMPurify from "dompurify";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";

interface ValidationSetUploadDialogProps
{
  isVisible: boolean;
  onUploadFileButtonClick: () => void;
  onCancel: () => void;
}

/**
 * The validation set upload dialog.
 *
 * @param {ValidationSetUploadDialogProps} props The props.
 * @returns {JSX.Element} The component instance.
 */
const ValidationSetUploadDialog: FunctionComponent<
  ValidationSetUploadDialogProps
> = (props: ValidationSetUploadDialogProps): JSX.Element => 
{
  const { t } = useTranslation();

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => 
  {
    event.preventDefault();
    props.onUploadFileButtonClick();
  };

  return (
    <Dialog
      open={props.isVisible}
      onClose={props.onCancel}
      PaperProps={{
        component: "form",
        onSubmit: handleSubmit,
      }}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>{t("ui.importexcel.dialogheader")}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={props.onCancel}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <FontAwesomeIcon icon="xmark" />
      </IconButton>
      <DialogContent>
        <p
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              t("ui.importexcel.description") as string,
              {
                USE_PROFILES: { html: true, svg: true },
              },
            ),
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onCancel}>{t("ui.cancel")}</Button>
        <Button type="submit">{t("ui.uploadfile")}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ValidationSetUploadDialog;