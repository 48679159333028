/* eslint-disable @typescript-eslint/no-explicit-any */
import * as axios from "axios";
import QGContext from "../../QGContext";
import { useContext } from "react";
import { useQuery } from "@tanstack/react-query";

/**
 * Gets the models from the Quality Gate API
 *
 * @param {string | null | undefined} projectId The project ID
 * @returns {Promise<any>} The project from the Quality Gate API
 */
export const useGetProject = (projectId: string | null | undefined) => 
{
  const { tokens } = useContext(QGContext);

  // TODO typing
  return useQuery<any, any>({
    queryKey: ["getProject", projectId],
    queryFn: async () => 
    {
      const response = await axios.default.get(
        `/qualitygate/v1/project/${projectId}`,
        {
          headers: {
            userToken: tokens.idToken,
            accessToken: tokens.accessToken,
          },
        },
      );

      console.log("useGetProject", response.data);
      return response.data;
    },
    enabled: !!projectId,
  });
};