import { IPermission } from "../interfaces/IPermission";
import { IRole } from "../interfaces/IRole";
import { v4 as uuid } from "uuid";

/**
 * The Role.
 *
 * @class
 * @augments {IRole}
 */
export class Role implements IRole
{
  /**
   * Create an instance of Role.
   *
   * @param {IRole} role The role.
   */
  constructor(role?: IRole | null)
  {
    if (role === null || role === undefined)
    {
      this.id = uuid();
      this.name = "";
      this.expiration = null;
      this.created = new Date();
      this.permissions = [];
      this.level = 0;
    }
    else 
    {
      this.id = role.id;
      this.name = role.name;
      this.expiration = role.expiration;
      this.created = role.created;
      this.permissions = role.permissions;
      this.level = role.level;
    }
  }

  /**
   * The ID.
   *
   * @property {string} id The ID.
   */
  id: string;

  /**
   * The name.
   *
   * @property {string} name The name.
   */
  name: string;

  /**
   * The level.
   *
   * @property {number} level The level.
   */
  level: number;

  /**
   * The expiration date.
   *
   * @param {string} expiration The expiration date.
   */
  expiration: Date | null;

  /**
   * The created date.
   *
   * @param {Date} created The created date.
   */
  created: Date;

  /**
   * The permission list.
   *
   * @param {IPermission[]} permissions The permission list.
   */
  permissions: IPermission[];
}