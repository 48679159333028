import React, {
  FunctionComponent,
  Suspense,
  useContext,
  useEffect,
} from "react";
import { Route, Routes } from "react-router-dom";
import ErrorBoundary from "./ErrorBoundary";
import Project from "./Project";
import ProjectList from "./ProjectList";
import ProjectMembers from "./ProjectMembers";
import ProjectRoles from "./ProjectRoles";
import QGContext from "../QGContext";
import SideBar from "./SideBar";
import Stakeholder from "./Stakeholder";
import ValidationArrangement from "./ValidationArrangement";
import ValidationResults from "./ValidationResults";
import ValidationSetContainer from "./ValidationSetContainer";
import ValidationSets from "./ValidationSets";
import { useGetProject } from "../api/rest/useGetProject";
import { useTranslation } from "react-i18next";

/**
 * The root layout functional component.
 *
 * @returns {JSX.Element} The component instance.
 */
const RootLayout: FunctionComponent = (): JSX.Element => 
{
  const { t } = useTranslation();
  const ctx = useContext(QGContext);

  // note that next 2 lines can be moved to SideBar.tsx as soon as it got a function component
  const { data: project } = useGetProject(
    ctx.project.id || window.localStorage.getItem("pid"),
  );

  useEffect(() => 
  {
    if (!project)
    {
      return;
    }
    ctx.setProject({
      id: project.id,
      accHubId: project.accHubId,
      accProjectId: project.accProjectId,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  return (
    <Suspense fallback="Loading translations...">
      <div
        className="flex items-start justify-between w-screen h-screen "
        id="update"
      >
        {ctx.project.id && <SideBar projectName={project?.name} />}
        <main className="w-full h-full">
          <ErrorBoundary>
            {ctx.project.id ? (
              <Routes>
                <Route path="projectinformation" element={<Project />} />
                <Route path="members" element={<ProjectMembers />} />
                <Route path="roles" element={<ProjectRoles prop={t} />} />
                <Route path="stakeholders" element={<Stakeholder />} />
                <Route path="validationsets" element={<ValidationSets />} />
                <Route
                  path="validation-sets"
                  element={<ValidationSetContainer />}
                />
                <Route
                  path="visual-designer/:id"
                  element={<ValidationSetContainer />}
                />
                <Route
                  path="validation-results"
                  element={<ValidationResults />}
                />
                <Route
                  path="validation-arrangement"
                  element={<ValidationArrangement />}
                />
              </Routes>
            ) : (
              <ProjectList />
            )}
          </ErrorBoundary>
        </main>
      </div>
    </Suspense>
  );
};

export default RootLayout;