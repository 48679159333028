import { LOGIN_URL, USER_POOL_CLIENT_ID, USER_POOL_ID } from "../environment";
import { CognitoJwtVerifier } from "aws-jwt-verify/cognito-verifier";

/**
 * The three cognito tokens
 *
 * @interface
 */
export interface CognitoTokens
{
  /**
   * The access token
   */
  accessToken: string;
  /**
   * The id token
   */
  idToken: string;
  /**
   * The refresh token
   */
  refreshToken: string;
}
/**
 * The three cognito tokens
 *
 * @interface
 */
export interface Claims
{
  /**
   * The cognito user id
   */
  sub: string;
  /**
   * The email address
   */
  email: string;
  /**
   * The given name
   */
  given_name: string;
  /**
   * The family name
   */
  family_name: string;
}
/**
 * Authentication result: Tokens and Claims
 *
 * @interface
 */
export interface AuthData
{
  /**
   * The tokens
   */
  tokens: CognitoTokens;
  /**
   * The claims
   */
  claims: Claims;
}

/**
 * Process the authentication of the user.
 * Flow:
 *  In the beginning there will be no (or expired) session storage data. QG then redirects to login app.
 *  Login app will then redirect to QG including access, id and refresh tokens as querystring parameter.
 *  QG verify the tokens. If they are valid, data will be put in session storage and QS reloads itself
 *  to get rid of the tokens in the querystring.
 *  This time the constellation looks like this: QG has no tokens in the querystring, but valid session storage data,
 *  i.e. authenication process is successfully finished.
 *  If the user comes back later, it might happen that the access token in the session storage expired.
 *  In this case QG will redirect to login app to get a new access token.
 *
 * @returns {AuthData | undefined} Available AuthData indicates that the user has been successfully authenticated. Undefined means that the auth flow is not yet finished
 */
export const processAuthentication = async (): Promise<
  AuthData | undefined
> => 
{
  const loginUrl = `${LOGIN_URL}/?redirectUrl=${window.location.protocol}//${window.location.host}/`;

  const urlQueryParameters = new URLSearchParams(window.location.search);

  const accessTokenFromQueryString = urlQueryParameters.get("accessToken");

  const accessTokenVerifier = createVerifier("access");

  if (accessTokenFromQueryString)
  {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const idTokenFromQueryString = urlQueryParameters.get("idToken")!;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const refreshTokenFromQueryString = urlQueryParameters.get("refreshToken")!;

    removeTokensFromSessionStorage();

    const idTokenVerifier = createVerifier("id");

    try 
    {
      await accessTokenVerifier.verify(accessTokenFromQueryString);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      await idTokenVerifier.verify(idTokenFromQueryString!);
    }
    catch (error)
    {
      // in case accesstoken or idtoken are invalid (e.g. expired), redirect to login and try again...
      window.location.href = loginUrl;
      return;
    }

    setTokensInSessionStorage(
      idTokenFromQueryString,
      accessTokenFromQueryString,
      refreshTokenFromQueryString,
    );

    window.location.href = window.location.pathname;
    return;
  }
  else 
  {
    const accessTokenFromSessionStorage
      = window.sessionStorage.getItem("accessToken");
    let isValidAccessToken = false;
    if (accessTokenFromSessionStorage)
    {
      try 
      {
        await accessTokenVerifier.verify(accessTokenFromSessionStorage);
        isValidAccessToken = true;
      }
      catch 
      {
        isValidAccessToken = false;
      }
    }

    if (!isValidAccessToken)
    {
      // in case accesstoken is invalid (e.g. expired), redirect to login and get a new one...
      window.location.href = loginUrl;
      return;
    }
  }

  // no accesstoken in querstring, valid session storage data => authentication successful
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const idToken = window.sessionStorage.getItem("userToken")!;
  const idTokenPayload = await createVerifier("id").verify(idToken);
  return {
    tokens: {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      accessToken: window.sessionStorage.getItem("accessToken")!,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      idToken,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      refreshToken: window.sessionStorage.getItem("refreshToken")!,
    },
    claims: {
      sub: idTokenPayload.sub,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      email: idTokenPayload["email"]!.toString(),
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      given_name: idTokenPayload["given_name"]!.toString(),
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      family_name: idTokenPayload["family_name"]!.toString(),
    },
  };
};

/**
 * Logout of currently signed in user
 *
 * @returns {void}
 */
export const logout = (): void => 
{
  removeTokensFromSessionStorage();
  window.location.href = `${LOGIN_URL}/logout`;
};

/**
 * Set the tokens in the session storage
 *
 * @param {string} idToken The id token
 * @param {string} accessToken The access token
 * @param {string} refreshToken The refresh token
 */
export const setTokensInSessionStorage = (
  idToken: string,
  accessToken: string,
  refreshToken?: string,
): void => 
{
  window.sessionStorage.setItem("userToken", idToken);
  window.sessionStorage.setItem("accessToken", accessToken);
  if (refreshToken)
  {
    window.sessionStorage.setItem("refreshToken", refreshToken);
  }
};

const removeTokensFromSessionStorage = (): void => 
{
  sessionStorage.removeItem("accessToken");
  sessionStorage.removeItem("userToken");
  sessionStorage.removeItem("refreshToken");
};

const createVerifier = (tokenUse: "access" | "id") => 
{
  return CognitoJwtVerifier.create({
    userPoolId: USER_POOL_ID,
    tokenUse,
    clientId: USER_POOL_CLIENT_ID,
  });
};