/**
 * Format the date.
 *
 * @param {string} date The date.
 * @returns {string} A formatted date.
 */
export function formatDate(date: string | Date | null)
{
  let internalDate: Date;

  if (typeof date === "string")
  {
    internalDate = new Date(date);
  }
  else if (date instanceof Date)
  {
    internalDate = date;
  }
  else 
  {
    internalDate = new Date(0);
  }

  const dateFormatter = new Intl.DateTimeFormat("default", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  const timeFormatter = new Intl.DateTimeFormat("default", {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  });

  return (
    dateFormatter.format(internalDate)
    + " "
    + timeFormatter.format(internalDate)
  );
}