import { Alert, Avatar, Button, Chip, FormControl } from "@mui/material";
import { Hub, Project } from "./SignIn.types";
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { getHubs, getProjects, handleLogOut } from "../api/graphql/signIn";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import QGContext from "../QGContext";
import Skeleton from "@mui/material/Skeleton";
import { gql } from "@apollo/client";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import "./SignIn.scss";

/**
 * Interface for the SignIn arguments.
 *
 * @interface
 */
export interface SignInProps
{
  /**
   * The called event handler if a hub has been changed.
   *
   * @param {string} hubId The hub ID.
   */
  onChangeHub: (hubId: string) => void;

  /**
   * The called event handler if a project has been changed.
   *
   * @param {string} projectId The project ID.
   */
  onChangeProject: (projectId: string) => void;
}

/**
 * The SignIn component.
 *
 * @param {SignInProps} props The properties.
 * @class
 * @augments {React.Component<SignInProps>}
 * @returns {JSX.Element} The component.
 */
const SignIn: FunctionComponent<SignInProps> = (props): JSX.Element => 
{
  const context = useContext(QGContext);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoadingHubs, setIsLoadingHubs] = useState<boolean>(false);
  const [isLoadingProjects, setIsLoadingProjects] = useState<boolean>(false);
  const [isAuthenticating, setIsAuthenticating] = useState<boolean>(false);
  const [availableHubs, setAvailableHubs] = useState<Hub[]>([]);
  const [selectedHubId, setSelectedHubId] = useState<string>("");
  const [availableProjects, setAvailableProjects] = useState<Project[]>([]);
  const [selectedProjectId, setSelectedProjectId] = useState<string>("");

  useEffect(() => 
  {
    if (context.acc === undefined || context.acc.isAuthenticated === false)
    {
      return;
    }
    (async () => 
    {
      await getAccData();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.acc]);

  const getAccData = async (): Promise<void> => 
  {
    let queriedHubs: Hub[] = [];
    setIsLoadingHubs(true);
    try 
    {
      queriedHubs = await getHubs(context);
    }
    catch (error)
    {
      context.setAccAuthentication(false);
      enqueueSnackbar("Error while fetching the Hubs. Please retry.", {
        variant: "error",
      });
      return;
    }
    finally 
    {
      setIsLoadingHubs(false);
    }

    setAvailableHubs(queriedHubs);

    if (context.project.accHubId)
    {
      let queriedProjects: Project[] = [];
      setIsLoadingProjects(true);

      try 
      {
        queriedProjects = await getProjects(context.project.accHubId, context);
      }
      catch (error)
      {
        context.setAccAuthentication(false);
        enqueueSnackbar("Error while fetching the Projects. Please retry.", {
          variant: "error",
        });
        return;
      }
      finally 
      {
        setIsLoadingProjects(false);
      }

      setAvailableProjects(queriedProjects);

      const selectedHub = queriedHubs.find(
        (hub) => hub.hubId === context.project.accHubId,
      );

      if (selectedHub)
      {
        setSelectedHubId(selectedHub.hubId);
      }
      else 
      {
        enqueueSnackbar("Couldn't find the previously selected hub.", {
          variant: "warning",
        });
        return;
      }

      if (context.project.accProjectId)
      {
        const selectedProject = queriedProjects.find(
          (project) => project.projectId === context.project.accProjectId,
        );

        if (selectedProject)
        {
          setSelectedProjectId(selectedProject.projectId);
        }
        else 
        {
          enqueueSnackbar("Couldn't find the previously selected project.", {
            variant: "warning",
          });
        }
      }
    }
  };

  const authenticate = async (): Promise<void> => 
  {
    setIsAuthenticating(true);
    let url = new URL(document.location as unknown as string);

    if (url.toString().includes("?"))
    {
      const urlParts = url.toString().split("?");
      const newUrl = urlParts[0];
      // only keep the url without the query parameters
      url = new URL(newUrl);
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const result = await context.accClient!.query({
      query: gql`{ authenticate(providerSettings:{items:[{key:"CallbackUri", value: "${url}" }]}){additionalInformation,nextStep,success} }`,
    });

    const uri = result.data.authenticate
      ? result.data.authenticate.additionalInformation
      : null;

    if (uri === null)
    {
      // TODO toast
      return;
    }

    window.location.href = uri;
  };

  const handleHubChange = async (event: SelectChangeEvent) => 
  {
    const hub = availableHubs.find((hub) => hub.hubId === event.target.value);
    if (!hub)
    {
      return;
    }
    setIsLoadingProjects(true);
    setSelectedHubId(hub.hubId);
    setSelectedProjectId("");

    const projects = await getProjects(hub.hubId, context);
    if (projects !== undefined && projects.length > 0)
    {
      setAvailableProjects(projects);
    }
    props.onChangeHub(hub.hubId);
    setIsLoadingProjects(false);
  };

  const handleProjectChange = (event: SelectChangeEvent) => 
  {
    const project = availableProjects.find(
      (project) => project.projectId === event.target.value,
    );

    if (!project)
    {
      return;
    }

    setSelectedProjectId(project.projectId);
    props.onChangeProject(project.projectId);
  };

  const handleLogout = async (): Promise<void> => 
  {
    setIsAuthenticating(true);

    const result = await handleLogOut(context);
    if (result.data.signOut.success as boolean)
    {
      setAvailableHubs([]);
      setAvailableProjects([]);
      setSelectedHubId("");
      setSelectedProjectId("");
      context.setAccAuthentication(false);
    }

    setIsAuthenticating(false);
  };

  if (context.acc?.isAuthenticated === undefined || isAuthenticating)
  {
    return (
      <div className="bim-section-skeleton">
        <Skeleton
          height={40}
          className="button-section-skeleton"
          variant="rectangular"
        ></Skeleton>
      </div>
    );
  }

  return (
    <>
      {context.acc.isAuthenticated ? (
        <div className="hub-wrapper">
          <div className="choose-hub-project">
            <div className="sign-out">
              <Chip
                className="avatar"
                avatar={
                  <Avatar>
                    {context.acc.userName ? context.acc.userName[0] : ""}
                  </Avatar>
                }
                label={context.acc.userName}
              />
              <Button
                onClick={handleLogout}
                style={{
                  marginLeft: 10,
                  backgroundColor: "#f73e3e",
                  color: "white",
                  borderRadius: "20px",
                }}
                variant="outlined"
              >
                LogOut
              </Button>
            </div>
            <div className="hubs-dropdown">
              {isLoadingHubs ? (
                <div className="bim-section-skeleton">
                  <Skeleton variant="rectangular"></Skeleton>
                </div>
              ) : (
                <Box>
                  <FormControl fullWidth variant="filled">
                    <InputLabel>Hubs</InputLabel>
                    <Select
                      sx={{ backgroundColor: "white" }}
                      value={selectedHubId}
                      label={
                        availableHubs.find((hub) => hub.hubId === selectedHubId)
                          ?.name
                      }
                      onChange={handleHubChange}
                    >
                      {availableHubs ? (
                        availableHubs.map((hub) => (
                          <MenuItem key={hub.hubId} value={hub.hubId}>
                            {hub.name}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem value={""}></MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Box>
              )}
            </div>
            {isLoadingProjects ? (
              <div className="bim-section-skeleton">
                <Skeleton height={40} variant="rectangular"></Skeleton>
              </div>
            ) : (
              <></>
            )}
            {!isLoadingProjects && selectedHubId !== "" ? (
              <div className="projects-dropdown">
                <Box>
                  <FormControl fullWidth variant="filled">
                    <InputLabel>Projects</InputLabel>
                    <Select
                      sx={{ backgroundColor: "white" }}
                      value={selectedProjectId}
                      label={
                        availableProjects.find(
                          (project) => project.projectId === selectedProjectId,
                        )?.name
                      }
                      onChange={handleProjectChange}
                    >
                      {availableProjects.length > 0 ? (
                        availableProjects.map((project) => (
                          <MenuItem
                            key={project.projectId}
                            value={project.projectId}
                          >
                            {project.name}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem value={""}></MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Box>
              </div>
            ) : (
              <></>
            )}
            {selectedProjectId !== "" && selectedHubId !== "" ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Alert
                  severity="success"
                  style={{ marginTop: "10px", borderRadius: "20px" }}
                >
                  {t("ui.projectinformation.signin.success")}
                </Alert>
              </div>
            ) : (
              <div></div>
            )}
          </div>
        </div>
      ) : (
        <div className="bim-section">
          <button onClick={authenticate} className="button_connect-bim">
            <svg
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="Icon" clipPath="url(#clip0_220_4269)">
                <path
                  id="Union"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10.8092 0.97627C12.1109 -0.325423 14.2216 -0.325423 15.5233 0.97627C16.825 2.27796 16.825 4.3881 15.5233 5.68979L11.3753 9.83781C10.0736 11.1395 7.96352 11.1395 6.66183 9.83781C6.40148 9.57747 6.40148 9.15534 6.66183 8.895C6.92217 8.63465 7.3443 8.63465 7.60464 8.895C8.38562 9.676 9.6515 9.676 10.4325 8.895L14.5805 4.74695C15.3615 3.96597 15.3615 2.70006 14.5805 1.91909C13.7995 1.13808 12.5329 1.13808 11.752 1.91909L9.48996 4.1811C9.22962 4.44144 8.80749 4.44144 8.54715 4.1811C8.2868 3.92075 8.2868 3.49863 8.54715 3.23828L10.8092 0.97627ZM5.2476 14.0804L7.13295 12.1951C7.3933 11.9347 7.81546 11.9347 8.0758 12.195C8.33615 12.4554 8.33615 12.8775 8.0758 13.1379L6.19045 15.0232C4.88807 16.3256 2.77781 16.3256 1.47627 15.0232C0.174577 13.7215 0.174577 11.6114 1.47627 10.3097L5.2476 6.53839C6.5493 5.2367 8.6594 5.2367 9.96109 6.53839C10.2214 6.79874 10.2214 7.22086 9.96109 7.48121C9.70075 7.74155 9.27862 7.74155 9.01828 7.48121C8.2373 6.7002 6.97139 6.7002 6.19042 7.48121L2.41906 11.2526C1.63808 12.0335 1.63808 13.2995 2.41921 14.0806C3.20019 14.8621 4.46597 14.8621 5.2476 14.0804Z"
                  fill="#071222"
                />
              </g>
              <defs>
                <clipPath id="clip0_220_4269">
                  <rect
                    width="16"
                    height="16"
                    fill="white"
                    transform="translate(0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
            <span className="bim-button-title">Connect Cloud</span>
          </button>
        </div>
      )}
    </>
  );
};

export default SignIn;