import * as React from "react";
import { type VariantProps } from "class-variance-authority";
import { cn } from "../../lib/utils";
import { cva } from "class-variance-authority";

const badgeVariants = cva(
  "inline-flex items-center rounded-sm  border px-2.5 py-0.5 text-xs font-medium ",
  {
    variants: {
      variant: {
        default: "border-transparent text-white bg-white/40",
        secondary: "border-transparent bg-secondary text-secondary-foreground",
        destructive:
          "border-transparent bg-destructive text-destructive-foreground ",
        outline: "text-foreground",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  },
);

// eslint-disable-next-line jsdoc/require-jsdoc
export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants>
{}

// eslint-disable-next-line brace-style, jsdoc/require-jsdoc, @typescript-eslint/space-before-blocks, space-before-blocks
function Badge({ className, variant, ...props }: BadgeProps) 
{
  return (
    <div className={cn(badgeVariants({ variant }), className)} {...props} />
  );
}

export { Badge, badgeVariants };