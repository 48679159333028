import * as React from "react";
import { DiagramEngine, PortWidget } from "@projectstorm/react-diagrams-core";
import { FilterPortModel } from "./FilterPortModel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "@emotion/styled";

/**
 * Interface for the filter port widget arguments.
 *
 * @interface
 */
export interface FilterPortLabelProps
{
  /**
   * The port.
   *
   * @property {FilterPortModel} port The port.
   */
  port: FilterPortModel;

  /**
   * The engine.
   *
   * @property {DiagramEngine} engine The engine.
   */
  engine: DiagramEngine;
}

/**
 * The styling namespace for a filter port label widget.
 *
 * @namespace
 */
namespace S 
{
  /**
   * The port label.
   */
  export const PortLabel = styled.div`
    display: flex;
    margin-top: 1px;
    align-items: center;
  `;

  /**
   * The label.
   */
  export const Label = styled.div`
    padding-right: 2.5rem;
    padding-left: 0.75rem;
    flex-grow: 1;

    & svg {
      margin-right: 0.25rem;
    }
  `;

  /**
   * The port.
   */
  export const Port = styled.div`
    width: 15px;
    height: 1.5rem;
    background: rgba(255, 255, 255, 0.1);
    &:hover {
      background: rgb(192, 255, 0);
    }
  `;
}

/**
 * The custom port label.
 *
 * @class
 * @augments {React.Component<FilterPortLabelProps>}
 */
export class FilterPortLabelWidget extends React.Component<FilterPortLabelProps>
{
  /**
   * Render the component.
   *
   * @returns {JSX.Element} The component instance.
   */
  render()
  {
    const port = (
      <PortWidget engine={this.props.engine} port={this.props.port}>
        <S.Port></S.Port>
      </PortWidget>
    );
    const label = (
      <S.Label>
        <FontAwesomeIcon icon="filter" />
        {this.props.port.getOptions().label}
      </S.Label>
    );

    return (
      <S.PortLabel>
        {port}
        {label}
      </S.PortLabel>
    );
  }
}