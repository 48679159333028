import * as React from "react";
import * as _ from "lodash";
import { ClassPortLabelWidget } from "./port/ClassPortLabelWidget";
import { ClassPortModel } from "./port/ClassPortModel";
import { CustomNodeModel } from "./CustomNodeModel";
import { CustomPortLabelWidget } from "./port/CustomPortLabelWidget";
import { CustomPortModel } from "./port/CustomPortModel";
import { DiagramEngine } from "@projectstorm/react-diagrams-core";
import { FilterPortLabelWidget } from "./port/FilterPortLabelWidget";
import { FilterPortModel } from "./port/FilterPortModel";
import { ListenerHandle } from "@projectstorm/react-canvas-core";
import styled from "@emotion/styled";

/**
 * The styling namespace for a custom node.
 *
 * @namespace
 */
namespace S 
{
  /* eslint-disable jsdoc/require-jsdoc */
  /**
   * The node styling type.
   *
   * @typedef {object} NodeStyling The node styling.
   * @property {string} background The background.
   * @property {boolean} selected A value indicating whether the element is selected.
   */

  /**
   * The title styling type.
   *
   * @typedef {object} TitleStyling The title styling.
   * @property {string} background The background.
   */

  /**
   * The node styling.
   *
   * @param {NodeStyling} p The styling parameters.
   * @returns {string} The border color.
   */
  export const Node = styled.div<{ background: string; selected: boolean }>`
    background-color: var(--accent-back-color);
    border-radius: 5px;
    font-family: sans-serif;
    color: var(--accent-front-color);
    border: solid 2px black;
    overflow: visible;
    line-height: 1.7rem;
    border: solid 2px ${(p) => (p.selected ? "rgb(0,192,255)" : "black")};
  `;

  /**
   * The title styling.
   *
   * @param {TitleStyling} p The styling parameters.
   * @returns {string} The background color.
   */
  export const Title = styled.div<{ background: string }>`
    background: ${(p) => p.background};
    color: #fff;
    display: flex;
    white-space: nowrap;
    justify-items: center;
  `;

  /**
   * The title name styling.
   */
  export const TitleName = styled.div`
    flex-grow: 1;
    padding: 5px 5px;
  `;

  /**
   * The ports styling.
   */
  export const Ports = styled.div`
    display: flex;
    background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2));
  `;

  /**
   * The ports container styling.
   */
  export const PortsContainer = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    &:first-of-type {
      margin-right: 10px;
    }
    &:only-child {
      margin-right: 0px;
    }
  `;
  /* eslint-enable jsdoc/require-jsdoc */
}

/**
 * Interface for the custom node widget arguments.
 *
 * @interface
 */
export interface CustomNodeWidgetProps
{
  /**
   * The node.
   *
   * @property {CustomNodeModel} node The node.
   */
  node: CustomNodeModel;

  /**
   * The engine.
   *
   * @property {DiagramEngine} engine The engine.
   */
  engine: DiagramEngine;
}

/**
 * The custom node widget.
 *
 * @class
 * @augments {React.Component<CustomNodeWidgetProps>}
 */
export class CustomNodeWidget extends React.Component<CustomNodeWidgetProps>
{
  /**
   * The listener.
   *
   * @property {ListenerHandle | null} listener The listener.
   */
  listener: ListenerHandle | null;

  /**
   * Create an instance of CustomNodeWidget.
   *
   * @param {CustomNodeWidgetProps} props The properties.
   */
  constructor(props: CustomNodeWidgetProps)
  {
    super(props);
    this.state = {};

    this.listener = null;
    this.installSelectionListener();
  }

  /**
   * Event handler when the component will unmount.
   *
   * @returns {void}
   */
  componentWillUnmount(): void
  {
    this.listener?.deregister();
    this.listener = null;
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */
  /**
   * Event handler when the component did update.
   *
   * @param {Readonly<CustomNodeWidgetProps>} prevProps The previous properties.
   * @param {Readonly<any>} prevState The previous state.
   * @param {any} snapshot The snapshot.
   * @returns {void}
   */
  componentDidUpdate(
    prevProps: Readonly<CustomNodeWidgetProps>,
    prevState: Readonly<any>,
    snapshot?: any,
  ): void
  {
    /* eslint-enable @typescript-eslint/no-explicit-any */
    if (this.listener && this.props.node !== prevProps.node)
    {
      this.listener.deregister();
      this.installSelectionListener();
    }
  }

  /**
   * Install the selection listener.
   *
   * @returns {void}
   */
  installSelectionListener()
  {
    this.listener = this.props.node.registerListener({
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      selectionChanged: (event: any) => 
      {
        if (this.props.node !== null || this.props.node !== undefined)
        {
          if (event.isSelected)
          {
            this.props.node.selectionEventHandler(this.props.node);
          }
          else 
          {
            this.props.node.selectionEventHandler(null);
          }
        }
      },
    });
  }

  /**
   * Generate a port.
   *
   * @param {CustomPortModel} port The port.
   * @returns {JSX.Element} A Port component instance.
   */
  generatePort = (port: CustomPortModel) => 
  {
    return (
      <CustomPortLabelWidget
        engine={this.props.engine}
        port={port}
        key={port.getID()}
      />
    );
  };

  /**
   * Generate a filter port.
   *
   * @param {FilterPortModel} port The port.
   * @returns {JSX.Element} A FilterPort component instance.
   */
  generateFilterPort = (port: FilterPortModel) => 
  {
    return (
      <FilterPortLabelWidget
        engine={this.props.engine}
        port={port}
        key={port.getID()}
      />
    );
  };

  /**
   * Generate a filter port.
   *
   * @param {ClassPortModel} port The port.
   * @returns {JSX.Element} A ClassPort component instance.
   */
  generateClassPort = (port: ClassPortModel) => 
  {
    return (
      <ClassPortLabelWidget
        engine={this.props.engine}
        port={port}
        key={port.getID()}
      />
    );
  };

  /**
   * Render the component.
   *
   * @returns {JSX.Element} The component instance.
   */
  render()
  {
    return (
      <S.Node
        data-default-node-name={this.props.node.getOptions().name}
        selected={this.props.node.isSelected()}
        background={this.props.node.getOptions().color}
      >
        <S.Ports>
          <S.PortsContainer>
            {_.map(this.props.node.getInClassPorts(), this.generateClassPort)}
          </S.PortsContainer>
        </S.Ports>
        <S.Title background={this.props.node.getOptions().color}>
          <S.TitleName>{this.props.node.getOptions().name}</S.TitleName>
        </S.Title>
        <S.Ports>
          <S.PortsContainer>
            {_.map(this.props.node.getFilterPorts(), this.generateFilterPort)}
          </S.PortsContainer>
        </S.Ports>
        <S.Ports>
          <S.PortsContainer>
            {_.map(this.props.node.getPropertyPorts(), this.generatePort)}
          </S.PortsContainer>
        </S.Ports>
        <S.Ports>
          <S.PortsContainer>
            {_.map(this.props.node.getOutClassPorts(), this.generateClassPort)}
          </S.PortsContainer>
        </S.Ports>
      </S.Node>
    );
  }
}