/* eslint-disable @typescript-eslint/no-explicit-any */
import { CirclePlus, Trash2 } from "lucide-react";
import React, { FunctionComponent, useContext } from "react";
import { Button } from "./ui/button";
import QGContext from "../QGContext";
import { useDeleteValidationSet } from "../api/rest/useDeleteValidationSet";
import { useGetValidationSets } from "../api/rest/useGetValidationSets";
import { useNavigate } from "react-router-dom";
import { usePostValidationSet } from "../api/rest/usePostValidationSet";
import { useTranslation } from "react-i18next";

/**
 * The project cards component.
 *
 * @returns {JSX.Element} - The element
 */
const ValidationSets: FunctionComponent = (): JSX.Element => 
{
  const ctx = useContext(QGContext);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data: validationSets, isLoading } = useGetValidationSets(
    ctx.project.id,
  );

  const creator = usePostValidationSet(ctx.project.id);
  const remover = useDeleteValidationSet(ctx.project.id);

  if (isLoading)
  {
    return <div>Loading...</div>;
  }

  const handleAdd = () => 
  {
    const msg = t("ui.prompt.validationSet");
    const validationSetName = prompt(msg);
    if (!validationSetName)
    {
      return;
    }

    creator.mutate(validationSetName, {
      onSuccess: (data) => 
      {
        navigateToValidationSet(data.id);
      },
    });
  };

  const handleRemove = (validationSetId: string) => 
  {
    const confirmMsg = t("ui.prompt.areyousure");
    if (!window.confirm(confirmMsg))
    {
      return;
    }
    remover.mutate(validationSetId);
  };

  const navigateToValidationSet = (validationSetId: string) => 
  {
    navigate(`/visual-designer/${validationSetId}`);
  };

  return (
    <div className="container pt-8 text-white">
      <div className="flex pb-12">
        <div className="pr-16">
          <h1 className="flex-grow text-2xl font-semibold">
            Validation Sets{/* TODO translate */}
          </h1>
          <div className="text-gray-500 pt-4">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam.
          </div>
        </div>
        <Button onClick={handleAdd}>
          <CirclePlus className="mr-2 h-4 w-4" />
          Create new Validation Set{/* TODO translate */}
        </Button>
      </div>
      <table className="min-w-[900px] text-left">
        <thead className="text-gray-500">
          {/* TODO translate */}
          <th>Name</th>
          <th>Status</th>
          <th className="text-center">Actions</th>
        </thead>
        <tbody>
          {validationSets?.map((validationSet: any) => (
            <tr key={validationSet.id} className="border-b-2 border-gray-700">
              <td
                className="cursor-pointer"
                onClick={() => navigateToValidationSet(validationSet.id)}
              >
                {validationSet.name}
              </td>
              <td
                className="cursor-pointer"
                onClick={() => navigateToValidationSet(validationSet.id)}
              >
                {(() => 
                {
                  switch (validationSet.status)
                  {
                  case "ExcelFileSuccessfullyImported":
                    return "Ready";
                  case "NoExcelUpload":
                    return "Excel file not yet uploaded";
                  case "NoValidExcelFormat":
                    return "Uploaded file not valid";
                  }
                })()}
              </td>
              <td className="text-center">
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={() => handleRemove(validationSet.id)}
                >
                  <Trash2 className="h-4 w-4" />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ValidationSets;