import { Box, CircularProgress } from "@mui/material";
import { CirclePlus, EditIcon, Trash } from "lucide-react";
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import { Button } from "./ui/button";
import ProjectMemberDialog from "../ui/dialog/ProjectMembersDialog";
import QGContext from "../QGContext";
import { User } from "../core/User";
import createDOMPurify from "dompurify";
import { enqueueSnackbar } from "notistack";
import { formatDate } from "../support/DateTime";
import { useDeleteProjectMember } from "../api/rest/useDeleteProjectMember";
import { useGetProjectMembers } from "../api/rest/useGetProjectMembers";
import { useGetUserByEmailAddress } from "../api/rest/useGetUserByEmailAddress";
import { usePostProjectMember } from "../api/rest/usePostProjectMember";
import { useTranslation } from "react-i18next";
import "./ProjectMembers.scss";

/**
 * The project member component.
 *
 * @returns {JSX.Element} - The element
 */
const ProjectMembers: FunctionComponent = (): JSX.Element => 
{
  const { t } = useTranslation();
  const ctx = useContext(QGContext);

  const [currentlyEditedUserId, setCurrentlyEditedUserId] = useState<
    string | null | undefined
  >(null);
  const [email, setEmail] = useState("");
  const [expirationDate, setExpirationDate] = useState<string | undefined>();

  const deleteProjectMember = useDeleteProjectMember(ctx.project.id);
  const postProjectMember = usePostProjectMember(ctx.project.id);

  const {
    isLoading: isLoadingUser,
    data: user,
    error: getUserByEmailError,
  } = useGetUserByEmailAddress(email);

  const { isLoading: isLoadingProjectMembers, data: projectMembers }
    = useGetProjectMembers(ctx.project.id);

  const DOMPurify = createDOMPurify(window);
  const description = t("ui.projectmembers.description");

  const removeProjectMember = (userId: string) => 
  {
    if (
      projectMembers.find((x: User) => x.id === userId).roles[0].name
      === "Owner"
    )
    {
      alert(t("ui.projectmembers.cantmodifyowner"));

      return;
    }

    const isConfirmed = window.confirm(t("ui.prompt.areyousure") as string);

    if (!isConfirmed)
    {
      return;
    }

    deleteProjectMember.mutate(userId);
  };

  useEffect(() => 
  {
    if (
      getUserByEmailError?.message === "Request failed with status code 404"
    )
    {
      // TODO translate
      enqueueSnackbar(t("No user with this email adress found."), {
        variant: "error",
      });
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUserByEmailError]);

  useEffect(() => 
  {
    if (!user)
    {
      return;
    }

    postProjectMember.mutate({
      userId: user.id,
      roleId: "",
      expirationDate: expirationDate,
    });

    setEmail("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleCreateProjectMember = async (
    email: string,
    expirationDate: string,
  ) => 
  {
    setEmail(email);
    setExpirationDate(expirationDate);
    setCurrentlyEditedUserId(null);
  };

  const handleUpdateProjectMember = async (
    userId: string,
    expirationDate: string,
  ) => 
  {
    postProjectMember.mutate({
      userId: userId,
      roleId: "",
      expirationDate: expirationDate,
    });

    setCurrentlyEditedUserId(null);
  };

  if (
    isLoadingProjectMembers
    || isLoadingUser
    || deleteProjectMember.isPending
    || postProjectMember.isPending
  )
  {
    return (
      <div>
        <Box>
          <CircularProgress className="spinner" />
        </Box>
      </div>
    );
  }

  return (
    <React.Fragment>
      <div className="post">
        <div>
          <h1 id="title">{t("ui.projectmembers.heading")}</h1>
          <div
            id="description"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(description),
            }}
          />
          <div className="content">
            <div className="action-bar">
              <Button onClick={() => setCurrentlyEditedUserId(undefined)}>
                <CirclePlus className="mr-2 h-4 w-4" />
                {t("ui.projectmembers.adduser")}
              </Button>
            </div>
            <table>
              <thead>
                <tr>
                  <th>{t("ui.user.account")}</th>
                  <th>{t("ui.user.maxrole")}</th>
                  <th>{t("ui.user.expiration")}</th>
                  <th>{t("ui.user.addedAt")}</th>
                  <th>{t("ui.user.lastactivity")}</th>
                  <th>{t("ui.user.actions")}</th>
                </tr>
              </thead>
              <tbody>
                {projectMembers.map((user: User) => (
                  <tr key={user.id} data-id={user.id}>
                    <td className="user-account">
                      <div className="user-image">
                        <img
                          src={user.imageData}
                          alt={`${user.firstName} ${user.lastName}`}
                        ></img>
                      </div>
                      <div className="user-information">
                        <span className="user-name">{`${user.firstName} ${user.lastName}`}</span>
                        {user.id === ctx.user.id && (
                          <span className="user-itsyou">
                            {t("ui.projectmembers.itsyou")}
                          </span>
                        )}
                        <span className="user-username">@{user.userName}</span>
                      </div>
                    </td>
                    <td className="user-maxrole">
                      <span>{user.roles[0].name}</span>
                    </td>
                    <td className="user-expiration">
                      {formatDate(user.expiration)}
                    </td>
                    <td className="user-created">{formatDate(user.created)}</td>
                    <td className="user-last-activity">
                      {formatDate(user.lastActivity)}
                    </td>
                    <td className="user-actions">
                      {user.roles[0].name !== "Owner" && (
                        <>
                          <Button
                            className="rounded-full bg-transparent hover:bg-transparent"
                            onClick={() => removeProjectMember(user.id)}
                          >
                            <Trash className="text-white" />
                          </Button>
                          <Button
                            className="rounded-full bg-transparent hover:bg-transparent"
                            onClick={() => setCurrentlyEditedUserId(user.id)}
                          >
                            <EditIcon className="text-white" />
                          </Button>
                        </>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {currentlyEditedUserId !== null && (
        <ProjectMemberDialog
          isVisible={currentlyEditedUserId !== null}
          user={projectMembers.find(
            (user: User) => user.id === currentlyEditedUserId,
          )}
          onCreatePojectMember={handleCreateProjectMember}
          onUpdateProjectMember={handleUpdateProjectMember}
          onCancel={() => setCurrentlyEditedUserId(null)}
        />
      )}
    </React.Fragment>
  );
};

export default ProjectMembers;