import * as axios from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import QGContext from "../../QGContext";
import { useContext } from "react";

/**
 * Posts a new project member to the Quality Gate API
 *
 * @param {string} projectId The Project Id
 * @returns {Promise<any>} The project member from the Quality Gate API
 */
export const usePostProjectMember = (projectId: string) => 
{
  const ctx = useContext(QGContext);
  const queryClient = useQueryClient();

  return useMutation({
    // TODO typing
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    mutationFn: async (args: any) => 
    {
      const response = await axios.default.post(
        `/qualitygate/v1/project/${projectId}/members`,
        `userId=${args.userId}&roleId=${args.roleId}&expirationDate=${args.expirationDate}`,
        {
          headers: {
            userToken: ctx.tokens.idToken,
            accessToken: ctx.tokens.accessToken,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        },
      );
      return response.data;
    },
    // note that returning the promise keeps the loading state activated)
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ["getProjectMembers", projectId],
      }),
  });
};