import { useEffect, useState } from "react";

type ValidationFunction = (value: string) => string[];
/**
 * Validate input fields when creating or updating the project
 *
 * @param {string} value - The model ID
 * @param {Function} validate - a function to validate the name
 * @returns {Array<string>} - The array of string, which are errors, if there is any
 */
export const useValidation = (
  value: string,
  validate: ValidationFunction,
): string[] => 
{
  const [errors, setErrors] = useState<string[]>([]);

  useEffect(() => 
  {
    const validationErrors = validate(value);
    setErrors(validationErrors);
  }, [value, validate]);

  return errors;
};

/**
 * ValidateName is a function that validates the input
 *
 * @param {string} name - a parameter
 * @returns {Array<string>} - returns an array of string
 */
export const validateName = (name: string): string[] => 
{
  const errors: string[] = [];

  if (name.length < 3)
  {
    errors.push("Name must be at least 3 characters long");
  }

  return errors;
};