import {
  AbstractModelFactory,
  DeserializeEvent,
} from "@projectstorm/react-canvas-core";
import {
  LinkModel,
  PortModel,
  PortModelAlignment,
  PortModelGenerics,
  PortModelOptions,
} from "@projectstorm/react-diagrams-core";
import { DefaultLinkModel } from "@projectstorm/react-diagrams-defaults";

/**
 * Interface for the custom port arguments.
 *
 * @interface
 * @augments {PortModelOptions}
 */
export interface CustomPortModelOptions extends PortModelOptions
{
  /**
   * The label.
   *
   * @property {string} label The label.
   */
  label?: string;

  /**
   * A value indicating whether the port has an input possibility.
   *
   * @property {boolean} in A value indicating whether the port has an input possibility.
   */
  in?: boolean;

  /**
   * The type.
   *
   * @property {string} type The type.
   */
  type?: string;
}

/**
 * Interface for the custom port generics arguments.
 *
 * @interface
 * @augments {PortModelGenerics}
 */
export interface CustomPortModelGenerics extends PortModelGenerics
{
  /**
   * The options.
   *
   * @property {CustomPortModelOptions} OPTIONS The options.
   */
  OPTIONS: CustomPortModelOptions;
}

/**
 * The custom port label.
 *
 * @class
 * @augments {PortModel<CustomPortModelGenerics>}
 */
export class CustomPortModel extends PortModel<CustomPortModelGenerics>
{
  /**
   * The options.
   *
   * @property {CustomPortModelOptions} options The options.
   */
  options: CustomPortModelOptions;

  /**
   * Create an instance of CustomPortModel.
   *
   * @param {boolean} isIn A value indicating whether the port should be an in port.
   * @param {string} name The name.
   * @param {string} label The label.
   */
  constructor(isIn: boolean, name?: string, label?: string);

  /**
   * Create an instance of CustomPortModel.
   *
   * @param {CustomPortModelOptions} options The options.
   */
  constructor(options: CustomPortModelOptions);

  /**
   * Create an instance of CustomPortModel.
   *
   * @param {CustomPortModelOptions | boolean} options The options.
   * @param {string} name The name.
   * @param {string} label The label.
   */
  constructor(
    options: CustomPortModelOptions | boolean,
    name?: string,
    label?: string,
  )
  {
    // check if name has an value
    if (name)
    {
      options = {
        in: !!options,
        name: name,
        label: label,
      };
    }

    options = options as CustomPortModelOptions;

    super({
      label: options.label || options.name,
      alignment: PortModelAlignment.LEFT,
      type: "custom-port",
      ...options,
    });

    this.options = options;
  }

  /**
   * Deserialize the model.
   *
   * @param {DeserializeEvent<this>} event The deserialize event.
   * @returns {void}
   */
  deserialize(event: DeserializeEvent<this>)
  {
    super.deserialize(event);
    this.options.in = event.data.in;
    this.options.label = event.data.label;
  }

  /**
   * Serialize the model.
   *
   * @returns {any} The serialized model.
   */
  serialize()
  {
    return {
      ...super.serialize(),
      in: this.options.in,
      label: this.options.label,
    };
  }

  /**
   * Link to this port.
   *
   * @template {LinkModel} T The link model type.
   * @param {PortModel} port The port.
   * @param {AbstractModelFactory<T>} factory The factory.
   * @returns {T} The link model.
   */
  link<T extends LinkModel>(
    port: PortModel,
    factory?: AbstractModelFactory<T>,
  ): T
  {
    const link = this.createLinkModel(factory);

    link.setSourcePort(this);
    link.setTargetPort(port);

    return link as T;
  }

  /**
   * Check if the passed port can link to this port.
   *
   * @param {PortModel} port The port.
   * @returns {boolean} A value indicating whether a link is possible.
   */
  canLinkToPort(port: PortModel): boolean
  {
    if (port instanceof CustomPortModel)
    {
      return this.options.in !== port.getOptions().in;
    }

    return true;
  }

  /**
   * Create the link model.
   *
   * @param {AbstractModelFactory<LinkModel>} factory The factory.
   * @returns {LinkModel} The link model.
   */
  createLinkModel(factory?: AbstractModelFactory<LinkModel>): LinkModel
  {
    const link = super.createLinkModel();

    if (!link && factory)
    {
      return factory.generateModel({});
    }

    return link || new DefaultLinkModel();
  }
}