import * as axios from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import QGContext from "../../QGContext";
import { useContext } from "react";

/**
 * Posts a new validation set to the Quality Gate API
 *
 * @param {string} projectId The Project Id
 * @returns {Promise<any>} The result from the Quality Gate API
 */
export const usePostValidationSet = (projectId: string) => 
{
  const ctx = useContext(QGContext);
  const queryClient = useQueryClient();

  return useMutation({
    // TODO typing
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    mutationFn: async (name: string) => 
    {
      // TODO PUT => POST
      const response = await axios.default.put(
        `/qualitygate/v1/validationSets/${projectId}`,
        `name=${name}&projectId=${projectId}`, // TODO why projectId two times?
        {
          headers: {
            userToken: ctx.tokens.idToken,
            accessToken: ctx.tokens.accessToken,
          },
        },
      );
      return response.data;
    },
    // note that returning the promise keeps the loading state activated)
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ["getValidationSets", projectId],
      }),
  });
};