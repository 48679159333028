import { ApolloClient, InMemoryCache, gql } from "@apollo/client";
import { Claims, CognitoTokens } from "./support/authentication";
import QGContext, { AppContext, defaultContext } from "./QGContext";
import React, { FunctionComponent, useEffect, useState } from "react";
import { generateTokens, getUser, isAuthenticated } from "./api/graphql/signIn";
import { AccUser } from "./components/SignIn.types";
import { CONNECT_GRAPHQL_ENDPOINT } from "./environment";
import { I18nextProvider } from "react-i18next";
import RootLayout from "./components/RootLayout";
import { Skeleton } from "@mui/material";
import { defaultNS } from "./setup/i18n-namespace";
import i18n from "./setup/i18n";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import "./App.scss";

/**
 * The app prop interface.
 *
 * @interface
 */
interface AppProps
{
  tokens: CognitoTokens;
  claims: Claims;
}

/**
 * The app component.
 *
 * @param {AppProps} props The app props.
 * @returns {JSX.Element} The app element.
 */
const App: FunctionComponent<AppProps> = (props: AppProps): JSX.Element => 
{
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams, setSearchParams] = useSearchParams();

  const defaultAppContext: AppContext = {
    ...defaultContext,
    user: {
      id: props.claims.sub,
      email: props.claims.email,
      firstName: props.claims.given_name,
      lastName: props.claims.family_name,
    },
    tokens: props.tokens,
    setProject: (project) =>
      setContext((ctx: AppContext) => 
      {
        if (!project.id)
        {
          window.localStorage.removeItem("pid");
        }
        else 
        {
          window.localStorage.setItem("pid", project.id);
        }

        return { ...ctx, project };
      }),
    setAccAuthentication: (
      isAuthenticated: boolean,
      user?: { id: string; name: string } | undefined,
    ) =>
      setContext((ctx: AppContext) => 
      {
        return {
          ...ctx,
          acc: { isAuthenticated, userId: user?.id, userName: user?.name },
        };
      }),
  };

  const [context, setContext] = useState<AppContext>(defaultAppContext);

  useEffect(() => 
  {
    (async () => 
    {
      const connectClient = new ApolloClient({
        uri: CONNECT_GRAPHQL_ENDPOINT,
        cache: new InMemoryCache(),
      });

      // ToDO: error handling snackbar
      const result = await connectClient.query({
        query: gql`
          query Provider($providerName: String) {
            getProviderByName(name: $providerName) {
              endpoint
            }
          }
        `,
        variables: {
          providerName: "acc",
        },
        fetchPolicy: "cache-first",
        context: {
          headers: {
            accessToken: props.tokens.accessToken,
          },
        },
      });

      const endpoint = result?.data?.getProviderByName?.endpoint;
      const accClient = new ApolloClient({
        uri: endpoint,
        cache: new InMemoryCache(),
      });

      if (!endpoint || !accClient)
      {
        // TODO toast (?)
        return;
      }

      setContext((prevContext: AppContext) => 
      {
        return {
          ...prevContext,
          accClient,
        };
      });

      if (searchParams.has("code"))
      {
        const successfullyGeneratedTokens = await generateTokens(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          searchParams.get("code")!,
          accClient,
          props.tokens.accessToken,
        );

        if (!successfullyGeneratedTokens)
        {
          enqueueSnackbar(
            "An error occured during ACC login (storeAuthenticationInfo=false). Please retry.",
            {
              variant: "error",
            },
          );
          context.setAccAuthentication(false);
          return;
        }

        let user: AccUser | undefined;
        try 
        {
          user = await getUser(props.tokens.accessToken, accClient);
        }
        catch 
        {
          enqueueSnackbar(
            "An error occured during ACC login (user not found). Please retry.",
            {
              variant: "error",
            },
          );
          context.setAccAuthentication(false);
          return;
        }

        context.setAccAuthentication(true, {
          id: user.userId,
          name: user.userName,
        });

        setSearchParams(new URLSearchParams(), { replace: true });
      }
      else 
      {
        try 
        {
          let user: AccUser | undefined;
          const authenticated = await isAuthenticated(
            props.tokens.accessToken,
            accClient,
          );
          if (authenticated)
          {
            try 
            {
              user = await getUser(props.tokens.accessToken, accClient);
            }
            catch 
            {
              enqueueSnackbar(
                "An error occured: ACC user not found. Please retry.",
                {
                  variant: "error",
                },
              );
            }
          }
          context.setAccAuthentication(
            authenticated && Boolean(user),
            user ? { id: user.userId, name: user.userName } : undefined,
          );
        }
        catch 
        {
          context.setAccAuthentication(false);
          // TODO toast
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => 
  {
    const pid = window.localStorage.getItem("pid");
    if (!pid || pid.length !== 36)
    {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (context.accClient === undefined)
  {
    return (
      <Skeleton
        variant="circular"
        sx={{
          position: "absolute",
          top: "calc(50% - 4em)",
          left: "calc(50% - 4em)",
          width: "6em",
          height: "6em",
          border: "1.1em solid var(--back-color)",
          borderLeft: "1.1em solid var(--front-color)",
          borderRight: "1.1em solid var(--front-color)",
          borderRadius: "50%",
          animation: "loading-animation 1.1s infinite linear",
        }}
      />
    );
  }

  return (
    <div className="dark Hero">
      <QGContext.Provider value={context}>
        <I18nextProvider i18n={i18n} defaultNS={defaultNS}>
          <RootLayout />
        </I18nextProvider>
      </QGContext.Provider>
    </div>
  );
};

export default App;