/* eslint-disable @typescript-eslint/no-explicit-any */
import * as axios from "axios";
import React, {
  ChangeEvent,
  FunctionComponent,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import QGContext from "../QGContext";
import ValidationSetDiagram from "./ValidationSetDiagram";
import ValidationSetUploadDialog from "./ValidationSetUploadDialog";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import "./ValidationSetContainer.scss";

/**
 * The validation set container.
 *
 * @returns {JSX.Element} The validation set container.
 */
const ValidationSetContainer: FunctionComponent = (): JSX.Element | null => 
{
  const { id: validationSetId } = useParams();
  const { t } = useTranslation();
  const {
    tokens,
    project: { id: projectId },
  } = useContext(QGContext);
  const { enqueueSnackbar } = useSnackbar();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [validationSet, setValidationSet] = useState<any | undefined>();
  const [isUploadDialogVisible, setIsUploadDialogVisible] = useState(false);
  const [isExcelUploadFinished, setIsExcelUploadFinished] = useState(false);
  const inputTypeFile = useRef<HTMLInputElement>(null);

  const loadValidationSet = async () => 
  {
    const response = await axios.default.get(
      `/qualitygate/v1/validationSet/${validationSetId}`,
      {
        headers: {
          userToken: tokens.idToken,
          accessToken: tokens.accessToken,
        },
      },
    );
    setValidationSet(response.data);
    return response.data.status;
  };

  // wenn id sich ändert => laden
  // nach dem upload => laden

  // validationSetId changed in URL => load validation set
  useEffect(() => 
  {
    setValidationSet(undefined);
    loadValidationSet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationSetId]);

  // file uploaded => reload validation set
  useEffect(() => 
  {
    if (isExcelUploadFinished)
    {
      setIsExcelUploadFinished(false);

      // reload validation set until status changed
      // TODO instead of an interval, use sequential setTimeouts , otherwise race conditions might occur when loading
      // takes more time than the interval
      let numberOfReloads = 0;
      const intervalId = window.setInterval(async () => 
      {
        numberOfReloads++;
        if (numberOfReloads < 4)
        {
          const status = await loadValidationSet();
          if (status !== "ExcelUpload")
          {
            window.clearInterval(intervalId);
          }
        }
        else 
        {
          // avoid endless reloading
          window.clearInterval(intervalId);
          // mark as invalid and trigger reload
          await postStatus("NoValidExcelFormat");
          loadValidationSet();
        }
      }, 2500);
    }
    // we are intentionally not adding postStatus() and loadValidationSet() to dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExcelUploadFinished]);

  const postStatus = (status: "ExcelUpload" | "NoValidExcelFormat") =>
    axios.default.post(
      `/qualitygate/v1/validationSet/${validationSetId}/status`,
      `status=${status === "ExcelUpload" ? 1 : 10}&projectId=${projectId}`,
      {
        headers: {
          userToken: tokens.idToken,
          accessToken: tokens.accessToken,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      },
    );

  const handleUploadFileButtonClick = () => 
  {
    if (!inputTypeFile.current)
    {
      return;
    }
    setIsUploadDialogVisible(false);
    inputTypeFile.current.click();
  };

  const handleInputTypeFileChange = async (
    event: ChangeEvent<HTMLInputElement>,
  ) => 
  {
    const { files } = event.target;
    if (!files || !files.length || files[0].name.split(".").pop() !== "xlsx")
    {
      enqueueSnackbar("Please upload a valid Excel file.", {
        variant: "error",
      });
      return;
    }

    setValidationSet(undefined); // will be reloaded after upload

    const file = files[0];

    try 
    {
      // get a presigned URL
      const presignedUrl = await axios.default.get(
        `/qualitygate/v1/presignedValidationSet/${projectId}`,
        {
          headers: {
            userToken: tokens.idToken,
            accessToken: tokens.accessToken,
          },
        },
      );

      // upload the file to the presigned URL
      await axios.default.put(presignedUrl.data.presignedUrl, file, {
        headers: {
          "Content-Type": "application/octet-stream",
        },
      });

      // Triggers creation of an item in ddb, table "files".
      // Also creates an event of type "fileUploaded", but as the triggered ifc conversion lambda ignores files that are not ".ifc" this does not have any effect.
      await axios.default.post(
        "/qualitygate/v1/fileupload",
        `fileId=${presignedUrl.data.fileName}&fileName=${file.name}&fileSize=${file.size}&projectId=${projectId}`,
        {
          headers: {
            userToken: tokens.idToken,
            accessToken: tokens.accessToken,
          },
        },
      );

      // update the status
      await postStatus("ExcelUpload");

      // trigger the event "importValidationSetFromExcel" that transforms the excel file content into a validation set
      await axios.default.post(
        "/qualitygate/v1/trigger/importValidationSetFromExcel",
        JSON.stringify({
          FileId: presignedUrl.data.fileName,
          ValidationSetId: validationSetId,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            userToken: tokens.idToken,
            accessToken: tokens.accessToken,
          },
        },
      );
    }
    catch (
      error: any // eslint-disable-line @typescript-eslint/no-explicit-any
    )
    {
      if (error.response?.status === 403)
      {
        enqueueSnackbar("You are not allowed to upload files.", {
          variant: "error",
        });
      }
      else 
      {
        enqueueSnackbar("Sorry, an error occured during upload.", {
          variant: "error",
        });
      }
    }
    finally 
    {
      // we're calling the function onchange/oninput. If you upload the same file the value of the file input HAS NOT changed from the previous upload and therefore isn't triggered. This also explains why it works if we upload a different file. =>  we can work around this by resetting the input field's value after you read the file.
      (event.target.value as unknown) = null;

      // trigger reload of ValidationSet
      setIsExcelUploadFinished(true);
    }
  };

  if (validationSet === undefined || validationSet.status === "ExcelUpload")
  {
    return <CircularProgress className="spinner" />;
  }

  return (
    <>
      {(() => 
      {
        switch (validationSet.status)
        {
        case "ExcelFileSuccessfullyImported":
          return (
            <ValidationSetDiagram definition={validationSet.definition} />
          );
        case "NoExcelUpload":
          return (
            <div className="placeholder-mask">
              <div className="placeholder">
                <div className="placeholder-content">
                  {t("ui.validationset.empty")}
                </div>
                <FontAwesomeIcon icon={["far", "file-excel"]} size="3x" />
                <i className="chevron left-down accent placeholder-direction"></i>
              </div>
            </div>
          );
        case "NoValidExcelFormat":
          return (
            <div className="placeholder-mask">
              <Alert severity="error">The uploaded file is not valid.</Alert>
            </div>
          );
        }
      })()}

      <div id="visual-designer-toolbar">
        <input
          type="file"
          id="excel-definition-file"
          accept=".xlsx"
          style={{ display: "none" }}
          ref={inputTypeFile}
          onChange={handleInputTypeFileChange}
        />
        <Button
          variant="outlined"
          title={t("ui.validationset.addexceltooltip") as string}
          onClick={() => setIsUploadDialogVisible(true)}
        >
          <FontAwesomeIcon icon={["far", "file-excel"]} />
        </Button>
      </div>
      {isUploadDialogVisible && (
        <ValidationSetUploadDialog
          isVisible={isUploadDialogVisible}
          onUploadFileButtonClick={handleUploadFileButtonClick}
          onCancel={() => setIsUploadDialogVisible(false)}
        />
      )}
    </>
  );
};

export default ValidationSetContainer;