import * as axios from "axios";
import * as dialog from "../ui/dialog/Dialog";
import { Box, CircularProgress } from "@mui/material";
import QGContext, { AppContext } from "../QGContext";
import { WithTranslation, withTranslation } from "react-i18next";
import { Button } from "./ui/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Search } from "lucide-react";
import { WithRouter } from "../interfaces/WithRouter";
import createDOMPurify from "dompurify";
import { formatDate } from "../support/DateTime";
import { renderToString } from "react-dom/server";
import { withRouter } from "../support/withRouter";
import "./ValidationResults.scss";

/**
 * Interface for the validation results state.
 *
 * @interface
 */
interface ValidationResultsState
{
  /**
   * The history list.
   *
   * @property {any[]} historyList The history list.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  historyList: any[];

  /**
   * A value indicating whether the model part of the page is loading.
   *
   * @property {boolean} isLoading A value indicating whether the model part of the page is loading.
   */
  isLoading: boolean;

  /**
   * A value indicating whether the history part of the page is loading.
   *
   * @property {boolean} isLoadingHistory A value indicating whether the history part of the page is loading.
   */
  isLoadingHistory: boolean;

  /**
   * A value indicating whether the detailed log is loading.
   *
   * @property {boolean} isLoadingDetailedLog A value indicating whether the detailed log is loading.
   */
  isLoadingDetailedLog: boolean;

  /**
   * A value indicating whether a detailed log dialog should be shown.
   *
   * @property {boolean} showDetailedLog A value indicating whether a detailed log dialog should be shown.
   */
  showDetailedLog: boolean;

  /**
   * Holds the detailed log data.
   *
   * @property {string} detailedLogData Holds the detailed log data.
   */
  detailedLogData: string;
}

/**
 * Interface for the validation results arguments.
 *
 * @interface
 */
interface ValidationResultArguments extends WithTranslation, WithRouter
{}

/**
 * The Validation Results component.
 *
 * @class
 * @augments {React.Component<WithTranslation, ValidationResultsState>}
 */
class ValidationResults extends React.Component<
  ValidationResultArguments,
  ValidationResultsState
>
{
  static contextType = QGContext;

  /**
   * The history list.
   *
   * @property {any[]} historyList The history list.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  historyList: any[];

  /**
   * Create an instance of ValidationResults.
   *
   * @param {ValidationResultArguments} props The properties.
   */
  constructor(props: ValidationResultArguments)
  {
    super(props);

    this.historyList = [];

    this.state = {
      historyList: this.historyList,
      isLoading: true,
      isLoadingHistory: true,
      showDetailedLog: false,
      isLoadingDetailedLog: false,
      detailedLogData: "",
    };

    this.showDetailedLog = this.showDetailedLog.bind(this);
    this.fillDetailedLogContent = this.fillDetailedLogContent.bind(this);
  }

  /**
   * Sets up the component after it has been mounted.
   *
   * @returns {void}
   */
  componentDidMount()
  {
    const ctx = this.context as AppContext;

    axios.default
      .get(`/qualitygate/v1/validationResults/${ctx.project.id}`, {
        headers: {
          userToken: ctx.tokens.idToken,
          accessToken: ctx.tokens.accessToken,
        },
      })
      .then((response) => 
      {
        this.setState({
          historyList: this.generateHistoryList(null, response.data),
          isLoadingHistory: false,
          isLoading: false,
        });
      });
  }

  /**
   * Generate a history list.
   *
   * @param {any[] | null} historyList The history list.
   * @param {any | any[]} responseData The API response data.
   * @returns {any[]} The history list.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  generateHistoryList(historyList: any[] | null, responseData: any | any[])
  {
    if (historyList === null)
    {
      historyList = [];
    }

    let validationResults = [];

    if (!Array.isArray(responseData))
    {
      validationResults.push(responseData);
    }
    else 
    {
      validationResults = responseData;
    }

    for (const validationResult of validationResults)
    {
      let log = "";
      switch (validationResult.result)
      {
      case "ProcessingError":
        log = "An error occurred while processing the validation.";
        break;
      case "ProcessedAsValid":
        log = `All ${validationResult.resultListItemCount} rules have been validated successfully.`;
        break;
      case "ProcessedAsInvalid":
        log = `${validationResult.resultListInvalidItemCount} rules out of ${validationResult.resultListItemCount} failed.`;
      }

      historyList.push({
        id: validationResult.id,
        name: validationResult.modelName,
        uploadDate: validationResult.created,
        validationSet: validationResult.validationSetName,
        result: validationResult.result,
        log,
      });
    }

    return historyList;
  }

  /**
   * Add the click handler for toggle buttons to allow these to expand the details below.
   *
   * @returns {void}
   */
  addExpandBehaviour()
  {
    const toggleButtons = document.getElementsByClassName("toggle-details");

    if (toggleButtons === null || toggleButtons.length < 1)
    {
      setTimeout(this.addExpandBehaviour, 200);
    }

    if (toggleButtons !== null && toggleButtons.length > 0)
    {
      for (const button of Array.from(toggleButtons))
      {
        button.addEventListener("click", (event) => 
        {
          let htmlElement = event.target as HTMLElement;

          while (!htmlElement.classList.contains("validation-result-item"))
          {
            if (htmlElement.parentElement === null)
            {
              return;
            }

            htmlElement = htmlElement.parentElement as HTMLElement;
          }

          const validationData = htmlElement.querySelector(
            ".validation-data",
          ) as HTMLDivElement;

          validationData.ariaExpanded
            = validationData.ariaExpanded === "true" ? "false" : "true";
        });
      }
    }
  }

  /**
   * Fill the detailed log content.
   *
   * @param {string} validationId The validation ID.
   * @param {number} checkCount The count how often the DOM has been checked for the content element.
   * @returns {void}
   */
  fillDetailedLogContent(validationId: string, checkCount: number)
  {
    const detailedLogContent = document.getElementById("detailed-log-content");

    if (detailedLogContent === null && checkCount < 3)
    {
      setTimeout(this.fillDetailedLogContent, 200, validationId, ++checkCount);
    }

    if (detailedLogContent !== null)
    {
      this.setState({
        isLoadingDetailedLog: true,
        detailedLogData: "",
      });

      const ctx = this.context as AppContext;

      // TODO: apply useGetValidationResult hook here as soon as this got a functional component
      axios.default
        .get(`/qualitygate/v1/validationResult/${validationId}`, {
          headers: {
            userToken: ctx.tokens.idToken,
            accessToken: ctx.tokens.accessToken,
          },
        })
        .then((response) => 
        {
          let detailedLogData = "";

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          response.data.resultList.forEach((validation: any) => 
          {
            const resultDetails = validation.validationInformation.replaceAll(
              "\r\n",
              "<br />",
            );

            const logContent = (
              <div className="validation-result-item" data-id={validation.id}>
                <div className="meta-data">
                  <div
                    className="is-successful icon"
                    data-successful={validation.successful}
                  >
                    <FontAwesomeIcon
                      icon={validation.successful ? "check" : "xmark"}
                    />
                  </div>
                  <div className="name">{validation.name}</div>
                  <div className="actions">
                    <Button className="toggle-details">
                      <FontAwesomeIcon
                        icon={
                          validation.successful ? "chevron-up" : "chevron-down"
                        }
                      />
                    </Button>
                  </div>
                </div>
                <div
                  className="validation-data"
                  aria-expanded={validation.successful}
                >
                  <div
                    className="result-details"
                    dangerouslySetInnerHTML={{ __html: resultDetails }}
                  ></div>
                </div>
              </div>
            );

            if (!validation.Successful)
            {
              detailedLogData = renderToString(logContent) + detailedLogData;
            }
            else 
            {
              detailedLogData += renderToString(logContent);
            }
          });

          this.setState({
            isLoadingDetailedLog: false,
            detailedLogData,
          });

          this.addExpandBehaviour();
        });
    }
  }

  /**
   * Show detailed results for the validation.
   *
   * @param {React.MouseEvent<HTMLButtonElement>} event The click event arguments.
   * @returns {void}
   */
  showDetailedLog(event: React.MouseEvent<HTMLButtonElement>)
  {
    console.debug("[ValidationResults] show detailed log dialog", {
      state: this.state,
    });

    let htmlElement = event.target as HTMLElement;

    while (htmlElement.tagName.toLowerCase() !== "tr")
    {
      if (htmlElement.parentElement === null)
      {
        return;
      }

      htmlElement = htmlElement.parentElement as HTMLElement;
    }

    const validationId = htmlElement.getAttribute("data-id") ?? "";

    if (validationId !== null && validationId !== "")
    {
      this.setState({
        showDetailedLog: true,
      });

      setTimeout(this.fillDetailedLogContent, 200, validationId, 0);
    }
  }

  /**
   * Render the component.
   *
   * @returns {JSX.Element} The component instance.
   */
  render()
  {
    const { t } = this.props;
    const DOMPurify = createDOMPurify(window);

    const description = t("ui.validationresults.description");

    const historyEntries: JSX.Element[] = [];
    const toggleDetailedLocalization = t(
      "ui.validationresults.toggledetailedrecord",
    );

    if (this.state.historyList !== null && this.state.historyList.length > 0)
    {
      for (const element of this.state.historyList)
      {
        const historyEntry = element;

        historyEntries.push(
          <tr
            key={historyEntry.id}
            data-id={historyEntry.id}
            data-highlight={
              historyEntry.id === window.location.hash.substring(1)
            }
          >
            <td className="history-entry-name">
              <FontAwesomeIcon icon="pen-ruler" />
              <span className="history-entry-name-label">
                {historyEntry.name}
              </span>
            </td>
            <td className="history-entry-upload-date">
              {formatDate(historyEntry.uploadDate)}
            </td>
            <td className="history-entry-status">
              <FontAwesomeIcon
                icon={
                  historyEntry.result === "ProcessedAsValid" ? "check" : "xmark"
                }
              />
              <span className="validation-set-name">
                {historyEntry.validationSet}
              </span>{" "}
              <br />
              {historyEntry.log}
            </td>
            <td className="history-entry-actions">
              {historyEntry.result !== "ProcessingError" && (
                <Button
                  title={toggleDetailedLocalization}
                  className="rounded-full bg-transparent hover:bg-transparent"
                  onClick={this.showDetailedLog}
                >
                  <Search className="text-white" />
                </Button>
              )}
            </td>
          </tr>,
        );
      }
    }

    if (this.state.isLoading)
    {
      return (
        <div>
          <Box>
            <CircularProgress className="spinner" />
          </Box>
        </div>
      );
    }

    return (
      <div id="container">
        <h1 id="title">{t("ui.validationresults.heading")}</h1>
        <div
          id="description"
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }}
        />
        <table
          className="history-content"
          id="validation-result-history"
          data-skeleton={this.state.isLoadingHistory}
        >
          <thead>
            <tr>
              <th>{t("ui.validationresults.filename")}</th>
              <th>{t("ui.validationresults.uploaddate")}</th>
              <th>{t("ui.validationresults.status")}</th>
              <th>{t("ui.validationresults.actions")}</th>
            </tr>
          </thead>
          <tbody>
            {historyEntries != null
              && historyEntries.length > 0
              && historyEntries}
          </tbody>
        </table>
        {this.state.showDetailedLog && (
          <dialog.default
            header={t("ui.detailedlog.dialogheader")}
            visible={this.state.showDetailedLog}
            onHide={() => this.setState({ showDetailedLog: false })}
          >
            <h2>{t("ui.detailedlog.header")}</h2>
            <div
              id="detailed-log-content"
              aria-hidden="true"
              data-skeleton={this.state.isLoadingDetailedLog}
              dangerouslySetInnerHTML={{ __html: this.state.detailedLogData }}
            ></div>
          </dialog.default>
        )}
      </div>
    );
  }
}

export default withTranslation()(withRouter(ValidationResults));