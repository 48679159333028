/* eslint-disable @typescript-eslint/no-explicit-any */
import * as axios from "axios";
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import { Search, Trash } from "lucide-react";
import { getFileById, getFileS3Url } from "../api/graphql/getFileById";
import { AccBaseEntity } from "../core/AccBaseEntity";
import AccProjectExplorerView from "./AccProjectExplorerView";
import AgreeOrDenyDialog from "../ui/dialog/AgreeOrDenyDialog";
import Box from "@mui/material/Box";
import { Button } from "./ui/button";
import { Button as MuiButton } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { FileUploader } from "react-drag-drop-files";
import FolderIcon from "@mui/icons-material/Folder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "@mui/material/Modal";
import QGContext from "../QGContext";
import Typography from "@mui/material/Typography";
import ValidationArrangementDialog from "./ValidationArrangementDialog";
import createDOMPurify from "dompurify";
import { createDropAbility } from "../ui/behaviour/DragAndDrop";
import { formatDate } from "../support/DateTime";
import { getProjectById } from "../api/graphql/signIn";
import { useGetModels } from "../api/rest/useGetModels";
import { useGetValidationResult } from "../api/rest/useGetValidationResult";
import { useGetValidationSets } from "../api/rest/useGetValidationSets";
import { useNavigate } from "react-router";
import { useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import "./ValidationArrangement.scss";

/**
 * The validation arrangement functional component
 *
 * @returns {JSX.Element} - The element
 */
const ValidationArrangement: FunctionComponent = (): JSX.Element => 
{
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const ctx = useContext(QGContext);
  const queryClient = useQueryClient();
  const [accFolderViewIsOpen, setAccFolderViewIsOpen]
    = useState<boolean>(false);
  const [isUploadingModel, setIsUploadingModel] = useState<boolean>(false);
  const [isLoadingAccModel, setIsLoadingAccModel] = useState<boolean>(false);
  const [dialogModelFileId, setDialogModelFileId] = useState<
    string | undefined
  >();
  const [allModels, setAllModels] = useState<Array<any>>([]);
  const [validationInProgress, setValidationInProgress] = useState<
    | {
        validationId?: string;
        validationSetId: string;
        validationSetName: string;
        modelFileId: string;
      }
    | undefined
  >();

  const [deleteModelDialog, setDeleteModelDialogOpen] = useState({
    open: false,
    modelId: "",
  });

  const { data: validationSets } = useGetValidationSets(ctx.project.id);

  const { data: models, isLoading: isLoadingModels } = useGetModels(
    ctx.project.id,
  );
  const { data: validationResult } = useGetValidationResult(
    validationInProgress?.validationId,
    true,
  );

  useEffect(() => 
  {
    if (!models)
    {
      return;
    }

    setAllModels(models);
  }, [models]);

  useEffect(() => 
  {
    if (!validationResult)
    {
      return;
    }
    navigate(`/validation-results#${validationInProgress?.validationId}`);
    setValidationInProgress(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationResult]);

  /**
   * Handle model delete
   *
   * @param {string} modelId - The model ID
   * @returns {Promise<void>} - The promise
   */
  const handleModelDelete = async (modelId: string) => 
  {
    try 
    {
      const response = await axios.default.delete(
        `/qualitygate/v1/model/${modelId}`,
        {
          headers: {
            userToken: ctx.tokens.idToken,
            accessToken: ctx.tokens.accessToken,
          },
        },
      );

      if (response.status === 200 && (response.data as boolean))
      {
        await queryClient.invalidateQueries({
          queryKey: ["getModels", ctx.project.id],
        });
      }
    }
    catch (e)
    {
      enqueueSnackbar(t("ui.validationarrangement.modeldeleteerror"), {
        variant: "error",
      });
    }
  };

  const handleUploadFile = async (files: File[]) => 
  {
    setIsUploadingModel(true);

    for (const file of files)
    {
      try 
      {
        const presignedUrl = await axios.default.get(
          `/qualitygate/v1/presignedurl/${ctx.project.id}`,
          {
            headers: {
              userToken: ctx.tokens.idToken,
              accessToken: ctx.tokens.accessToken,
            },
          },
        );

        await axios.default.put(presignedUrl.data.presignedUrl, file, {
          headers: {
            "Content-Type": "application/octet-stream",
          },
        });

        await axios.default.post(
          "/qualitygate/v1/fileupload",
          `fileId=${presignedUrl.data.fileName}&fileName=${file.name}&fileSize=${file.size}&projectId=${ctx.project.id}`,
          {
            headers: {
              userToken: ctx.tokens.idToken,
              accessToken: ctx.tokens.accessToken,
            },
          },
        );

        await queryClient.invalidateQueries({
          queryKey: ["getModels", ctx.project.id],
        });
      }
      catch (e: any)
      {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (e.response.status === 403)
        {
          // TODO translate
          enqueueSnackbar(t("You are not allowed to create a model."), {
            variant: "error",
          });
        }
        else 
        {
          enqueueSnackbar(t("ui.validationarrangement.fileuploaderror"), {
            variant: "error",
          });
        }
        console.debug("[ValidationArrangement] file upload ERROR:", e);
      }
    }

    setIsUploadingModel(false);
  };

  const mapValidationSetsToFlatFilteredList = () => 
  {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const recursivelyPushToFlatList = (validationSet: any, level: number) => 
    {
      if (validationSet.status === "ExcelFileSuccessfullyImported")
      {
        result.push({ ...validationSet, level });
      }
      if (validationSet.children)
      {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        validationSet.children.forEach((item: any) =>
          recursivelyPushToFlatList(item, level + 1),
        );
      }
    };

    const result: Array<any> = [];
    if (validationSets)
    {
      validationSets.forEach((item: any) => recursivelyPushToFlatList(item, 0));
    }
    return result;
  };

  const handleAccModelSelected = async (entity: AccBaseEntity) => 
  {
    if (
      ctx.project.accProjectId === undefined
      || ctx.project.accHubId === undefined
    )
    {
      return;
    }

    setIsLoadingAccModel(true);

    const split = entity.displayName.split("/");
    if (split.length > 1)
    {
      entity.displayName = split[split.length - 1].trim();
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let item: any;
    try 
    {
      item = await getFileById(entity.id, ctx);
      const project = await getProjectById(
        ctx.project.accProjectId,
        ctx.project.accHubId,
        ctx,
      );

      try 
      {
        await axios.default.post(
          "/qualitygate/v1/fileupload",
          `fileId=${entity.id}&fileName=${entity.displayName}&fileSize=${
            item.size
          }&projectId=${ctx.project.id}&isFromCloud=true&cloudProjectId=${
            ctx.project.accProjectId
          }&cloudProjectName=${project?.name ?? ""}`,
          {
            headers: {
              userToken: ctx.tokens.idToken,
              accessToken: ctx.tokens.accessToken,
            },
          },
        );

        await queryClient.invalidateQueries({
          queryKey: ["getModels", ctx.project.id],
        });
      }
      catch (error: any)
      {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (error.response.status === 403)
        {
          // TODO translate
          enqueueSnackbar(t("You are not allowed to create a model."), {
            variant: "error",
          });
        }
        else 
        {
          throw error;
        }
      }
    }
    catch (error)
    {
      enqueueSnackbar(t("ui.validationarrangement.noaccfilereceived"), {
        variant: "error",
      });
      setIsLoadingAccModel(false);
      return;
    }

    setIsLoadingAccModel(false);
    setAccFolderViewIsOpen(false);
  };

  const drag = (
    event: React.DragEvent<HTMLTableRowElement>,
    useCase: "over" | "leave",
  ) => 
  {
    let node = event.target as HTMLElement;

    while (node.nodeName.toLowerCase() !== "tr")
    {
      if (node.parentNode !== null)
      {
        node = node.parentNode as HTMLElement;
      }
    }

    if (useCase === "over")
    {
      if (!node.classList.contains("drag-over"))
      {
        node.classList.add("drag-over");
      }
    }
    else 
    {
      if (node.classList.contains("drag-over"))
      {
        node.classList.remove("drag-over");
      }
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const DropComponent = ({ onDrop, children }: any) => (
    <div className="drop-component" {...createDropAbility(onDrop)}>
      {children}
    </div>
  );

  const handleDropValidationSet = (
    validationSetJson: string,
    targetModelFileId: string,
  ) => 
  {
    // TODO fix querySelectorAll
    const nodes = document.querySelectorAll(".drag-over");
    nodes.forEach((node) => 
    {
      if (node.classList.contains("drag-over"))
      {
        node.classList.remove("drag-over");
      }
    });

    const validationSetData = JSON.parse(validationSetJson);
    if (validationSetData.isExcelFileSuccessfullyImported)
    {
      handleSelectValidationSet(
        validationSetData.id,
        validationSetData.name,
        targetModelFileId,
      );
    }
    else 
    {
      enqueueSnackbar(t("This validation set is not valid, please correct."), {
        variant: "error",
      });
    }
  };

  const handleSelectValidationSetFromDialog = (
    validationSetId: string,
    validationSetName: string,
  ) => 
  {
    handleSelectValidationSet(
      validationSetId,
      validationSetName,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      dialogModelFileId!,
    );
    setDialogModelFileId(undefined);
  };

  const handleSelectValidationSet = async (
    validationSetId: string,
    validationSetName: string,
    modelFileId: string,
  ) => 
  {
    if (validationInProgress)
    {
      // only one validation at a time
      return;
    }

    setValidationInProgress({
      validationSetId,
      validationSetName,
      modelFileId,
    });

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const modelToValidate = allModels.find((m) => m.fileId === modelFileId)!;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let response: any;
    if (modelToValidate.isFromCloud)
    {
      let url: string;
      try 
      {
        const projectId = ctx.project.accProjectId;
        if (!projectId)
        {
          throw new Error("Project ID not found.");
        }
        // if projectId in context changes, this will fail!!!
        url = await getFileS3Url(modelToValidate.fileId, ctx, projectId);
      }
      catch (error)
      {
        // no connection to acc or no file found or wrong projectId
        // Todo: include project id
        enqueueSnackbar(t("ui.validationarrangement.nos3urlreceived"), {
          variant: "error",
        });
        return;
      }

      try 
      {
        const s3Url = encodeURIComponent(url);

        response = await axios.default.post(
          "/qualitygate/v1/validateCloudModel",
          `modelId=${modelToValidate.fileId}&s3Url=${s3Url}&validationSet=${validationSetId}&modelName=${modelToValidate.name}&projectId=${ctx.project.id}`,
          {
            headers: {
              userToken: ctx.tokens.idToken,
              accessToken: ctx.tokens.accessToken,
            },
          },
        );
      }
      catch (error: any)
      {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (error.response.status === 403)
        {
          // TODO translate
          enqueueSnackbar(t("You are not allowed to validate a model."), {
            variant: "error",
          });
          setValidationInProgress(undefined);
          return;
        }
        else 
        {
          // TODO translate
          enqueueSnackbar(
            t("An error occured while trying to validate a model."),
            {
              variant: "error",
            },
          );
          setValidationInProgress(undefined);
          return;
        }
      }
    }
    else 
    {
      try 
      {
        response = await axios.default.post(
          "/qualitygate/v1/validateModel",
          `fileId=${modelToValidate.fileId}&validationSet=${validationSetId}&projectId=${ctx.project.id}&fileName=${modelToValidate.name}`,
          {
            headers: {
              userToken: ctx.tokens.idToken,
              accessToken: ctx.tokens.accessToken,
            },
          },
        );
      }
      catch (error: any)
      {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        console.log("error", error);
        if (error.response.status === 403)
        {
          // TODO translate
          enqueueSnackbar(t("You are not allowed to validate a model."), {
            variant: "error",
          });
          setValidationInProgress(undefined);
          return;
        }
        else 
        {
          // TODO translate
          enqueueSnackbar(
            t("An error occured while trying to validate a model."),
            {
              variant: "error",
            },
          );
          setValidationInProgress(undefined);
          return;
        }
      }
    }

    if (response?.data)
    {
      setValidationInProgress((prev) => ({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        ...prev!,
        validationId: response.data,
      }));
    }
    // TODO error handling
  };

  if (isLoadingModels || isLoadingAccModel || isUploadingModel)
  {
    return (
      <div>
        <Box>
          <CircularProgress className="spinner" />
        </Box>
      </div>
    );
  }

  return (
    <>
      <div id="container">
        <h1 id="title">{t("ui.validationarrangement.heading")}</h1>
        <div
          id="description"
          dangerouslySetInnerHTML={{
            __html: createDOMPurify(window).sanitize(
              t("ui.validationarrangement.description") as string,
            ),
          }}
        />
        <div id="file-upload-container">
          <MuiButton
            className="Upload"
            startIcon={<FolderIcon />}
            style={{
              margin: "24px",
              border: "2px dashed #000000",
              color: "black",
              minWidth: "300px",
            }}
            variant="outlined"
            size="large"
            onClick={() => setAccFolderViewIsOpen(true)}
          >
            <Typography
              style={{ textTransform: "none", alignContent: "flex-start" }}
              fontSize={"13px"}
              variant="subtitle1"
            >
              {t("ui.validationarrangement.accchoose")}
            </Typography>
          </MuiButton>
          <FileUploader
            handleChange={handleUploadFile}
            name="file"
            types={["IFC"]}
            classes="file-upload"
            hoverTitle={t("ui.hoveringfile")}
            label={t("ui.uploadfile")}
            multiple={true}
          />
        </div>
        <div>
          <Modal
            open={accFolderViewIsOpen}
            onClose={() => setAccFolderViewIsOpen(false)}
          >
            <AccProjectExplorerView
              onFileSelected={handleAccModelSelected}
              isAccAuthenticated={
                ctx.acc === undefined ? undefined : ctx.acc.isAuthenticated
              }
            />
          </Modal>
        </div>
        <table className="content" id="validation-arrangement-models">
          <thead>
            <tr>
              <th>{t("ui.validationarrangement.filename")}</th>
              <th>{t("ui.validationarrangement.uploaddate")}</th>
              <th>{t("ui.validationarrangement.filesize")}</th>
              <th>{t("ui.validationarrangement.status")}</th>
              <th>{t("ui.validationarrangement.tags")}</th>
              <th>{t("ui.validationarrangement.CloudProjectName")}</th>
              <th>{t("ui.validationresults.actions")}</th>
            </tr>
          </thead>
          <tbody id="model-tbody">
            {allModels.map((model) => (
              <tr
                key={model.fileId}
                onDragOver={(e) => drag(e, "over")}
                onDragLeave={(e) => drag(e, "leave")}
              >
                <td className="model-name">
                  <DropComponent
                    onDrop={(entityData: string) =>
                      handleDropValidationSet(entityData, model.fileId)
                    }
                  >
                    <FontAwesomeIcon icon="pen-ruler" />
                    <span className="model-name-label">{model.name}</span>
                  </DropComponent>
                </td>
                <td className="model-upload-date">
                  <DropComponent
                    onDrop={(entityData: string) =>
                      handleDropValidationSet(entityData, model.fileId)
                    }
                  >
                    {formatDate(model.uploadDate)}
                  </DropComponent>
                </td>
                <td className="model-filesize">
                  <DropComponent
                    onDrop={(entityData: string) =>
                      handleDropValidationSet(entityData, model.fileId)
                    }
                  >
                    {(model.fileSize / (1024 * 1024)).toLocaleString(
                      undefined,
                      {
                        maximumFractionDigits: 2,
                      },
                    )}{" "}
                    MB
                  </DropComponent>
                </td>
                <td className="model-status">
                  <DropComponent
                    onDrop={(entityData: string) =>
                      handleDropValidationSet(entityData, model.fileId)
                    }
                  >
                    {validationInProgress
                      && validationInProgress.modelFileId === model.fileId && (
                      <>
                        <div className="validation-status">
                          <FontAwesomeIcon icon="gear" />
                        </div>
                        <span className="validation-status-label">
                          {t("ui.validationresults.validating")}{" "}
                          <span className="validation-set-name">
                            {validationInProgress.validationSetName}
                          </span>
                        </span>
                      </>
                    )}
                  </DropComponent>
                </td>
                <td className="model-tags">
                  <DropComponent
                    onDrop={(entityData: string) =>
                      handleDropValidationSet(entityData, model.fileId)
                    }
                  >
                    {/*eslint-disable-next-line @typescript-eslint/no-explicit-any*/}
                    {model.tags.map((tag: any, index: number) => (
                      <span key={index} className="model-tag">
                        {tag.name}
                      </span>
                    ))}
                  </DropComponent>
                </td>
                <td className="model-cloud-project">
                  <DropComponent
                    onDrop={(entityData: string) =>
                      handleDropValidationSet(entityData, model.fileId)
                    }
                  >
                    {model.isFromCloud && model.cloudProjectName !== "" ? (
                      <span className="model-cloud-project-name-label">
                        {model.cloudProjectName}
                      </span>
                    ) : (
                      <span className="model-cloud-project-name-label">-</span>
                    )}
                  </DropComponent>
                </td>
                <td className="model-help">
                  <Button
                    className="rounded-full bg-transparent hover:bg-transparent"
                    onClick={() => setDialogModelFileId(model.fileId)}
                  >
                    <Search className="text-white" />
                  </Button>
                  <Button
                    className="rounded-full bg-transparent hover:bg-transparent"
                    onClick={() =>
                      setDeleteModelDialogOpen({
                        open: true,
                        modelId: model.fileId,
                      })
                    }
                  >
                    <Trash className="text-white" />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {dialogModelFileId && (
        <ValidationArrangementDialog
          isVisible={!!dialogModelFileId}
          validationSetFlatList={mapValidationSetsToFlatFilteredList()}
          onCancel={() => setDialogModelFileId(undefined)}
          onSelectValidationSetFromDialog={handleSelectValidationSetFromDialog}
        />
      )}
      <AgreeOrDenyDialog
        onClose={() => 
        {
          setDeleteModelDialogOpen({
            open: false,
            modelId: "",
          });
        }}
        message={t("ui.validationarrangement.deletemodelmessage")}
        title={t("ui.validationarrangement.deletemodeltitle")}
        onConfirm={() => 
        {
          if (deleteModelDialog.open && deleteModelDialog.modelId !== "")
          {
            handleModelDelete(deleteModelDialog.modelId);
          }
        }}
        open={deleteModelDialog.open}
      />
    </>
  );
};

export default ValidationArrangement;