/* eslint-disable @typescript-eslint/no-explicit-any */
import * as axios from "axios";
import QGContext from "../../QGContext";
import { useContext } from "react";
import { useQuery } from "@tanstack/react-query";

/**
 * Gets the projects from the Quality Gate API
 *
 * @returns {Promise<any>} the projects from the Quality Gate API
 */
export const useGetProjects = () => 
{
  const ctx = useContext(QGContext);

  const result = useQuery<any, any>({
    queryKey: ["getProjects"],
    queryFn: async () => 
    {
      const response = await axios.default.get("/qualitygate/v1/projects", {
        headers: {
          userToken: ctx.tokens.idToken,
          accessToken: ctx.tokens.accessToken,
        },
      });
      return response.data;
    },
  });

  console.log("useGetProjects", result);

  // TODO typing
  return result;
};