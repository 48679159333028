import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { CognitoTokens } from "./support/authentication";
import { createContext } from "react";

/**
 * The QG context
 *
 * @interface
 */
export interface AppContext
{
  /**
   * The Apollo client
   */
  accClient: ApolloClient<NormalizedCacheObject> | undefined;

  /**
   * The three cognito tokens
   */
  tokens: CognitoTokens;

  /**
   * The user data
   */
  user: {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
  };

  /**
   * The Acc data
   */
  acc:
    | {
        isAuthenticated: boolean;
        userId?: string;
        userName?: string;
      }
    | undefined;

  /**
   * The project data. Note that this is a temporary solution!!
   */
  project: {
    id: string;
    accHubId?: string;
    accProjectId?: string;
  };

  /**
   * Set the project. Temporary !!
   *
   * @param project
   * @returns
   */
  setProject: (project: AppContext["project"]) => void;

  /**
   * Set the Acc login
   *
   * @param userId
   * @param userName
   * @returns
   */
  setAccAuthentication: (
    isAuthenticated: boolean,
    user?: { id: string; name: string } | undefined,
  ) => void;
}

/**
 * The default context
 */
export const defaultContext: AppContext = {
  accClient: undefined,
  tokens: {
    accessToken: "",
    idToken: "",
    refreshToken: "",
  },
  user: {
    id: "",
    email: "User",
    firstName: "",
    lastName: "",
  },
  acc: undefined,
  project: {
    id: "",
  },
  setProject: () =>
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  {},
  setAccAuthentication: () =>
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  {},
};

const QGContext = createContext<AppContext>(defaultContext);

export default QGContext;