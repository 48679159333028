/**
 * Enumeration for all check types
 *
 * @readonly
 * @enum {number}
 */
export enum CheckType
{
  /**
   * Equals.
   */
  Eq = 1,

  /**
   * Not equals.
   */
  NEq = 2,

  /**
   * Greater than.
   */
  Gt = 11,

  /**
   * Greater than / Equals.
   */
  Gte = 12,

  /**
   * Lower than.
   */
  Lt = 21,

  /**
   * Lower than / Equals.
   */
  Lte = 22,

  /**
   * In.
   */
  In = 31,

  /**
   * Not In.
   */
  NotIn = 32,

  /**
   * Empty
   */
  Empty = 41,

  /**
   * Not Empty
   */
  NotEmpty = 42,
}

/**
 * Map the check type.
 *
 * @param {CheckType} checkType The check type.
 * @param {boolean} short A value indicating whether a short or a long description should be returned.
 * @returns {string} The check type label.
 */
export function mapCheckType(checkType: CheckType, short: boolean): string
{
  switch (checkType)
  {
  case CheckType.NEq:
    return short ? " != " : " not equals ";
  case CheckType.Gt:
    return short ? " > " : " greater than ";
  case CheckType.Gte:
    return short ? " >= " : " greater than / equals ";
  case CheckType.Lt:
    return short ? " < " : " lower than ";
  case CheckType.Lte:
    return short ? " <= " : " lower than / equals ";
  case CheckType.In:
    return " in ";
  case CheckType.NotIn:
    return " not in ";
  case CheckType.Empty:
    return " is empty";
  case CheckType.NotEmpty:
    return " is not empty";
  default:
  case CheckType.Eq:
    return short ? " == " : " equals ";
  }
}

/**
 * Get the translated check type.
 *
 * @param {CheckType | string} checkType The check type.
 * @param {any} translateFunction The translate function.
 * @param {boolean} short A value indicating whether a short or a long description should be returned.
 * @returns {string} The translated label.
 */
export function getTranslatedCheckType(
  checkType: CheckType | string,
  translateFunction: (key: string) => string,
  short: boolean,
): string
{
  switch (checkType)
  {
  case CheckType.NEq:
  case "NEq":
    return short
      ? translateFunction("ui.checktype.neq")
      : translateFunction("ui.checktype.long.neq");
  case CheckType.Gt:
  case "Gt":
    return short
      ? translateFunction("ui.checktype.gt")
      : translateFunction("ui.checktype.long.gt");
  case CheckType.Gte:
  case "Gte":
    return short
      ? translateFunction("ui.checktype.gte")
      : translateFunction("ui.checktype.long.gte");
  case CheckType.Lt:
  case "Lt":
    return short
      ? translateFunction("ui.checktype.lt")
      : translateFunction("ui.checktype.long.lt");
  case CheckType.Lte:
  case "Lte":
    return short
      ? translateFunction("ui.checktype.lte")
      : translateFunction("ui.checktype.long.lte");
  case CheckType.In:
  case "In":
    return short
      ? translateFunction("ui.checktype.in")
      : translateFunction("ui.checktype.long.in");
  case CheckType.NotIn:
  case "NotIn":
    return short
      ? translateFunction("ui.checktype.nin")
      : translateFunction("ui.checktype.long.nin");
  case CheckType.Empty:
  case "Empty":
    return short
      ? translateFunction("ui.checktype.empty")
      : translateFunction("ui.checktype.long.empty");
  case CheckType.NotEmpty:
  case "NotEmpty":
    return short
      ? translateFunction("ui.checktype.notempty")
      : translateFunction("ui.checktype.long.notempty");
  default:
  case CheckType.Eq:
  case "Eq":
    return short
      ? translateFunction("ui.checktype.eq")
      : translateFunction("ui.checktype.long.eq");
  }
}