import {
  BookCheck,
  BookKey,
  ChevronDown,
  ChevronLeft,
  Home,
  LogOut,
  UserRound,
  Users,
} from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "./ui/dropdown-menu";
import QGContext, { AppContext } from "../QGContext";
import { WithTranslation, withTranslation } from "react-i18next";
import { Button } from "./ui/button";
import English from "./Images/united-kingdom_555417.png";
import Logo from "./Images/Icon.svg";
import { NavLink } from "react-router-dom";
import React from "react";
import { Separator } from "./ui/separator";
import UserInfo from "./UserInfo";
import { WithRouter } from "../interfaces/WithRouter";
import { logout } from "../support/authentication";
import { withRouter } from "../support/withRouter";

/**
 * Interface for the side bar arguments.
 *
 * @interface
 */
interface SideBarProps extends WithTranslation, WithRouter
{
  /*
   * The project name.
   *
   * @property {string} projectName The project name.
   */
  projectName: string | undefined;
}

/**
 * The Side Bar component.
 *
 * @class
 * @augments {React.Component<SideBarProps>}
 */
class SideBar extends React.Component<SideBarProps>
{
  static contextType = QGContext;

  /**
   * Create an instance of SideBar.
   *
   * @param {SideBarProps} props The properties.
   */
  constructor(props: SideBarProps)
  {
    super(props);
    this.backToProjectList = this.backToProjectList.bind(this);
  }

  /**
   * Unselect the current project.
   *
   * @returns {void}
   */
  backToProjectList()
  {
    (this.context as AppContext).setProject({
      id: "",
      accHubId: undefined,
      accProjectId: undefined,
    });
    this.props.router.navigate("/");
  }

  /**
   * Render the component.
   *
   * @returns {JSX.Element} The component instance.
   */
  render()
  {
    const { t } = this.props;

    return (
      <div>
        <div className="flex flex-col  bg-black bg-opacity-20 min-h-screen w-[272px] min-w-[272px] p-4 ">
          <div className="grid items-center justify-center h-[80px] w-[232px] ">
            <img src={Logo} alt="logo" className="logo w-[195px] " />
          </div>
          <Separator />

          <div className="Navigation-back grid items-center justify-center h-[60px] w-[232px]">
            <Button
              className="back-to-home bg-transparent text-gray-400 text-sm font-semibold hover:bg-white/5 "
              onClick={this.backToProjectList}
            >
              <ChevronLeft className="w-[25px] h-[20px] pr-[12px]" />
              {t("Zurück zur Projektübersicht")}
            </Button>
          </div>
          <div className="project-details flex flex-col items-start pl-[15px] h-[60px] w-[232px] gap-y-[-5px]">
            <span className="title text-sm font-medium text-gray-400">
              Projekt
            </span>
            <span className="projectName text-md font-medium text-white">
              {this.props.projectName}
            </span>
          </div>

          <Separator />
          <div className="wrapperClasses"></div>

          <div className="project-details grid items-center h-[232px] w-[232px]  ">
            <Button className="w-full h-[48px] flex items-center justify-start gap-x-2 bg-transparent text-gray-400 text-md font-medium hover:bg-white/5 hover:border hover:border-orange-500/50  ">
              <Home className="w-[20px] h-[20px] " />
              <NavLink to="projectinformation">{t("Dashboard")}</NavLink>
            </Button>

            <Button className="w-full h-[48px] flex items-center justify-start gap-x-2 bg-transparent text-gray-400 text-md font-medium hover:bg-white/5 hover:border hover:border-orange-500/50">
              <UserRound className="w-[20px] h-[20px] " />
              <NavLink to="roles">{t("ui.navigation.roles")}</NavLink>
            </Button>

            <Button className="w-full h-[48px] flex items-center justify-start gap-x-2 bg-transparent text-gray-400 text-md font-medium hover:bg-white/5 hover:border hover:border-orange-500/50   ">
              <Users className="w-[20px] h-[20px]" />
              <NavLink to="members">{t("ui.navigation.members")}</NavLink>
            </Button>

            <Button className="w-full h-[48px] flex items-center justify-start gap-x-2 bg-transparent text-gray-400 text-md font-medium hover:bg-white/5 hover:border hover:border-orange-500/50 ">
              <LogOut className="w-[20px] h-[20px] " />
              <NavLink to="stakeholders">
                {t("ui.navigation.stakeholders")}
              </NavLink>
            </Button>
          </div>
          <Separator />
          <div className="valisation-Sets grid items-center h-[55px] w-[232px]  ">
            <Button className="w-full h-[48px] flex items-center justify-start gap-x-2 bg-transparent text-gray-400 text-md font-medium hover:bg-white/5 hover:border hover:border-orange-500/50">
              <NavLink to="validationsets">{t("Validation Sets")}</NavLink>
            </Button>
          </div>

          <Separator />
          <div className="Tests-Results grid items-center h-[110px] w-[232px]">
            <Button className="w-full h-[48px] flex items-center justify-start gap-x-2 bg-transparent text-gray-400 text-md font-medium hover:bg-white/5 hover:border hover:border-orange-500/50">
              <BookCheck className="w-[20px] h-[20px] " />
              <NavLink to={"validation-arrangement"}>
                {t("ui.navigation.validationarrangement")}
              </NavLink>
            </Button>

            <Button className="w-full h-[48px] flex items-center justify-start bg-transparent text-gray-400 text-md font-medium hover:bg-white/5 hover:border hover:border-orange-500/50">
              <BookKey className="w-[20px] h-[20px] " />
              <NavLink to={"validation-results"}>
                {t("ui.navigation.validationresults")}
              </NavLink>
            </Button>
          </div>

          <div className=" grow w-[232px] "></div>

          <Separator />
          <div className=" h-[80px] w-[232px] flex items-center justify-center ">
            <DropdownMenu>
              <DropdownMenuTrigger>
                <Button className=" flex w-[232px] h-[63px] relative justify-start items-center bg-transparent hover:bg-white/5 ">
                  {/* UserInfo */}
                  <div className="flex justify-start">
                    <UserInfo userImage="/user-image.jpg" />
                  </div>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="bg-slate-800 w-[250px] h-[180px] text-white border-0 p-4">
                <div className="flex justify-between pb-4">
                  <p className="font-sans text-xs ">Language</p>
                  <ChevronDown className="w-[16px] h-[16px] " />
                </div>
                <DropdownMenuItem className=" flex items-center p-4 h-[48px] text-base gap-x-4 bg-white/10 hover:bg-white/20">
                  <img src={English} alt="English" className="logo w-[20px] " />
                  Language
                </DropdownMenuItem>
                <DropdownMenuSeparator className="opacity-20 " />
                <DropdownMenuItem
                  onClick={logout}
                  className="p-4 gap-x-4 h-[48px] text-base hover:bg-white/10"
                >
                  <LogOut className="w-[20px] h-[20px] rotate-180" />
                  {t("ui.user.logout")}
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(SideBar));