/* eslint-disable @typescript-eslint/no-explicit-any */
import * as axios from "axios";
import QGContext from "../../QGContext";
import { useContext } from "react";
import { useQuery } from "@tanstack/react-query";

/**
 * Gets the validation result from the Quality Gate API
 *
 * @param {string} validationId The validation ID
 * @param {boolean} shortPolling Whether to use short polling
 * @returns {Promise<any>} The models from the Quality Gate API
 */
export const useGetValidationResult = (
  validationId: string | undefined,
  shortPolling: boolean = false,
) => 
{
  const ctx = useContext(QGContext);

  // TODO typing
  return useQuery<any, any>({
    queryKey: ["getValidationResult", validationId],
    queryFn: async () => 
    {
      const response = await axios.default.get(
        `/qualitygate/v1/validationResult/${validationId}`,
        {
          headers: {
            userToken: ctx.tokens.idToken,
            accessToken: ctx.tokens.accessToken,
          },
        },
      );
      return response.data;
    },
    refetchInterval: (query) => 
    {
      // short polling as long as response is http 404 (not found)
      if (shortPolling && query.state.error?.response?.status === 404)
      {
        return 3000;
      }
      return false;
    },
    meta: {
      ignore404: shortPolling, // ignore short polling 404 errors
    },
    enabled: !!validationId,
  });
};