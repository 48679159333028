/* eslint-disable @typescript-eslint/no-explicit-any */
import * as axios from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import QGContext from "../../QGContext";
import { useContext } from "react";

/**
 * Deletes the project from the Quality Gate
 *
 * @returns {Promise<any>} The response from the Quality Gate API
 */
export const useDeleteProject = () => 
{
  const ctx = useContext(QGContext);
  const queryClient = useQueryClient();

  return useMutation({
    // TODO typing
    mutationFn: async (projectId: string) => 
    {
      const response = await axios.default.delete(
        `/qualitygate/v1/project/${projectId}`,
        {
          headers: {
            userToken: ctx.tokens.idToken,
            accessToken: ctx.tokens.accessToken,
          },
        },
      );

      return response.data;
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ["getProjects"],
      }),
  });
};